import Loader from "./loader"




function CommonButton(props) {

    const {
        title,
        handlerFunction,
        isLoading
    } = props

    return <button className="AddQuestion_submit_btn mx-3" style={{ cursor: 'pointer' }
    }
        onClick={handlerFunction}
        disabled={isLoading}
    > {isLoading ? <Loader /> : title}</button >

}

export default CommonButton
