import React, { useEffect, useContext, useState } from "react";

import ApiConfig from "../../../api/ApiConfig";
import {
  PostCallWithErrorResponse,
  getWithAuthCall,
  getWithRequestBodyWithErrorResponse,
  postWithAuthCall,
} from "../../../api/ApiServices";

import Loader from "../../Loader";
import { AppContext } from "../../../context/AppContext";
import { ToastMsg } from "../../TostMsg";
import { Accordion, Form, OverlayTrigger, Tooltip } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPenToSquare, faTrash } from "@fortawesome/free-solid-svg-icons";
import NoData from "../../../sharedComponent/NoData";
import CommonButton from "../button";

const AccessCode = () => {
  const { isDrawerOpen, selectedDomainId, domainName, sidebar } =
    useContext(AppContext);


  const [permissionList, setPermissionList] = useState([]);

  const [isLoading, setIsLoading] = useState(true);

  const [isloadNewPerm, setIsLoadNewPerm] = useState(false)

  const getPermissionList = () => {
    setIsLoading(true);
    getWithAuthCall(ApiConfig.GET_PERMISSIONS_LIST + selectedDomainId).then(
      (res) => {
        if (res?.success) {
          setPermissionList(res?.data);
        } else {
          ToastMsg("error", res?.message);
          setPermissionList([])
        }
        setIsLoading(false);
      }
    );
  };
  useEffect(() => {
    getPermissionList();
  }, [selectedDomainId]);

  const loadNewPermissions = () => {

    setIsLoadNewPerm(true)

    PostCallWithErrorResponse(ApiConfig.LOAD_NEW_PERMISSION).then((res) => {

      if (res?.json?.success) {

        setIsLoadNewPerm(false)

        ToastMsg('success', res?.json?.message)

      } else {

        ToastMsg('error', res?.json?.message)

      }

      setIsLoadNewPerm(false)

    })

  }

  const updatePermission = (id, status, index, subIndex) => {
    PostCallWithErrorResponse(
      ApiConfig.UPDATE_PERMISSION,
      JSON.stringify({
        permission_id: id,
        domain_id: selectedDomainId,
        status: status,
      })
    )
      .then((res) => {

        if (res?.json?.success) {
          ToastMsg("success", res?.json?.message);


          let updatePerm = permissionList?.map((perm, permIndex) => {

            if (permIndex == index) {

              return {
                ...perm,
                permissions: perm?.permissions?.map((subPerm, subPerindex) => {

                  if (subPerindex === subIndex) {
                    return { ...subPerm, permission_status: status }
                  } else {
                    return subPerm
                  }

                })
              }

            } else {
              return perm
            }

          })


          setPermissionList(updatePerm);
        } else {
          ToastMsg("error", res?.json?.message);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <div className={sidebar ? "taskMain " : "cx-active taskMain"} id="cx-main">
      <div>
        <div>
          <div className="role-content">
            <div className="sub-header-role d-flex justify-content-between ">
              <h6 className="mt-2">Access</h6>
              <h6 className="mt-2 mr-2">Domain Name : {domainName}</h6>
            </div>
            <div className="d-flex justify-content-end mb-4 mt-4 ml-2">
              <CommonButton
                title="Load New Permissions"
                isLoading={isloadNewPerm}
                handlerFunction={loadNewPermissions}
              />
            </div>
            <div className="table-wrapper table-wrapper-3">
              {
                isLoading ? <Loader /> :
                  <Accordion defaultActiveKey={'0'} style={{ marginTop: '0px' }}>{
                    permissionList?.length ? permissionList.map((per, index) => {

                      return <Accordion.Item eventKey={index}>
                        <Accordion.Header >
                          <div
                            style={{ backgroundColor: "white" }}
                            className="py-3 rounded-3 px-2 d-flex justify-content-between w-100 "
                          >
                            <h6
                              className="ml-2"
                              style={{ width: "80%", fontWeight: "bold" }}
                            >
                              {per?.module_by_display_name ? per.module_by_display_name : per?.category}
                            </h6>
                          </div>
                        </Accordion.Header>
                        <Accordion.Body>
                          {
                            <div
                              className="table-common"
                              style={{
                                height: "200px",
                                marginTop: "0px",
                                backgroundColor: "white",
                                borderRadius: "10px",
                              }}
                            >

                              {/* {answer?.question?.length > 0 ? ( */}
                              <div className="py-3 row ml-4 ">
                                {
                                  per?.permissions?.length > 0 ?
                                    per?.permissions?.map((subPer, indx) => {

                                      return <Form.Group className="fw-bold col-md-4 mt-1" key={indx}>
                                        <Form.Check type="checkbox" label={subPer?.sub_module_by_display_name ? subPer?.sub_module_by_display_name : subPer?.name}
                                          checked={subPer?.permission_status}
                                          onChange={(e) => updatePermission(subPer?.permission_id, e.target.checked, index, indx)}
                                        />
                                      </Form.Group>

                                    })
                                    : <NoData />
                                }

                              </div>
                            </div>
                          }
                        </Accordion.Body>
                      </Accordion.Item>

                    }) : <NoData />
                  }</Accordion>
              }
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default AccessCode;
