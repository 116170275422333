import React, { useEffect, useState, useRef } from "react";



import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import ApiConfig from "../../../api/ApiConfig";
import { postRequest } from "../../../api/ApiServices";
import Loader from "../loader";
import { linkify } from "../commaon";

const ALLOWED_TYPES = ["user", "bot"]; // action, slot, user_featurization



function ChatDetails({ chat, selectedChat, onSelectChat }) {
  const filter_events = (chat) => {
    const filteredEvents = chat.events.filter((event) =>
      ALLOWED_TYPES.includes(event.type_name)
    );
    return filteredEvents.slice();
  };

  const [isLoading, setIsLoading] = useState(true);

  const [chatList, setChatList] = useState([]);

  const getChatDetails = () => {
    setIsLoading(true);
    postRequest(
      ApiConfig.GET_CHAT_DETAILS,
      JSON.stringify({
        number: selectedChat?.sender_id,
      })
    ).then((res) => {
      if (res?.success) {
        setChatList(res?.chatbot_message);
        setIsLoading(false);
        if (messagesEndRef.current) {
          // Using a timeout to ensure the scroll happens after rendering
          setTimeout(() => {
            messagesEndRef.current.scrollIntoView({ behavior: "smooth" });
          }, 0);
        }
      } else {
        setIsLoading(false);
      }
    });
  };

  const messagesEndRef = useRef(null);

  useEffect(() => {
    getChatDetails();
  }, [selectedChat]);

  return (

    <div className="chat-details hideScrollBar soft-shadow">
      {chat ? (
        <div className="messages">
          {isLoading ? (
            <Loader />
          ) : (
            chatList.map((event, index) => (
              <div key={index} className={`message ${event.type_name}`}>
                <p className={`timestamp ${event.type_name}`}>
                  {event.created_at}
                  {/* {formatTimestamp(event.created_at)} */}
                </p>
                <p className={`message-body ${event.type_name}`}>
                  {linkify(event.message)}
                </p>
              </div>
            ))
          )}
          <div ref={messagesEndRef} />
        </div>
      ) : (
        <p>Select a chat</p>
      )}
    </div>
  );
}

export default ChatDetails;
