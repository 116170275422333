import { useContext, useEffect, useState } from "react";
import { AppContext } from "../../context/AppContext";
import { Badge, Button, Form } from "react-bootstrap";
import { simpleGetCallWithErrorResponse, putWithAuthCall } from "../../api/ApiServices";
import ApiConfig from "../../api/ApiConfig";
import { ToastMsg } from "../TostMsg";
import Loader from "../Loader";

const SMSGateway = () => {
  const { sidebar } = useContext(AppContext);

  const [smsDrop, setsmsDrom] = useState([])
  const [loading, setLoading] = useState(false)

  const [getwayId, setGetWayId] = useState('')
  const [updateSms, setAddUpdateSms] = useState({
    Id: "",
    ApiKey: "",
    PartnerId: "",
    ShortCode: "",
    Balance: "",
    // "Enabled": true,
    GatewayId: ""
  })
  const [enable, setEnable] = useState(false)


  const handleEnable = (e) => {
    const avtive = e.target.checked ? true : false
    setEnable(avtive)
  }

  const paymentGetDrop = () => {
    simpleGetCallWithErrorResponse(ApiConfig.PAYMENTGETWAY + "Sms")
      .then((res) => {
        console.log("paymentDrop--->", res.json.Data)
        setsmsDrom(res.json.Data)
      })
      .catch((err) => {
        console.log(err)
      })
  }


  const smsGetdETAILS = (ID) => {
    setLoading(true)
    simpleGetCallWithErrorResponse(ApiConfig.SMSGETWAYDETAILS + ID)
      .then((res) => {
        console.log("paymentDatils--->", res.json.Data)
        // setGetPayment(res.json.Data)
        if (res.json.Success === true) {
          setLoading(false)
          setAddUpdateSms(res.json.Data)
        } else {
          setLoading(false)
          setAddUpdateSms("")
          setEnable("")
          // setMode("")
        }

      })
      .catch((err) => {
        console.log(err)
      })
  }
  useEffect(() => {
    paymentGetDrop()

    if (getwayId) {
      smsGetdETAILS(getwayId)
    }
  }, [getwayId])

  useEffect(() => {
    if (updateSms) {
      setEnable(updateSms.Enabled)
    }

  }, [updateSms.Enabled])

  const onUpdateSms = () =>{
    setLoading(true)

    const body ={
      Id: updateSms.Id,
      ApiKey:  updateSms.ApiKey,
      PartnerId:  updateSms.PartnerId,
      ShortCode:  updateSms.ShortCode,
      // Balance: 0,
      Enabled: enable,
      GatewayId:Number(getwayId)
    }

    putWithAuthCall(ApiConfig.UPDATESMS,JSON.stringify(body))
      
    .then((res)=>{
      if(res.Success === true){
        ToastMsg('success',res.Message)
        setLoading(false)

      }else{
        ToastMsg('error',res.Message)
        setLoading(false)

      }
      console.log("payment update--->",res.json)
    })
    .catch((err)=>{
      console.log(err)
    })
  }

  return (
    <div className={sidebar ? "taskMain " : "cx-active taskMain"}
      id="cx-main">
      <div className="role-content">
        <div className="sub-header-role ">
          <h6 className="mt-2">SMS Gateway</h6>
        </div>
        <div className="innerRoleSec">
          <Form>
            <div className="row">
              {
                loading ? ("") : (
                  <Form.Group className="col-md-12 my-3">
                    <div className="">
                      <div className="toggle-switch-enabled">
                        <input
                          type="checkbox"
                          className="toggle-switch-checkbox-enabled"
                          name="yesnoenabled"
                          id='yesnoenabled'
                          checked={enable}
                          onChange={handleEnable}
                        />
                        <label className="toggle-switch-label-enabled" htmlFor="yesnoenabled" >
                          <span className="toggle-switch-inner-enabled" />
                          <span className="toggle-switch-switch-enabled" />
                        </label>
                      </div><span className="text-grey ms-2">SMS Gateway Status</span>
                    </div>
                  </Form.Group>
                )
              }

              <Form.Group className="col-md-6">
                <Form.Label className="mb-2 text-grey">
                  SMS Gateway :
                </Form.Label>
                <Form.Select
                  className="form-control tasKCategory1"
                  required
                  value={getwayId}
                  onChange={(e) => setGetWayId(e.target.value)}

                >
                  <option value={0}>Select</option>
                  {
                    smsDrop.map((item) => (
                      <option key={item.GatewayId} value={item.GatewayId}>{item.Gateway}</option>
                    ))
                  }

                </Form.Select>
              </Form.Group>
              {
                loading ? (<Loader />) : (
                  <>
                    <Form.Group className="col-md-6 my-auto">
                      <Form.Label className="mb-2 text-grey">
                        API Key :
                      </Form.Label>
                      <Form.Control
                        className="form-control tasKCategory1"
                        type="text"
                        value={updateSms.ApiKey}
                        onChange={(e) => setAddUpdateSms({ ...updateSms, ApiKey: e.target.value })}
                      />
                    </Form.Group>

                    <Form.Group className="col-md-6 my-3">
                      <Form.Label className="mb-2 text-grey">
                        Partner ID :
                      </Form.Label>
                      <Form.Control
                        className="form-control tasKCategory1"
                        type="number"
                        value={updateSms.PartnerId}
                        onChange={(e) => setAddUpdateSms({ ...updateSms, PartnerId: e.target.value })}

                      />
                    </Form.Group>

                    <Form.Group className="col-md-6 my-3">
                      <Form.Label className="mb-2 text-grey">
                        Short Code :
                      </Form.Label>
                      <Form.Control
                        className="form-control tasKCategory1"
                        type="text"
                        value={updateSms.ShortCode}
                        onChange={(e) => setAddUpdateSms({ ...updateSms, ShortCode: e.target.value })}
                      />
                    </Form.Group>
                 
                      <div className="bg-light py-2 rounded-3">
                        <Form.Text>SMS Gateway Information : </Form.Text>
                        <ol className="px-4 mb-0">
                          <li><a className="link-text">Click here</a><Form.Text> to Sign up to the SMS Gateway website</Form.Text></li>
                          <li><a className="link-text">Click here</a><Form.Text> to Recharge SMS balance</Form.Text></li>
                        </ol>
                        <Form.Text>Availabel balance is : <span className="bg-info rounded-3 px-2 text-white">{updateSms.Balance}</span> </Form.Text>
                      </div>
                      <div className="my-3 d-flex justify-content-end" >
                      <button className="allocateionBtn" 
                      onClick={onUpdateSms}
                      >Update</button>
                    </div>
                  </>
                )}
            </div>
          </Form>
        </div>
      </div>
    </div>
  )
}
export default SMSGateway;