import React, { useContext, useEffect, useState } from "react";
import { AppContext } from "../../context/AppContext";
import "../../assets/css/Timesheet.scss";
// import { Accordion, Pagination, Tab, Tabs } from "react-bootstrap";
import Pagination from "../../sharedComponent/Pagination";

import downArrow from "../../assets/images/downArrow.png";
import upArrow from "../../assets/images/upArrow.png";
import { simpleGetCallWithToken } from "../../api/ApiServices";
import ApiConfig from "../../api/ApiConfig";
import Loader from "../Loader";
import NoData from "../../sharedComponent/NoData";
import moment from "moment";

const MyDiary = () => {
  const { sidebar, setSidebar, Dark, setDark } = useContext(AppContext);

  // const { sidebar } = useContext(AppContext);
  const [show, setShow] = useState(false);
  const [showEdit, setShowEdit] = useState(false);
  const [loading, setLoading] = useState(false);

  const [activityList, setActivityList] = useState([]);

  const [searchValue, setSearchValue] = useState("");
  const [currentPage, setCurrentPage] = useState(0);
  const [usersPerPage, setUsersPerPage] = useState(5);

  const startIndex = currentPage * usersPerPage;
  const endIndex = startIndex + parseInt(usersPerPage);

  const pageChangeHandler = ({ selected }) => {
    setCurrentPage(selected);
  };

  const searchedDataPageCounts =
    activityList &&
    activityList.length &&
    activityList.filter((item) =>
      item.ProjectName?.toLowerCase().includes(searchValue)
    );

  const fetchUserActivity = () => {
    setLoading(true);
    simpleGetCallWithToken(ApiConfig.MY_DAIRY)
      .then((res) => {
        if (res.Success === true) {
          setLoading(false);
          setActivityList(res?.Data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    fetchUserActivity();
  }, []);

  const [btnLoader, setbtnLoader] = useState(false);

  // const [dropdownacc, setDropdownacc] = useState(false);

  // const [btn1, setBtn1] = useState(true);
  // const [btn2, setBtn2] = useState(false);
  // const [btn3, setBtn3] = useState(false);

  // const activefirst = () => {
  //   setBtn1(true);
  //   setBtn2(false);
  //   setBtn3(false);
  // };
  // const activeSec = () => {
  //   setBtn1(false);
  //   setBtn2(true);
  //   setBtn3(false);
  // };
  // const activethir = () => {
  //   setBtn1(false);
  //   setBtn2(false);
  //   setBtn3(true);
  // };

  return (
    // <>
    //   {/* <Header />
    //         <Sidebar /> */}
    //   {/* <div
    //     className={sidebar ? "taskMain " : "cx-active taskMain"}
    //     id="cx-main"
    //   > */}
    //     {/* <div className="role-content">
    //                 <div className="sub-header-role ">
    //                     <h6 className="mt-2">Today</h6>
    //                 </div>
    //                 <div className="d-flex justify-content-between role-searchbar-and-btns RspSalary">
    //                     <div className="d-flex justify-content-between mainIcons mt-4">
    //                         <div class="mydiary-btn" onClick={() => { activefirst() }}>
    //                             {btn1 === true ?
    //                                 <button type="submit" className="mydiary-active">Today</button>
    //                                 : <button type="submit" className="mydiary-unactive">Today</button>
    //                             }
    //                         </div>
    //                         <div class="mydiary-btn" onClick={() => { activeSec() }}>
    //                             {btn2 === true ?
    //                                 <button type="submit" className="mydiary-active">This Week</button>
    //                                 : <button type="submit" className="mydiary-unactive">This Week</button>
    //                             }
    //                         </div>
    //                         <div class="mydiary-btn" onClick={() => { activethir() }}>
    //                             {btn3 === true ?
    //                                 <button type="submit" className="mydiary-active">This Months</button>
    //                                 : <button type="submit" className="mydiary-unactive">This Months</button>
    //                             }
    //                         </div>
    //                     </div>
    //                     <div className="role-table-header">
    //                         <div className="search-input-wrapper">
    //                             <input type="date" placeholder="Search" />
    //                         </div>
    //                     </div>
    //                 </div>
    //                 <div className="diary-main-wrap row">
    //                     <div className="todayDiary col-lg-4">
    //                         <div class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 mt-2 custom-height-30">
    //                             <div class="check-in-out mydiry-contain">
    //                                 <div class="heading active">
    //                                     <h5>2 December 2022, Thursday</h5>
    //                                 </div>
    //                                 <div class="check-in-out-details">
    //                                     <p htmlFor="">Project Name : </p>
    //                                     <p htmlFor="">Task Name :</p>
    //                                     <p htmlFor="">Activity :</p>
    //                                 </div>
    //                             </div>
    //                         </div>
    //                     </div>
    //                     <div className="todayDiary col-lg-4">
    //                         <div class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 mt-2 custom-height-30">
    //                             <div class="check-in-out mydiry-contain">
    //                                 <div class="heading">
    //                                     <h5>1 December 2022, Thursday</h5>
    //                                 </div>
    //                                 <div class="check-in-out-details">
    //                                     <p htmlFor="">Project Name : <span>Project Title</span></p>
    //                                     <p htmlFor="">Task Name : Ui design</p>
    //                                     <div className="d-flex custumheight">
    //                                         <div><p htmlFor="">Activity : </p></div>
    //                                         <div className="overflowx-auto">
    //                                             <ul>
    //                                                 <li>Lorem ipsum, dolor sit amet consectetur adipisicing elit. Voluptas, tempora!</li>
    //                                                 <li>Lorem ipsum dolor sit amet consectetur.</li>
    //                                                 <li>Lorem, ipsum dolor sit amet consectetur adipisicing elit. Itaque mollitia et ex!</li>
    //                                                 <li>Lorem ipsum dolor sit, amet consectetur adipisicing elit. Velit fugiat error deserunt consectetur necessitatibus ipsa maiores nesciunt cumque.</li>
    //                                                 <li>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Earum, enim consectetur ullam sunt voluptas molestias atque libero sapiente minus cum.</li>
    //                                             </ul>
    //                                         </div>
    //                                     </div>
    //                                     <div className="dairy-btn">
    //                                         <button>Read more</button>
    //                                     </div>
    //                                 </div>
    //                             </div>
    //                         </div>
    //                     </div>
    //                     <div className="todayDiary col-lg-4">
    //                         <div class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 mt-2 custom-height-30">
    //                             <div class="check-in-out mydiry-contain">
    //                                 <div class="heading">
    //                                     <h5>30 November 2022, Wednusday</h5>
    //                                 </div>
    //                                 <div class="check-in-out-details">
    //                                     <div className="emptydiart">
    //                                         <p>No Data Found</p>
    //                                     </div>
    //                                 </div>
    //                             </div>
    //                         </div>
    //                     </div>
    //                     <div className="todayDiary col-lg-4">
    //                         <div class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 mt-2 custom-height-30">
    //                             <div class="check-in-out mydiry-contain">
    //                                 <div class="heading">
    //                                     <h5>27 November  2022, Tuesday</h5>
    //                                 </div>
    //                                 <div class="check-in-out-details">
    //                                     <p htmlFor="">Project Name : <span>Project Title</span></p>
    //                                     <p htmlFor="">Task Name : Ui design</p>
    //                                     <div className="d-flex custumheight">
    //                                         <div><p htmlFor="">Activity : </p></div>
    //                                         <div className="overflowx-auto">
    //                                             <ul>
    //                                                 <li>Lorem ipsum, dolor sit amet consectetur adipisicing elit. Voluptas, tempora!</li>
    //                                                 <li>Lorem ipsum dolor sit amet consectetur.</li>
    //                                                 <li>Lorem, ipsum dolor sit amet consectetur adipisicing elit. Itaque mollitia et ex!</li>
    //                                                 <li>Lorem ipsum dolor sit, amet consectetur adipisicing elit. Velit fugiat error deserunt consectetur necessitatibus ipsa maiores nesciunt cumque.</li>
    //                                                 <li>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Earum, enim consectetur ullam sunt voluptas molestias atque libero sapiente minus cum.</li>
    //                                             </ul>
    //                                         </div>
    //                                     </div>
    //                                     <div className="dairy-btn">
    //                                         <button>Read more</button>
    //                                     </div>
    //                                 </div>
    //                             </div>
    //                         </div>
    //                     </div>
    //                     <div className="todayDiary col-lg-4">
    //                         <div class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 mt-2 custom-height-30">
    //                             <div class="check-in-out mydiry-contain">
    //                                 <div class="heading">
    //                                     <h5>28 November 2022, Monday</h5>
    //                                 </div>
    //                                 <div class="check-in-out-details">
    //                                     <p htmlFor="">Project Name : <span>Project Title</span></p>
    //                                     <p htmlFor="">Task Name : Ui design</p>
    //                                     <div className="d-flex custumheight">
    //                                         <div><p htmlFor="">Activity : </p></div>
    //                                         <div className="overflowx-auto">
    //                                             <ul>
    //                                                 <li>Lorem ipsum, dolor sit amet consectetur adipisicing elit. Voluptas, tempora!</li>
    //                                                 <li>Lorem ipsum dolor sit amet consectetur.</li>
    //                                                 <li>Lorem, ipsum dolor sit amet consectetur adipisicing elit. Itaque mollitia et ex!</li>
    //                                                 <li>Lorem ipsum dolor sit, amet consectetur adipisicing elit. Velit fugiat error deserunt consectetur necessitatibus ipsa maiores nesciunt cumque.</li>
    //                                                 <li>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Earum, enim consectetur ullam sunt voluptas molestias atque libero sapiente minus cum.</li>
    //                                             </ul>
    //                                         </div>
    //                                     </div>
    //                                     <div className="dairy-btn">
    //                                         <button>Read more</button>
    //                                     </div>
    //                                 </div>
    //                             </div>
    //                         </div>
    //                     </div>
    //                 </div>
    //             </div> */}

    //     <div className="role-content diary-tabs-main">
    //       <div className="sub-header-role ">
    //         <h6 className="mt-2">Today</h6>
    //       </div>
    //       <Tabs
    //         defaultActiveKey="home"
    //         transition={false}
    //         id="noanim-tab-example"
    //         className="mb-3 "
    //       >
    //         <div className="role-table-header">
    //           <div className="search-input-wrapper">
    //             <input type="date" placeholder="Search" />
    //           </div>
    //         </div>
    //         <Tab eventKey="home" title="Toady">
    //           <div className="diary-main-wrap row">
    //             <div className="todayDiary col-lg-4">
    //               <div class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 mt-2 custom-height-30 mb-3">
    //                 <div class="check-in-out mydiry-contain">
    //                   <div class="heading active">
    //                     <h5>2 December 2022, Thursday</h5>
    //                   </div>

    //                   <div class="check-in-out-details">
    //                     <table>
    //                       <tr>
    //                         <td>Project Name</td>
    //                         <td>&nbsp; &nbsp;</td>
    //                         <td>:</td>
    //                         <td></td>
    //                       </tr>
    //                       <tr>
    //                         <td>Task Name</td>
    //                         <td></td>
    //                         <td>:</td>
    //                         <td></td>
    //                       </tr>
    //                       <tr>
    //                         <td>Activity</td>
    //                         <td></td>
    //                         <td>:</td>
    //                         <td></td>
    //                       </tr>
    //                     </table>
    //                   </div>
    //                 </div>
    //               </div>
    //             </div>
    //             <div className="todayDiary col-lg-4">
    //               <div class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 mt-2 custom-height-30">
    //                 <div class="check-in-out mydiry-contain">
    //                   <div class="mydiary-heading">
    //                     <h5>1 December 2022, Thursday</h5>
    //                     <span>08:00 Hrs</span>
    //                   </div>
    //                   <div class="check-in-out-details">
    //                     <table>
    //                       <tr>
    //                         <td>Project Name</td>
    //                         <td>&nbsp; &nbsp;</td>
    //                         <td>: &nbsp;</td>
    //                         <td>Project Name</td>
    //                       </tr>
    //                       <tr>
    //                         <td>Task Name</td>
    //                         <td></td>
    //                         <td>: &nbsp;</td>
    //                         <td>Name of Task</td>
    //                       </tr>
    //                       <tr>
    //                         <td>Activity</td>
    //                         <td></td>
    //                         <td>:</td>
    //                         <td>
    //                           <ul>
    //                             <li>Lorem ipsum dolor sit.</li>
    //                             <li>Lorem ipsum dolor sit.</li>
    //                             <li>Lorem ipsum dolor sit.</li>
    //                             <li>Lorem ipsum dolor sit.</li>
    //                             <li>Lorem ipsum dolor sit.</li>
    //                           </ul>
    //                         </td>
    //                       </tr>
    //                     </table>
    //                     <div className="dairy-btn mx-4">
    //                       <button>Read More</button>
    //                     </div>
    //                   </div>
    //                 </div>
    //               </div>
    //             </div>
    //             <div className="todayDiary col-lg-4">
    //               <div class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 mt-2 custom-height-30">
    //                 <div class="check-in-out mydiry-contain">
    //                   <div class="mydiary-heading">
    //                     <h5>30 November 2022, Wednusday</h5>
    //                     <span>08:00 Hrs</span>
    //                   </div>
    //                   <div class="check-in-out-details">
    //                     <div className="emptydiart">
    //                       <p>No Data Found</p>
    //                     </div>
    //                   </div>
    //                 </div>
    //               </div>
    //             </div>
    //             <div className="todayDiary col-lg-4">
    //               <div class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 mt-2 custom-height-30">
    //                 <div class="check-in-out mydiry-contain">
    //                   <div class="mydiary-heading">
    //                     <h5>27 November 2022, Tuesday</h5>
    //                     <span>10:30 Hrs</span>
    //                   </div>
    //                   <div class="check-in-out-details">
    //                     <table>
    //                       <tr>
    //                         <td>Project Name</td>
    //                         <td>&nbsp; &nbsp;</td>
    //                         <td>: &nbsp;</td>
    //                         <td>Project Title</td>
    //                       </tr>
    //                       <tr>
    //                         <td>Task Name</td>
    //                         <td></td>
    //                         <td>: &nbsp;</td>
    //                         <td>UI Design</td>
    //                       </tr>
    //                       <tr>
    //                         <td>Activity</td>
    //                         <td></td>
    //                         <td>:</td>
    //                         <td>
    //                           <ul>
    //                             <li>Lorem ipsum dolor sit.</li>
    //                             <li>Lorem ipsum dolor sit.</li>
    //                             <li>Lorem ipsum dolor sit.</li>
    //                             <li>Lorem ipsum dolor sit.</li>
    //                             <li>Lorem ipsum dolor sit.</li>
    //                             <li>Lorem ipsum dolor sit.</li>
    //                             <li>Lorem ipsum dolor sit.</li>
    //                             <li>Lorem ipsum dolor sit.</li>
    //                             <li>Lorem ipsum dolor sit.</li>
    //                             <li>Lorem ipsum dolor sit.</li>
    //                             <li>Lorem ipsum dolor sit.</li>
    //                             <li>Lorem ipsum dolor sit.</li>
    //                             <li>Lorem ipsum dolor sit.</li>
    //                             <li>Lorem ipsum dolor sit.</li>
    //                             <li>Lorem ipsum dolor sit.</li>
    //                             <li>Lorem ipsum dolor sit.</li>
    //                             <li>Lorem ipsum dolor sit.</li>
    //                           </ul>
    //                         </td>
    //                       </tr>
    //                     </table>
    //                     <div className="dairy-btn mx-4">
    //                       <button>Read Less</button>
    //                     </div>
    //                   </div>
    //                 </div>
    //               </div>
    //             </div>
    //             <div className="todayDiary col-lg-4">
    //               <div class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 mt-2 custom-height-30">
    //                 <div class="check-in-out mydiry-contain">
    //                   <div class="mydiary-heading">
    //                     <h5>28 November 2022, Monday</h5>
    //                     <span>10:30 Hrs</span>
    //                   </div>
    //                   <div class="check-in-out-details">
    //                     <table>
    //                       <tr>
    //                         <td>Project Name</td>
    //                         <td>&nbsp; &nbsp;</td>
    //                         <td>: &nbsp;</td>
    //                         <td>Project Name</td>
    //                       </tr>
    //                       <tr>
    //                         <td>Task Name</td>
    //                         <td></td>
    //                         <td>: &nbsp;</td>
    //                         <td>Name of Task</td>
    //                       </tr>
    //                       <tr>
    //                         <td>Activity</td>
    //                         <td></td>
    //                         <td>:</td>
    //                         <td>
    //                           <ul>
    //                             <li>Lorem ipsum dolor sit.</li>
    //                             <li>Lorem ipsum dolor sit.</li>
    //                             <li>Lorem ipsum dolor sit.</li>
    //                             <li>Lorem ipsum dolor sit.</li>
    //                             <li>Lorem ipsum dolor sit.</li>
    //                           </ul>
    //                         </td>
    //                       </tr>
    //                     </table>
    //                   </div>
    //                 </div>
    //               </div>
    //             </div>
    //           </div>
    //         </Tab>
    //         <Tab eventKey="profile" title="This Week">
    //           <div className="table-wrapper">
    //             <table>
    //               <tr className="firstTabelRow">
    //                 <th>Sr. no</th>
    //                 <th>Week Start Date</th>
    //                 <th>Week End Date</th>
    //                 <th>Project Name</th>
    //                 <th>Task Name</th>
    //                 <th>Start Date</th>
    //                 <th>Total Hrs</th>
    //               </tr>

    //               <tr className="table-row-custom">
    //                 <td> 1</td>
    //                 <td>09-08-2022</td>
    //                 <td>09-08-2022</td>
    //                 <td>Project Titale</td>
    //                 <td>UI Design</td>
    //                 <td>09-08-2022</td>
    //                 <td>10</td>
    //               </tr>
    //               <tr className="table-row-custom">
    //                 <td> 2</td>
    //                 <td>09-08-2022</td>
    //                 <td>09-08-2022</td>
    //                 <td>Project Titale</td>
    //                 <td>UI Design</td>
    //                 <td>09-08-2022</td>
    //                 <td>09</td>
    //               </tr>
    //               <tr className="table-row-custom">
    //                 <td> 3</td>
    //                 <td>09-08-2022</td>
    //                 <td>09-08-2022</td>
    //                 <td>Project Titale</td>
    //                 <td>UI Design</td>
    //                 <td>09-08-2022</td>
    //                 <td>03</td>
    //               </tr>
    //               <tr className="table-row-custom">
    //                 <td> 4</td>
    //                 <td>09-08-2022</td>
    //                 <td>09-08-2022</td>
    //                 <td>Project Titale</td>
    //                 <td>UI Design</td>
    //                 <td>09-08-2022</td>
    //                 <td>08</td>
    //               </tr>
    //             </table>
    //           </div>
    //         </Tab>
    //         <Tab eventKey="contact" title="This Months">
    //           <Accordion>
    //             <Accordion.Item eventKey="" className="topAccordiance">
    //               <Accordion.Header>
    //                 <div className="table-wrapper ">
    //                   <table>
    //                     <tr>
    //                       <th className="serialnumber">Sr.no</th>
    //                       <th className="projectname">Project Name</th>
    //                       <th className="totalbudget">Task Name</th>
    //                       <th className="remark">Total Hrs</th>
    //                       <th>{"  "}</th>
    //                     </tr>
    //                   </table>
    //                 </div>
    //               </Accordion.Header>
    //             </Accordion.Item>
    //             <Accordion.Item eventKey="0">
    //               <Accordion.Header>
    //                 <div className="table-wrapper table-row-custom-padding">
    //                   <table>
    //                     <tr
    //                       className="table-row-custom bodypadding"
    //                       onClick={() => {
    //                         setDropdownacc(!dropdownacc);
    //                       }}
    //                     >
    //                       <td>Week 1</td>
    //                       <td>Project Title</td>
    //                       <td>UI Design</td>
    //                       <td>40</td>
    //                       <td>
    //                         {dropdownacc == true ? (
    //                           <img src={upArrow} alt="" />
    //                         ) : (
    //                           <img src={downArrow} alt="" />
    //                         )}
    //                       </td>
    //                     </tr>
    //                   </table>
    //                 </div>
    //               </Accordion.Header>
    //               <Accordion.Body>
    //                 <div className="AccordianBody">
    //                   <table>
    //                     <tr className="table-row-custom bodypadding">
    //                       <td>Sr.No</td>
    //                       <td>Date</td>
    //                       <td>Project Name</td>
    //                       <td>Task Name</td>
    //                       <td>Worked Hrs</td>
    //                     </tr>
    //                     <tr className="tabel-body-acordince">
    //                       <td>1</td>
    //                       <td>10-12-2022</td>
    //                       <td>Wifi Attendance</td>
    //                       <td>Team Management Module</td>
    //                       <td>8</td>
    //                     </tr>
    //                     <tr className="tabel-body-acordince">
    //                       <td>2</td>
    //                       <td>11-12-2022</td>
    //                       <td>Wifi Attendance</td>
    //                       <td>Team Management Module</td>
    //                       <td>8</td>
    //                     </tr>
    //                     <tr className="tabel-body-acordince">
    //                       <td>3</td>
    //                       <td>13-12-2022</td>
    //                       <td>Wifi Attendance</td>
    //                       <td>Team Management Module</td>
    //                       <td>20</td>
    //                     </tr>
    //                   </table>
    //                 </div>
    //               </Accordion.Body>
    //             </Accordion.Item>
    //             <Accordion.Item eventKey="1">
    //               <Accordion.Header>
    //                 <div className="table-wrapper table-row-custom-padding">
    //                   <table>
    //                     <tr
    //                       className="table-row-custom bodypadding"
    //                       onClick={() => {
    //                         setDropdownacc(!dropdownacc);
    //                       }}
    //                     >
    //                       <td>Week 2</td>
    //                       <td>Project Title</td>
    //                       <td>UI Design</td>
    //                       <td>40</td>
    //                       <td>
    //                         {dropdownacc == true ? (
    //                           <img src={upArrow} alt="" />
    //                         ) : (
    //                           <img src={downArrow} alt="" />
    //                         )}
    //                       </td>
    //                     </tr>
    //                   </table>
    //                 </div>
    //               </Accordion.Header>
    //               <Accordion.Body>
    //                 <div className="AccordianBody">
    //                   <table>
    //                     <tr className="table-row-custom bodypadding">
    //                       <td>Sr.No</td>
    //                       <td>Date</td>
    //                       <td>Project Name</td>
    //                       <td>Task Name</td>
    //                       <td>Worked Hrs</td>
    //                     </tr>
    //                     <tr className="tabel-body-acordince">
    //                       <td>1</td>
    //                       <td>10-12-2022</td>
    //                       <td>Wifi Attendance</td>
    //                       <td>Team Management Module</td>
    //                       <td>8</td>
    //                     </tr>
    //                     <tr className="tabel-body-acordince">
    //                       <td>2</td>
    //                       <td>11-12-2022</td>
    //                       <td>Wifi Attendance</td>
    //                       <td>Team Management Module</td>
    //                       <td>8</td>
    //                     </tr>
    //                     <tr className="tabel-body-acordince">
    //                       <td>3</td>
    //                       <td>13-12-2022</td>
    //                       <td>Wifi Attendance</td>
    //                       <td>Team Management Module</td>
    //                       <td>20</td>
    //                     </tr>
    //                   </table>
    //                 </div>
    //               </Accordion.Body>
    //             </Accordion.Item>
    //             <Accordion.Item eventKey="2">
    //               <Accordion.Header>
    //                 <div className="table-wrapper table-row-custom-padding">
    //                   <table>
    //                     <tr
    //                       className="table-row-custom bodypadding"
    //                       onClick={() => {
    //                         setDropdownacc(!dropdownacc);
    //                       }}
    //                     >
    //                       <td>Week 3</td>
    //                       <td>Project Title</td>
    //                       <td>UI Design</td>
    //                       <td>40</td>
    //                       <td>
    //                         {dropdownacc == true ? (
    //                           <img src={upArrow} alt="" />
    //                         ) : (
    //                           <img src={downArrow} alt="" />
    //                         )}
    //                       </td>
    //                     </tr>
    //                   </table>
    //                 </div>
    //               </Accordion.Header>
    //               <Accordion.Body>
    //                 <div className="AccordianBody">
    //                   <table>
    //                     <tr className="table-row-custom bodypadding">
    //                       <td>Sr.No</td>
    //                       <td>Date</td>
    //                       <td>Project Name</td>
    //                       <td>Task Name</td>
    //                       <td>Worked Hrs</td>
    //                     </tr>
    //                     <tr className="tabel-body-acordince">
    //                       <td>1</td>
    //                       <td>10-12-2022</td>
    //                       <td>Wifi Attendance</td>
    //                       <td>Team Management Module</td>
    //                       <td>8</td>
    //                     </tr>
    //                     <tr className="tabel-body-acordince">
    //                       <td>2</td>
    //                       <td>11-12-2022</td>
    //                       <td>Wifi Attendance</td>
    //                       <td>Team Management Module</td>
    //                       <td>8</td>
    //                     </tr>
    //                     <tr className="tabel-body-acordince">
    //                       <td>3</td>
    //                       <td>13-12-2022</td>
    //                       <td>Wifi Attendance</td>
    //                       <td>Team Management Module</td>
    //                       <td>20</td>
    //                     </tr>
    //                   </table>
    //                 </div>
    //               </Accordion.Body>
    //             </Accordion.Item>
    //             <Accordion.Item eventKey="3">
    //               <Accordion.Header>
    //                 <div className="table-wrapper table-row-custom-padding">
    //                   <table>
    //                     <tr
    //                       className="table-row-custom bodypadding"
    //                       onClick={() => {
    //                         setDropdownacc(!dropdownacc);
    //                       }}
    //                     >
    //                       <td>Week 4</td>
    //                       <td>Project Title</td>
    //                       <td>UI Design</td>
    //                       <td>40</td>
    //                       <td>
    //                         {dropdownacc == true ? (
    //                           <img src={upArrow} alt="" />
    //                         ) : (
    //                           <img src={downArrow} alt="" />
    //                         )}
    //                       </td>
    //                     </tr>
    //                   </table>
    //                 </div>
    //               </Accordion.Header>
    //               <Accordion.Body>
    //                 <div className="AccordianBody">
    //                   <table>
    //                     <tr className="table-row-custom bodypadding">
    //                       <td>Sr.No</td>
    //                       <td>Date</td>
    //                       <td>Project Name</td>
    //                       <td>Task Name</td>
    //                       <td>Worked Hrs</td>
    //                     </tr>
    //                     <tr className="tabel-body-acordince">
    //                       <td>1</td>
    //                       <td>10-12-2022</td>
    //                       <td>Wifi Attendance</td>
    //                       <td>Team Management Module</td>
    //                       <td>8</td>
    //                     </tr>
    //                     <tr className="tabel-body-acordince">
    //                       <td>2</td>
    //                       <td>11-12-2022</td>
    //                       <td>Wifi Attendance</td>
    //                       <td>Team Management Module</td>
    //                       <td>8</td>
    //                     </tr>
    //                     <tr className="tabel-body-acordince">
    //                       <td>3</td>
    //                       <td>13-12-2022</td>
    //                       <td>Wifi Attendance</td>
    //                       <td>Team Management Module</td>
    //                       <td>20</td>
    //                     </tr>
    //                   </table>
    //                 </div>
    //               </Accordion.Body>
    //             </Accordion.Item>
    //           </Accordion>
    //         </Tab>
    //       </Tabs>
    //     </div>
    //   </div>
    // </>
    <>
      <div
        className={sidebar ? "taskMain " : "cx-active taskMain"}
        id="cx-main"
      >
        <div className="role-content-main">
          <div className="sub-header-role mb-4">
            <h6 className="mt-2">My Diary</h6>
            {/* <button
                            className="create-btn-sub-header"
                            variant="primary"
                            onClick={() => {
                                setShow(true);
                            }}
                        >
                            + Add New Record{" "}
                        </button> */}
          </div>
          <div className="entries-wrapper">
            <p>Entries per page</p>
            <select
              className="form-select select-drop-icon"
              aria-label="Default select example"
              onChange={(e) => {
                setUsersPerPage(e.target.value);
                setCurrentPage(0);
              }}
            >
              <option value="5">5</option>
              <option value="10">10</option>
              <option value="15">15</option>
              <option value="20">20</option>
            </select>
          </div>
          <div className="table-wrapper adjustableTab">
            {loading ? (
              <>
                <Loader />
              </>
            ) : (
              <>
                {activityList && activityList.length > 0 ? (
                  <>
                    <table>
                      <thead>
                        <tr className="firstTabelRow">
                          <th>Sr.No</th>
                          <th>New</th>
                          <th>Module Name</th>
                          <th>Activity</th>
                          <th>Activity Date</th>

                        </tr>
                      </thead>
                      <tbody>
                        {/* {activityList
                          .slice(startIndex, endIndex)
                          .map((data, index) => {
                            return (
                              <tr className="table-row-custom" key={index}>
                                <td>{startIndex + index + 1}</td>
                                <td>{data.ModuleName}</td>
                                <td>{data.Activity}</td>
                                <td>{data.ActivityDate}</td>
                              </tr>
                            );
                          })} */}
                        {activityList
                          .sort((a, b) => new Date(b.ActivityDate) - new Date(a.ActivityDate)) // Sorting by ActivityDate
                          .slice(startIndex, endIndex) // Paginate data if needed
                          .map((data, index) => {
                            const isMostRecent = index === 0; // Check if this is the first item after sorting
                            return (
                              <tr className="table-row-custom" key={index}>
                                {/* Sr.No */}
                                <td>{startIndex + index + 1}</td>

                                {/* New column showing "New" for the most recent item */}
                                <td>{isMostRecent ? <span style={{ color: "green" }}>New</span> : ""}</td>

                                {/* Other columns */}
                                <td>{data.ModuleName}</td>
                                <td>{data.Activity}</td>
                                <td>{data.ActivityDate}</td>
                              </tr>
                            );
                          })}
                      </tbody>
                    </table>
                  </>
                ) : (
                  <NoData />
                )}
              </>
            )}
          </div>
        </div>
        <Pagination
          data={activityList}
          pageChangeHandler={pageChangeHandler}
          usersPerPage={usersPerPage}
          currentPage={currentPage}
          searchValue={searchValue}
          searchedDataPageCounts={searchedDataPageCounts}
        />
      </div>
    </>
  );
};

export default MyDiary;
