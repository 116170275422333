import { useContext, useEffect, useState } from "react";
import { AppContext } from "../../context/AppContext";
import { Button, Form } from "react-bootstrap";
import { simpleGetCallWithErrorResponse, putWithAuthCall } from "../../api/ApiServices";
import ApiConfig from "../../api/ApiConfig";
import { ToastMsg } from "../TostMsg";
import Loader from "../Loader";

const PaymentGateway = () => {
  const { sidebar } = useContext(AppContext);
  const [isYes, setIsYes] = useState(true);
  const toggle = () => {
    setIsYes(!isYes);
  };
  const [loading, setLoading] = useState(false)
  const [getPayment, setGetPayment] = useState([])
  const [paymentMethod, setPaymentMethod] = useState('');
  console.log("paymentMethod id---->", paymentMethod)

  const [addPayment, setAddPayment] = useState({
    MerchantId: '',
    ApiKey: "",
    SecretKey: "",
    TransactionCharge: "",
    Mode: "",
    ReceiptEmail: "",
    GatewayId: ""

  })
  const [activePayment, setActivePayment] = useState(false)
  const [mode, setMode] = useState(false); // Initial state as SANDBOX


  const handleChange = (e) => {
    setActivePayment(e.target.checked ? true : false);
    console.log('Checkbox value:--->', e.target.checked); // Add this line to check the value
  };

  const handleChangeMode = (e) => {
    const newMode = e.target.checked;
    setMode(newMode);
  
    const data = newMode ? 'PRODUCTION' : 'SANDBOX';
    console.log('Mode:--->', data); // Log the current mode
    
  };
  

  // const handleChangeMode = (e) => {
  //   let newMode = e.target.checked ? true : false;

  //   const data = newMode === true ? 'PRODUCTION' : 'SANDBOX'
  //   setMode(data);
  //   console.log('Mode:--->', data); // Log the current mode
  // };

  const paymentGetDrop = () => {
    simpleGetCallWithErrorResponse(ApiConfig.PAYMENTGETWAY + "Payment")
      .then((res) => {
        console.log("paymentDrop--->", res.json.Data)
        setGetPayment(res.json.Data)
      })
      .catch((err) => {
        console.log(err)
      })
  }

  const paymentGetdETAILS = (ID) => {
    setLoading(true)
    simpleGetCallWithErrorResponse(ApiConfig.PAYMENTGETDETAILS + ID)
      .then((res) => {
        console.log("paymentDatils--->", res.json.Data)
        // setGetPayment(res.json.Data)
        if (res.json.Success === true) {
          setLoading(false)
          setAddPayment(res.json.Data)
        } else {
          setLoading(false)
          setAddPayment("")
          setActivePayment("")
          setMode("")
        }

      })
      .catch((err) => {
        console.log(err)
      })
  }

  useEffect(() => {
    paymentGetDrop()

    if (paymentMethod) {
      paymentGetdETAILS(paymentMethod)
    }


    console.log("active payment-->", activePayment)
  }, [paymentMethod])

  useEffect(() => {
    if (addPayment) {
      setActivePayment(addPayment.Active)
      setMode(addPayment.Mode)
    }
  }, [addPayment])

  const onUpdatePayment = (e) => {
    setLoading(true)
    e.preventDefault()
    let body = {
      Id: addPayment?.Id,
      MerchantId:addPayment?.MerchantId,
      ApiKey: addPayment?.ApiKey,
      SecretKey: addPayment?.SecretKey,
      TransactionCharge: Number(addPayment?.TransactionCharge),
      Active: activePayment,
      Mode: mode? 'PRODUCTION' : 'SANDBOX',
      ReceiptEmail: addPayment?.ReceiptEmail,
      GatewayId: Number(paymentMethod)


    }
    putWithAuthCall(ApiConfig.UPDATEPAYMENT, JSON.stringify(body))

      .then((res) => {
        if (res.Success === true) {
          ToastMsg('success', res.Message)
          setLoading(false)

        } else {
          ToastMsg('error', res.Message)
          setLoading(false)

        }
        console.log("payment update--->", res.json)
      })
      .catch((err) => {
        console.log(err)
      })
  }

  return (
    <div className={sidebar ? "taskMain " : "cx-active taskMain"}
      id="cx-main">
      <div className="role-content">
        <div className="sub-header-role ">
          <h6 className="mt-2">Payment Gateway</h6>
        </div>
        <div className="innerRoleSec">

          <Form>
            <div className="row">
              {loading ? ("") : (
                <Form.Group className="col-md-12">
                  <div className="">
                    <div className="toggle-switch">
                      <input
                        type="checkbox"
                        className="toggle-switch-checkbox"
                        name="yesno"
                        id='yesno'
                        checked={activePayment}
                        onChange={handleChange}
                      />
                      <label className="toggle-switch-label" htmlFor="yesno" >
                        <span className="toggle-switch-inner" />
                        <span className="toggle-switch-switch" />
                      </label>
                    </div>
                    <span className="text-grey ms-2">Activate Online Payment</span>
                  </div>
                </Form.Group>
              )}
              <Form.Group className="col-md-6 my-3">
                <Form.Label className="mb-2 text-grey">
                  Payment Gateway :
                </Form.Label>
                <Form.Select
                  className="form-control tasKCategory1"
                  required
                  onChange={(e) => setPaymentMethod(e.target.value)}
                >
                  <option value={0}>Select</option>
                  {
                    getPayment.map((item) => (
                      <option value={item?.GatewayId}>{item?.Gateway}</option>

                    ))
                  }

                </Form.Select>
              </Form.Group>
              {loading ? (<Loader />) : (
                <>
                  <Form.Group className="col-md-6 my-auto">
                    <Form.Label className="mb-2 text-grey">
                      Payment Gateway merchanat ID :
                    </Form.Label>
                    <Form.Control
                      className="form-control tasKCategory1"
                      type="number"
                      value={addPayment?.MerchantId}

                      onChange={(e) => { setAddPayment({ ...addPayment, MerchantId: e.target.value }) }}
                    />
                  </Form.Group>

                  <Form.Group className="col-md-6 my-3">
                    <Form.Label className="mb-2 text-grey">
                      Payment Gateway Key ID :
                    </Form.Label>
                    <Form.Control
                      className="form-control tasKCategory1"
                      type="text"
                      value={addPayment?.ApiKey}
                      onChange={(e) => { setAddPayment({ ...addPayment, ApiKey: e.target.value }) }}


                    />
                  </Form.Group>

                  <Form.Group className="col-md-6 my-3">
                    <Form.Label className="mb-2 text-grey">
                      Payment Gateway Key Secret :
                    </Form.Label>
                    <Form.Control
                      className="form-control tasKCategory1"
                      type="text"
                      value={addPayment?.SecretKey}

                      onChange={(e) => { setAddPayment({ ...addPayment, SecretKey: e.target.value }) }}

                    />
                  </Form.Group>

                  <Form.Group className="col-md-6">
                    <Form.Label className="mb-2 text-grey">
                      Payment Gateway Transaction Charges(%) :
                    </Form.Label>
                    <Form.Control
                      className="form-control tasKCategory1"
                      type="text"
                      value={addPayment?.TransactionCharge}
                      onChange={(e) => { setAddPayment({ ...addPayment, TransactionCharge: e.target.value }) }}

                    />
                  </Form.Group>

                  <Form.Group className="col-md-6">
                    <Form.Label className="mb-2 text-grey">
                      Default email for receiving online payment recipt for parents  :
                    </Form.Label>
                    <Form.Control
                      className="form-control tasKCategory1"
                      type="text"
                      value={addPayment?.ReceiptEmail}

                      onChange={(e) => { setAddPayment({ ...addPayment, ReceiptEmail: e.target.value }) }}

                    />
                  </Form.Group>

                  <Form.Group className="col-md-6 my-3">
                    <div className="">
                      <div className="toggle-switch-sandbox">
                        <input
                          type="checkbox"
                          className="toggle-switch-checkbox-sandbox"
                          name="yesnosandbox"
                          id='yesnosandbox'
                          checked={mode}
                          onChange={handleChangeMode}
                        />
                        <label className="toggle-switch-label-sandbox" htmlFor="yesnosandbox" >
                          <span className="toggle-switch-inner-sandbox" />
                          <span className="toggle-switch-switch-sandbox" />
                        </label>
                      </div>
                      <span className="text-grey ms-2">Payment Gateway Account</span>
                    </div>
                  </Form.Group>
                </>
              )}

            </div>
            <div className="my-3 d-flex justify-content-end">
              <button className="allocateionBtn" onClick={onUpdatePayment}>Update</button>
            </div>
          </Form>

        </div>
      </div>
    </div>
  )
}
export default PaymentGateway;