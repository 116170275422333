import React, { useState, useContext, useEffect, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import Form from "react-bootstrap/Form";
import xlicon from "../../assets/images/xls.svg";
import imgicon from "../../assets/images/jpg.svg";
import wordIcon from "../../assets/images/word.svg";
import pdficon from "../../assets/images/pdf.svg.svg";
import ic_view from "../../assets/images/ic_view.svg";
import image_prf from "../../assets/images/image_prf.svg";
import ic_search from "../../assets/images/ic_search.svg";
import ic_search_dark from "../../assets/images/ic_search_dark.svg";
import edit from "../../assets/images/bx_edit.svg";
import delete_ic from "../../assets/images/DelectAcBlc.svg";

// import view_blue from "../../assets/images/view_blue.svg";
import letter from "../../assets/images/letter.svg";
import download_icon from "../../assets/images/download_icon.svg";
import download_icon2 from "../../assets/images/salary_download.svg";
import download_icon_blue from "../../assets/images/salary_download_blue.svg";
import view_salary_grey from "../../assets/images/view_salary_grey.svg";
import view_blue from "../../assets/images/view_salary_blue.svg";
import project_document from "../../assets/images/project_document.svg";

import ic_bars_dot from "../../assets/images/ic_bars_dot.svg";
import Logo from "../../assets/images/Logo.svg";
import img_logo_bottom from "../../assets/images/img_logo_bottom.png";
import img_logo_left from "../../assets/images/img_logo_left.png";
import img_logo from "../../assets/images/img_logo.svg";
import img_logo_top from "../../assets/images/img_logo_top.png";
import img_custom from "../../assets/images/img_custom.png";
import ic_zoom from "../../assets/images/ic_zoom.svg";
import Tab from "react-bootstrap/Tab";
import Nav from "react-bootstrap/Nav";
import ic_green_pen from "../../assets/images/ic_green_pen.svg";
import Dropdown from "react-bootstrap/Dropdown";
import Up_arrow_white from "../../assets/images/Up_arrow_white.svg";
import { Button, Modal } from "react-bootstrap";
import Carousel from "react-bootstrap/Carousel";
import { AppContext } from "../../context/AppContext";
import Axe from "../../assets/images/x.svg";
import close_modal from "../../assets/images/close_modal.svg";
import close_modal_dark from "../../assets/images/close_modal_dark.svg";
import {
  multipartPostCallWithErrorResponse,
  simpleGetCallWithErrorResponse,
} from "../../api/ApiServices";
import ApiConfig, { BASE_URL } from "../../api/ApiConfig";
import { ToastMsg } from "../TostMsg";
import BackDropLoader from "../../sharedComponent/BackDropLoader";
import ButtonLoader from "../../sharedComponent/ButtonLoader";
import Loader from "../Loader";
import NoData from "../../sharedComponent/NoData";
import FileSaver from "file-saver";
import Pagination from "../../sharedComponent/Pagination";

const ProjectDocumentation = () => {
  const {
    sidebar,
    setSidebar,
    backdrop,
    setbackdrop,
    profile,
    setprofile,
    temp,
    settemp,
    Dark,
    handleEditDocument,
    deleteDocument,
    downloadDocument,
    permissions

  } = useContext(AppContext);

  const initialEmployeDoc = {
    EmployeeId: "",
    DocumentHeading: "",
    File: "",
  };

  const [documentList, setDocumentList] = useState([]);

  const [documentDetails, setDocementDetails] = useState(initialEmployeDoc);

  const [isDocumentOpen, setIsDocumentOpen] = useState(false);

  const [editId, setEditId] = useState("");

  const [isShowValidation, setIsShowValidation] = useState(false);

  const [isUpload, setIsUpload] = useState(false);

  const [isLoading, setIsLoading] = useState(false);

  const [selectProject, setSelectProject] = useState("");
  const [currentPage, setCurrentPage] = useState(0);
  const [usersPerPage, setUsersPerPage] = useState(25);

  const startIndex = currentPage * usersPerPage;
  const endIndex = startIndex + parseInt(usersPerPage);

  const pageChangeHandler = ({ selected }) => {
    setCurrentPage(selected);
  };

  const [show, setShow] = useState(false);
  const [showdoc, setShowdoc] = useState(false);
  const handleClosedoc = () => setShowdoc(false);
  const showFileImg = useRef();

  const projectID = useRef();
  const naviget = useNavigate();

  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false);

  const [employeeList, setEmployeeList] = useState([]);

  const handleChange = (key, val) => {
    setDocementDetails((prev) => ({
      ...prev,
      [key]: val,
    }));
  };

  const handleUpdate = (item) => {
    setDocementDetails({
      ...item,
      EmployeeId: item.ProjectId,
      DocumentHeading: item.DocumentHeading,
      File: item.FileName,
    });
  };

  const getDocumentListById = (id) => {
    let url;
    if (id) {
      url = ApiConfig.PROJECT_LIST_BYID + `${id}`;
    }

    setIsLoading(true);
    simpleGetCallWithErrorResponse(url).then((res) => {
      if (res?.json?.Success === true) {
        setIsLoading(false);
        if (res?.json?.Data.length > 0) {
          setDocumentList(res?.json?.Data);
        }
      } else {
        setIsLoading(false);
        setDocumentList();
      }
    });
  };

  const getDocumentList = () => {
    let url;

    url = ApiConfig.PROJECT_LIST;

    setIsLoading(true);
    simpleGetCallWithErrorResponse(url).then((res) => {
      if (res?.json?.Success === true) {
        setIsLoading(false);
        setDocumentList(res?.json?.Data);
      } else {
        setIsLoading(false);
      }
    });
  };

  const getEmployeeList = () => {
    simpleGetCallWithErrorResponse(ApiConfig.PROJECT_LIST_DROPDOWN).then(
      (res) => {
        if (res?.json?.Success) {
          setEmployeeList(res?.json?.Data);
        }
      }
    );
  };

  useEffect(() => {
    if (selectProject) {
      getDocumentListById(selectProject);
    } else {
      getDocumentList();
    }
  }, [selectProject]);

  useEffect(() => {
    // getDocumentList()
    getEmployeeList();
  }, []);

  const onNavigetLinkpage = () => {
    naviget("/projectdetails");
  };

  const handleCloseDocument = () => {
    setIsDocumentOpen(false);

    setEditId("");

    setIsShowValidation(false);

    setDocementDetails(initialEmployeDoc);
  };

  const handleSubmit = () => {
    console.log(documentDetails);

    if (
      !documentDetails.File ||
      !documentDetails.DocumentHeading ||
      !documentDetails.EmployeeId
    ) {
      console.log(documentDetails);
      setIsShowValidation(true);

      return;
    }

    setIsUpload(true);

    const formData = new FormData();

    formData.append("ProjectId", documentDetails.EmployeeId);
    formData.append("DocumentHeading", documentDetails.DocumentHeading);
    formData.append("File", documentDetails.File);
    // formData.append('DocumentId',editId)

    if (editId) {
      formData.append("DocumentId", editId);
    }

    multipartPostCallWithErrorResponse(ApiConfig.UPLOAD_PROJECT, formData).then(
      (res) => {
        if (res?.json?.Success) {
          ToastMsg("success", res?.json?.Message);
          handleCloseDocument();

          setIsUpload(false);
          setIsLoading(true);

          getDocumentList();
        } else {
          setIsUpload(false);

          ToastMsg("error", res?.json?.Message);
        }
      }
    );
  };

  const downlodPDF = (id) => {
    const url = ApiConfig.PROJECT_DOCUMENT_DOWNLOAD;
    downloadDocument(id, url);
  };

  const deleteProjectDocument = (id) => {
    handleShow();
    projectID.current = id;
  };
  const handleShowdoc = (item) => {
    setShowdoc(true);
    showFileImg.current = item;
  };
  const employeeIds = localStorage.getItem("EmpId");
  const onDeleteProjectDocument = () => {
    const url = ApiConfig.PROJECT_DELETE;
    deleteDocument(projectID.current, url);
    handleClose();
    setIsLoading(true);
    setTimeout(() => {
      getDocumentList();
    }, 1000);
  };

  return (
    <>
      {/* <Header />
      <Sidebar /> */}

      <div
        className={sidebar ? "taskMain " : "cx-active taskMain"}
        id="cx-main"
      >
        <div className="role-content">
          <div className="sub-header-role ">
            <h6 className="mt-2">Project Documents </h6>
            <div className="entries-wrapper ">
              {/* <button className="create-btn-sub-header"
                onClick={() => onNavigetLinkpage()}
              >Document Links</button> */}
              {
                permissions && permissions['Upload Project Documents'] &&

                <button
                  className="create-btn-sub-header"
                  onClick={() => setIsDocumentOpen(true)}
                >
                  Upload Document
                </button>
              }
            </div>
          </div>

          <div className="d-flex justify-content-between role-searchbar-and-btns RspSalary">
            <div className="role-table-header accoridance">
              <div className="entries-wrapper myAccordiance">
                {
                  permissions && permissions['View Project Documents'] &&

                  <Link to="/projectdocumentation">
                    <button className=" active all-anc-btn-sub-header ">
                      Project Document
                    </button>
                  </Link>
                }
                {/* )} */}
              </div>
              <div className="search-input-wrapper allAcordiance">
                {
                  permissions && permissions['View Project Links'] &&
                  <Link to="/projectdetails">
                    <button className="my-anc-btn-sub-header">
                      Project Links
                    </button>
                  </Link>
                }
                {/* )} */}
              </div>
              <div className="mt-4">
                <select
                  className="form-select select-drop-icon mr-2"
                  aria-label="Default select example"
                  style={{ border: " 1px solid #53b7e8" }}
                  onChange={(e) => {
                    setSelectProject(e.target.value);
                    setCurrentPage(0);
                  }}
                >
                  <option value={""}>Select Project</option>
                  {employeeList.map((emp) => {
                    return (
                      <option value={emp.ProjectId}>{emp.ProjectName}</option>
                    );
                  })}
                </select>
              </div>
              <div className="role-table-header">
                <div className="entries-wrapper">
                  <p>Entries per page</p>
                  <select
                    style={{ width: "200px" }}
                    className="form-select select-drop-icon "
                    aria-label="Default select example"
                    onChange={(e) => {
                      setUsersPerPage(e.target.value);
                      setCurrentPage(0);
                    }}
                  >
                    <option value="5">5</option>
                    <option value="10">10</option>
                    <option value="15">15</option>
                    <option value="20">20</option>
                    <option value="25" selected>
                      25
                    </option>
                  </select>
                </div>
              </div>
            </div>
          </div>
          <section className="cx-content-section">
            {isLoading ? (
              <Loader />
            ) : documentList && documentList?.length > 0 ? (
              <div className="row main-row">
                <div className="personal-section">
                  <section className="personal-section-wrapper">
                    <div>
                      <div className="templates_body-main row">
                        {documentList
                          .slice(startIndex, endIndex)
                          .map((document) => {
                            return (
                              <div className="col-lg-3 col-md-4 personal_card">
                                <div className="training-letter">
                                  <label
                                    htmlFor="temp1"
                                    className="label-main-out d-block"
                                  >
                                    <div className="text-logo ">
                                    <label className="text-dark" style={{ maxWidth: "220px", maxHeight: "30px", overflow: "hidden" }} htmlFor="">{document?.DocumentHeading.length > 30 ? document?.DocumentHeading.slice(0,30)+"..." : document?.DocumentHeading}</label>

                                      <div className="d-flex">
                                        <img
                                          src={edit}
                                          alt=""
                                          onClick={() => {
                                            setEditId(document.DocumentId);
                                            handleUpdate(document);
                                            setIsDocumentOpen(true);
                                            handleChange(
                                              "DocumentHeading",
                                              document.DocumentHeading
                                            );
                                          }}
                                        />{" "}
                                        <img
                                          src={delete_ic}
                                          alt=""
                                          onClick={() => {
                                            deleteProjectDocument(
                                              document.DocumentId
                                            );
                                          }}
                                        />{" "}
                                        <img
                                          src={download_icon}
                                          alt=""
                                          onClick={() =>
                                            downlodPDF(document.DocumentId)
                                          }
                                        />{" "}
                                      </div>
                                    </div>
                                    <div className="right-logo logo-back">
                                      <div
                                        className="img-right img"
                                        style={{
                                          display: "flex",
                                          justifyContent: "center",
                                          alignItems: "center",
                                        }}
                                      >
                                        <img
                                          // src={img_logo}
                                          src={
                                            document?.Extension === ".png" ||
                                              document?.Extension === ".jpg"
                                              ? imgicon
                                              : document?.Extension === ".pdf"
                                                ? pdficon
                                                : document?.Extension === ".docx"
                                                  ? wordIcon
                                                  : document?.Extension === ".xlsx"
                                                    ? xlicon
                                                    : img_logo
                                          }
                                          alt=""
                                          className="folder-img"
                                          onClick={() =>
                                            handleShowdoc(document)
                                          }
                                        />
                                      </div>
                                    </div>
                                  </label>
                                </div>
                              </div>
                            );
                          })}
                      </div>
                    </div>
                  </section>
                </div>
              </div>
            ) : (
              <>
                <NoData />
              </>
            )}
          </section>
          <Pagination
            data={documentList}
            pageChangeHandler={pageChangeHandler}
            usersPerPage={usersPerPage}
            currentPage={currentPage}
          // searchValue={searchValue}
          // searchedDataPageCounts={searchedDataPageCounts}
          />
        </div>
      </div>

      <Modal
        style={{ zIndex: "2200" }}
        show={showdoc}
        onHide={handleClosedoc}
        className="modal-templates"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Body className="document-popup-modal">
          {showFileImg.current?.Extension === ".png" ||
            showFileImg.current?.Extension === ".jpg" ? (
            <img
              src={`${BASE_URL}uploads/project/${showFileImg.current.ProjectId}/${showFileImg.current.FileName}`}
              alt="Preview"
              className="img-fluid"
            />
          ) : showFileImg.current?.Extension === ".pdf" ? (
            <embed
              src={`${BASE_URL}uploads/project/${showFileImg.current.ProjectId}/${showFileImg.current.FileName}`}
              type="application/pdf"
              width="100%"
              height="500px"
            />
          ) : showFileImg.current?.Extension === ".docx" ||
            showFileImg.current?.Extension === ".xlsx" ? (
            <p>
              Preview not available for this document type. You can download it
              to view.
            </p>
          ) : (
            <p>No preview available.</p>
          )}
        </Modal.Body>
      </Modal>

      <Modal
        className="delete-news-modal"
        show={isDocumentOpen}
        onHide={handleCloseDocument}
        dialogClassName="modal-90w"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title className="delete-title">
            {editId ? "Update" : "Upload"} Document
          </Modal.Title>
        </Modal.Header>
        <div className="lineSeperate"></div>
        <div className="p-3">
          {/* {
            !editId && */}
          <Form.Group className="mb-3">
            <Form.Label>File</Form.Label>
            <Form.Control
              type="file"
              accept=".pdf, .jpg, .jpeg, .png, .xlsx, .xls"
              onChange={(e) => handleChange("File", e.target.files[0])}
            />
            {editId &&
              documentDetails.File &&
              (typeof documentDetails.File === "string"
                ? documentDetails.File.slice(0, 40)
                : documentDetails.File.name)}
            {isShowValidation && !documentDetails.File && (
              <div className="text-danger">Please Select Document </div>
            )}
          </Form.Group>
          {/* } */}

          <Form.Group className="mb-3">
            <Form.Label>Document Name</Form.Label>
            <Form.Control
              type="text"
              placeholder="Display Name"
              value={documentDetails.DocumentHeading}
              onChange={(e) => {
                handleChange("DocumentHeading", e.target.value);
              }}
            />
            {isShowValidation && !documentDetails.DocumentHeading && (
              <div className="text-danger">Please Enter Document Name </div>
            )}
          </Form.Group>
          {/* {
            !editId && */}
          <Form.Group>
            <Form.Select
              className="mb-3"
              value={documentDetails.EmployeeId}
              onChange={(e) => handleChange("EmployeeId", e.target.value)}
            >
              <option>Select Project</option>
              {employeeList.map((emp) => {
                return <option value={emp.ProjectId}>{emp.ProjectName}</option>;
              })}
            </Form.Select>
            {isShowValidation && !documentDetails.EmployeeId && (
              <div className="text-danger">Please Select Employee </div>
            )}
          </Form.Group>
          {/* } */}
        </div>
        <div className="d-flex justify-content-end align-items-center mainBtnsSub">
          <button
            className="btn-width cancelBtn mx-3"
            onClick={handleCloseDocument}
          >
            Cancel
          </button>

          <button
            className="btn-width saveBtn"
            onClick={handleSubmit}
            disabled={isUpload}
          >
            {isUpload ? <ButtonLoader /> : editId ? "Update" : "Upload"}
          </button>
        </div>
      </Modal>

      <Modal
        className="delete-news-modal"
        show={show}
        onHide={handleClose}
        dialogClassName="modal-90w"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title className="delete-title">
            Delete Confirmation
          </Modal.Title>
        </Modal.Header>
        <div class="lineSeperate"></div>
        <Modal.Body className="delete-body ps-4">
          {/* <p>Are you sure, You want to delete? 
            {/* {"( "+deleteProjectName + " )"} */}
          <p> Are you sure, You want to delete this Project Document </p>
        </Modal.Body>

        <div className="lineSeperate"></div>
        <div className="d-flex justify-content-end align-items-center mainBtnsSub">
          <button className="btn-width cancelBtn mx-3" onClick={handleClose}>
            Cancel
          </button>
          <button
            className="btn-width saveBtn"
            onClick={() => {
              //deleteProject(useDeleteTaskList)
              // deleteDesignation(DeleteTaskList);
              // handleClose4();
              onDeleteProjectDocument();
            }}
          >
            Delete
          </button>
        </div>
      </Modal>
    </>
  );
};

export default ProjectDocumentation;
