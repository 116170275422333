import { Form } from "react-bootstrap";
import { AppContext } from "../../context/AppContext"
import { useContext, useEffect, useState } from "react"
import { PostCallWithErrorResponse, PostCallWithErrorResponseReqBody, simpleGetCall, simpleGetCallWithErrorResponse } from "../../api/ApiServices";
import ApiConfig from "../../api/ApiConfig";
import { ToastMsg } from "../TostMsg";
import ButtonLoader from "../../sharedComponent/ButtonLoader";

function EmployeeCode(){

  const { sidebar, setSidebar, Dark, setDark, userData } =
  useContext(AppContext);

  const [validated,setValidated] = useState(false)

  const initialState={
    numberingType:'',
    prefix:'',
    suffix:'',
    locationId:'',
    FirstNumber:'',
    CounterLength:'',
    NumberingType:""
  }

  const [employeeCodeDetails,setEmployeeCodeDetails] = useState(initialState)

  const [isUpdate,setIsUpdate] = useState(false)

  const [loading,setLoading] = useState(false)

  const [locations,setLocations] = useState([])

  const getLocations=()=>{

    simpleGetCallWithErrorResponse(ApiConfig.GET_LOCATION).then((res)=>{

        console.log(res);

        if(res.json.Success){

            setLocations(res.json.Data)

        }

    })

  }

  useEffect(()=>{

       getLocations()


  },[])

  const getEmployeeCodeDetalis=(locationId)=>{

    simpleGetCallWithErrorResponse(`${ApiConfig.GET_EMPLOYEE_BY_ID}?locationId=${locationId}&OrgId=${userData.OrgId}`).then((res)=>{
      console.log(res);
      setEmployeeCodeDetails({
         Id:res.json.Id,
         prefix:res.json.TypePrefix,
         suffix:res.json.TypeSuffix,
         locationId:res.json.LocationId,
         FirstNumber:res.json.FirstNumber,
         CounterLength:res.json.CounterLength,
         NumberingType:res.json.NumberingType,
         orgId:res.json.OrgId

      })

      setIsUpdate(true)

    }).catch((err)=>{

      console.log(err);

      setEmployeeCodeDetails(prev=>({
        ...initialState,
        locationId:locationId
        }
        ))

        setIsUpdate(false)

    })

  }

  const submitData=(e)=>{

    console.log(employeeCodeDetails);

    e.preventDefault();
    const form = e.currentTarget

      if(form.checkValidity() === false){

        e.preventDefault();
        e.stopPropagation();

        setValidated(true)

      }else{

        setLoading(true)

        let details = {
          OrgId:Number(userData.OrgId),
          LocationId:Number(employeeCodeDetails.locationId),
          FirstNumber:Number(employeeCodeDetails.FirstNumber),
          CounterLength:Number(employeeCodeDetails.CounterLength),
          TypePrefix:employeeCodeDetails.prefix,
          TypeSuffix:employeeCodeDetails.suffix,
          NumberingType:employeeCodeDetails.NumberingType,
          isDeleted:false,
        }

        if(employeeCodeDetails?.Id){

          details.Id=employeeCodeDetails.Id

        }

       // employeeCodeDetails?.Id?details.Id=employeeCodeDetails.Id:""


        PostCallWithErrorResponse(ApiConfig.EMPLOYEE_CODE,JSON.stringify(details)).then((res)=>{

          console.log(res);

          ToastMsg('success',isUpdate?'Update Succesfully':"Add Successfully")


          setEmployeeCodeDetails(initialState)

          setValidated(false)

          setLoading(false)

          setIsUpdate(false)

        }).catch(()=>{

          setLoading(false)

        })

      }

  }


    return(
        <>
            <div
             className={sidebar ? "taskMain " : "cx-active taskMain"}
             id="cx-main"
        > 
          <div className="role-content">
            <div className="sub-header-role my-3">
            <h6 className="mt-2">Employee Code Setting</h6>
            </div>
          </div>
              <div className="bg-white mb-4 pt-4 pb-4 pl-4 pr-4 rounded">
                <Form noValidate validated={validated} onSubmit={submitData}>
                 <div className="row gy-3 role-content-main " style={{width:'100%'}}>
                   <div className="col-md-6 search-input-wrapper-main pt-3">
                    <label className="text-secondary">Location</label>
                    <select
                     className="form-select tasKCategory1 select-drop-icon mt-1"
                     style={{width:'100%'}}
                     aria-label="Default select example"
                     required
                     value={employeeCodeDetails.locationId}
                     onChange={(e)=>{

                      getEmployeeCodeDetalis(e.target.value)
                      /*setEmployeeCodeDetails(prev=>({
                        ...prev,
                        locationId:e.target.value
                      }))*/

                     }}
                     >
                       <option value="">Select Location</option>
                       {
                        locations.map((location,index)=>{

                            return <option key={index} value={location.LocationId}>{location.LocationName}</option>

                        })
                       }       
                      </select>
                      <Form.Control.Feedback type="invalid">Select Location</Form.Control.Feedback>
                   </div>
                   <div className="col-md-6 search-input-wrapper-main pt-3">
                    <label className="text-secondary">Numbering Type</label>
                    <select
                     className="form-select tasKCategory1 select-drop-icon mt-1"
                     style={{width:'100%'}}
                     aria-label="Default select example"
                    // required
                    value={employeeCodeDetails.NumberingType}
                      onChange={(e)=>{

                         setEmployeeCodeDetails(prev=>({
                          ...prev,
                          NumberingType:e.target.value
                         }))

                      }}
                     >
                       <option value="">Select Type</option>    
                       <option value={'Auto'}>Auto</option>      
                       <option value={'Manual'}>Manual</option>       
                      </select>
                   <Form.Control.Feedback type="invalid">Select Numbering Type</Form.Control.Feedback>
                   </div>
                   <div className="col-md-6 ">
                   <label className="text-secondary">Prefix</label>
                      <input 
                      className="form-control tasKCategory1"
                      //style={{width:"100%"}}
                      required
                      value={employeeCodeDetails.prefix}
                      onChange={(e)=>{

                         setEmployeeCodeDetails(prev=>({
                          ...prev,
                          prefix:e.target.value
                         }))

                      }}
                      />
                      <Form.Control.Feedback type="invalid">please Enter prefix</Form.Control.Feedback>
                      
                   </div>
                   <div className="col-md-6 ">
                   <label className="text-secondary">Suffix</label>
                      <input 
                      className="form-control tasKCategory1"
                      style={{width:"100%"}}
                      //required
                      value={employeeCodeDetails.suffix}
                      onChange={(e)=>{

                        setEmployeeCodeDetails(prev=>({
                          ...prev,
                          suffix:e.target.value
                        }))

                      }}
                      />
                      <Form.Control.Feedback type="invalid">please Enter suffix</Form.Control.Feedback>
                   
                   </div>

                   <div className="col-md-6 ">
                   <label className="text-secondary">First Number</label>
                      <input 
                      className="form-control tasKCategory1"
                      type="number"
                      style={{width:"100%"}}
                      required
                      value={employeeCodeDetails.FirstNumber}
                      onChange={(e)=>{

                        setEmployeeCodeDetails(prev=>({
                          ...prev,
                          FirstNumber:e.target.value
                        }))

                      }}
                      />
                      <Form.Control.Feedback type="invalid">please Enter First Number</Form.Control.Feedback>
                   </div>
                   
                   <div className="col-md-6 ">
                   <label className="text-secondary">Counter Length</label>
                      <input 
                      type="number"
                      className="form-control tasKCategory1"
                      style={{width:"100%"}}
                      required
                      value={employeeCodeDetails.CounterLength}
                      onChange={(e)=>{
                        setEmployeeCodeDetails(prev=>({
                          ...prev,
                          CounterLength:e.target.value
                        }))
                      }}
                      />
                      <Form.Control.Feedback type="invalid">please Enter Counter Length</Form.Control.Feedback>
                   </div>
                 </div>
                 
                 <div className="d-flex justify-content-end mt-3">
                   <button
                   type='button'
                   className='modal-cancel-btn branchList-modal-cancel-btn-arbaz-responsive'>
                     Cancel
                   </button>
                   <button
                   type="submit"
                   className='modal-create-btn branchList-modal-cancel-btn-arbaz-responsive'
                   disabled={loading}
                   >
                     {
                      loading?<ButtonLoader/>:isUpdate?"Update":'Save'
                     }
                   </button>

                 </div>
                </Form>
              </div>

            </div>
            
        </>
    )

}

export default EmployeeCode