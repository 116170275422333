import React, { useContext, useEffect, useRef, useState } from "react";

import {
  faPenToSquare,
  faTrash,
  faPlus,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import NoDataFound from "../../../pages/ChatAdmin/NodataFound";

import "../styles/chatUi.css";
import { downloadFile, getPlainText } from "../../../pages/ChatAdmin/commaon";

import { Accordion } from "react-bootstrap";
import { Form } from "react-bootstrap";
import Pagination from "../Pagination";
import { AppContext } from "../../../context/AppContext";
import {
  deleteWithAuthCall,
  PostCallWithErrorResponse,
  simpleGetCall,
  simpleGetCallWithToken,
} from "../../../api/ApiServices";
import ApiConfig from "../../../api/ApiConfig";
import { ToastMsg } from "../../TostMsg";
import Loader from "../loader";

function TraineeQues() {
  const navigate = useNavigate();
  const [selectedChatDetails, setSelectedChatDetails] = useState(undefined);

  const [selectedChat, setSelectedChat] = useState(undefined);

  const ref = useRef(null);

  const [search, setSearch] = useState("");

  const [chats, setChats] = useState([]);

  const [questionList, setQuestionList] = useState([]);

  const [totalPage, setTotalPage] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);

  const [listingType, setListingType] = useState("all");

  const {
    isDrawerOpen,
    selectedDomainId,
    sidebar,
    setBackTo,
    setAddInExisting,
  } = useContext(AppContext);

  const [isLoading, setIsLoading] = useState(true);

  const [tolalRecords, setTotalRecords] = useState(0);

  const [isDownload, setIsDownload] = useState(false);

  const [startIndex, setStartIndex] = useState(0);

  const handleChatClick = (selectedChat) => {
    setSelectedChat(selectedChat);
    // setSelectedChatDetails(undefined);

    //setSelectedChatDetails(getChatDetails);
    // RasaAdminAPI.getChat(selectedChat.sender_id).then(data => {
    // });
  };

  function getAnswerList() {
    setIsLoading(true);

    let query =
      listingType == "all"
        ? ""
        : listingType == "published"
        ? "&start_range=4"
        : "&start_range=1&end_range=3";

    simpleGetCall(
      ApiConfig.GET_MULTIPLE_QUESTION +
        "?page=" +
        currentPage +
        "&page_limit=10" +
        "&domain_id=" +
        selectedDomainId +
        "&search=" +
        search +
        query
    )
      .then((res) => {
        console.log(res);
        if (res.success) {
          setTotalRecords(res?.total);
          setTotalPage(res?.total_pages);
          setQuestionList(res.data);
          // setStartIndex((currentPage-1)*10)
        } else {
          setQuestionList([]);
          setTotalRecords(0);
        }
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
      });
  }

  useEffect(() => {
    setCurrentPage(1);
    getAnswerList();
  }, [selectedDomainId, listingType]);

  useEffect(() => {
    getAnswerList();
  }, [currentPage]);

  const deleteQuestion = (id) => {
    deleteWithAuthCall(ApiConfig.DELETE_QUESTION + id)
      .then((res) => {
        if (res?.success) {
          ToastMsg("success", res?.message);

          getAnswerList();
        } else {
          ToastMsg("error", res?.message);
        }
      })
      .catch((err) => {
        ToastMsg("error", "Something Went Wrong Try Again");
      });
  };

  const downloadList = () => {
    setIsDownload(true);
    setIsLoading(true);
    simpleGetCallWithToken(
      ApiConfig?.GET_MULTIPLE_QUESTION +
        "?domain_id=" +
        Number(selectedDomainId) +
        "&&page_limit=" +
        tolalRecords +
        "&start_range=4"
    )
      .then((res) => {
        if (res?.success) {
          downloadFile({
            data: JSON.stringify(res?.data, null, 2),
            fileName: "answers.json",
            fileType: "text/json",
          });
          setIsLoading(false);
        } else {
          ToastMsg("error", res?.Message);
        }
        setIsDownload(false);
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  // const [count, setCount] = useState(0);
  // const getCount = () => {
  //   simpleGetCallWithToken(ApiConfig?.GET_COUNTS + selectedDomainId)
  //     .then((res) => {
  //       if (res?.success) {
  //         if (listingType === "all") {
  //           setCount(res?.data.length);
  //         } else {
  //           setCount(0);
  //         }
  //       }
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //     });
  // };

  const uploadExcell = (e) => {
    setIsLoading(true);

    let file = e.target.files[0];

    console.log(file);

    let formData = new FormData();
    formData.append("domain_id", selectedDomainId);
    formData.append("file", e.target.files[0]);

    PostCallWithErrorResponse(ApiConfig.IMPORT_ANSERS_LIST, formData)
      .then((res) => {
        if (res?.success) {
          ToastMsg("success", res?.message);

          getAnswerList();

          setIsLoading(false);
        } else {
          ToastMsg("error", res?.message);
        }
        setIsLoading(false);
      })
      .catch((err) => {
        ToastMsg("error", "Something Went Wrong");

        setIsLoading(false);
      });
  };

  return (
    <>
      <div
        className={sidebar ? "taskMain " : "cx-active taskMain"}
        id="cx-main"
      >
        <div>
          <div>
            <div className="role-content">
              <div className="sub-header-role d-flex justify-content-between">
                <h6 className="mt-2">Import Training Questions</h6>
                {/* <h6 className="mt-2 mr-2">Domain Name : {domainName}</h6> */}
                <div>
                  <input
                    type="file"
                    name=""
                    id=""
                    style={{ display: "none" }}
                    ref={ref}
                    accept=".xls,.xlsx"
                    onChange={uploadExcell}
                  />

                  <button
                    className="AddQuestion_submit_btn mx-3"
                    onClick={() => {
                      ref.current.click();
                    }}
                  >
                    Import{" "}
                  </button>
                  {/* <button className="AddQuestion_submit_btn mx-3"
            >Download</button> */}
                  {listingType == "published" && (
                    <button
                      className="AddQuestion_submit_btn mx-3"
                      onClick={downloadList}
                    >
                      Publish
                    </button>
                  )}
                </div>
              </div>
              <div className="d-flex justify-content-between mt-4">
                <div className="input_wraper d-flex ">
                  <Form.Select
                    aria-label="Default select example"
                    style={{ width: "350px", height: "42px" }}
                    value={listingType}
                    onChange={(e) => setListingType(e.target.value)}
                  >
                    <option value="all">All</option>
                    <option value="published">Published</option>
                    <option value="Non-Published">Non-Published</option>
                  </Form.Select>
                  <input
                    placeholder="Search By Answers"
                    type="text"
                    style={{ width: "350px", marginLeft: "10px" }}
                    value={search}
                    onChange={(e) => setSearch(e.target.value)}
                  />
                  <button
                    className="AddQuestion_submit_btn mx-3"
                    onClick={getAnswerList}
                  >
                    Search
                  </button>
                </div>
                <div className="d-flex align-items-center">
                  <p className="my-auto">Count : {tolalRecords}</p>
                </div>

                <div>
                  <button
                    className="AddQuestion_submit_btn mx-3"
                    style={{ height: "42px" }}
                    type="button"
                    onClick={() => {
                      setBackTo("/traineeQues");
                      navigate("/addQuestion");
                    }}
                  >
                    Add Question
                  </button>
                </div>
              </div>
              <div className=".table-wrapper-Admin table-.table-wrapper-Admin-2">
                <Accordion defaultActiveKey="0">
                  {isLoading ? (
                    <Loader />
                  ) : questionList?.length > 0 ? (
                    questionList.map((answer, index) => {
                      return (
                        <Accordion.Item eventKey={index} key={index}>
                          <Accordion.Header>
                            <div
                              style={{ backgroundColor: "white" }}
                              className="py-3 rounded-3 px-2 d-flex justify-content-between w-100"
                            >
                              <h6
                                className=""
                                style={{ width: "80%", fontWeight: "bold" }}
                              >
                                Answer : {getPlainText(answer?.answer)}
                              </h6>
                              <h6
                                className={`ml-1 mr-2 ${
                                  answer?.question.length < 4
                                    ? "text-warning"
                                    : "text-success"
                                }`}
                              >
                                {answer?.question?.length < 4 ? "Not" : ""}{" "}
                                Published
                              </h6>
                              <div style={{ marginRight: "50px" }}>
                                <OverlayTrigger
                                  placement="bottom"
                                  delay={{ show: 100, hide: 300 }}
                                  overlay={(props) => (
                                    <Tooltip id="button-tooltip" {...props}>
                                      Add Questtions
                                    </Tooltip>
                                  )}
                                >
                                  <FontAwesomeIcon
                                    onClick={() => {
                                      setAddInExisting({
                                        answer: answer?.answer,
                                        intent: answer?.intent,
                                        keyword: answer?.keyword,
                                        productModule: answer?.product_module,
                                      });
                                      setBackTo("/traineeQues");
                                      navigate("/addQuestion?add=add");
                                    }}
                                    icon={faPlus}
                                    style={{ width: "15px", height: "15px" }}
                                  />
                                </OverlayTrigger>{" "}
                              </div>
                            </div>
                          </Accordion.Header>
                          <Accordion.Body>
                            {
                              <div
                                className="table-common"
                                style={{
                                  height: "400px",
                                  marginTop: "0px",
                                  backgroundColor: "white",
                                }}
                              >
                                <div
                                  style={{
                                    width: "100%",
                                    borderTop: "2px solid grey",
                                  }}
                                ></div>
                                {answer?.question?.length > 0 ? (
                                  <table style={{ marginBottom: "15px" }}>
                                    <thead className="">
                                      <tr className="firstTabelRow">
                                        <th>Sr.No</th>
                                        <th>Question</th>
                                        <th>Answers</th>
                                        <th>Intent</th>
                                        <th>Action</th>
                                      </tr>
                                    </thead>
                                    {answer?.question?.map((item, index) => (
                                      <tr
                                        key={index}
                                        className="table-row-custom"
                                      >
                                        <td>{startIndex + index + 1} </td>
                                        <td>{item.question} </td>
                                        <td>{getPlainText(item?.answer)}</td>
                                        <td>{item?.intent}</td>
                                        <td className="d-flex">
                                          <OverlayTrigger
                                            placement="bottom"
                                            delay={{ show: 100, hide: 300 }}
                                            overlay={(props) => (
                                              <Tooltip
                                                id="button-tooltip"
                                                {...props}
                                              >
                                                Edit Question
                                              </Tooltip>
                                            )}
                                          >
                                            <FontAwesomeIcon
                                              onClick={() => {
                                                setBackTo("/traineeQues");
                                                navigate(
                                                  "/editQuestion/" + item?.id
                                                );
                                              }}
                                              icon={faPenToSquare}
                                              style={{
                                                width: "15px",
                                                height: "15px",
                                                marginRight: "10px",
                                              }}
                                            />
                                          </OverlayTrigger>{" "}
                                          <OverlayTrigger
                                            placement="bottom"
                                            delay={{ show: 100, hide: 300 }}
                                            overlay={(props) => (
                                              <Tooltip
                                                id="button-tooltip"
                                                {...props}
                                              >
                                                Delete Question
                                              </Tooltip>
                                            )}
                                          >
                                            <FontAwesomeIcon
                                              icon={faTrash}
                                              onClick={() =>
                                                deleteQuestion(item?.id)
                                              }
                                              style={{
                                                width: "15px",
                                                height: "15px",
                                              }}
                                            />
                                          </OverlayTrigger>
                                        </td>
                                      </tr>
                                    ))}
                                  </table>
                                ) : (
                                  <NoDataFound />
                                )}
                              </div>
                            }
                          </Accordion.Body>
                        </Accordion.Item>
                      );
                    })
                  ) : (
                    <NoDataFound />
                  )}
                </Accordion>
              </div>
              <Pagination
                totalPage={totalPage}
                currentPage={currentPage}
                pageChangeHandler={(page) => setCurrentPage(page + 1)}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default TraineeQues;
