
import nodata from "../../assets/images/icons/Group 703.svg";
function NoDataFound(){
    return(
        <div className="d-flex justify-content-center align-items-center">
            <img src={nodata } style={{scale:"0.57"}} />
            {/* <h5 className="text-center textColor">
                
            </h5> */}
        </div>
    )
}

export default NoDataFound