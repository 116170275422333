import React from "react";
import ReactPaginate from "react-paginate";

const Pagination = ({
  data,
  pageChangeHandler,
  usersPerPage,
  currentPage,
  searchValue,
  searchedDataPageCounts,
}) => {
  const pageCount = Math.ceil(data?.length / usersPerPage);
  const searchedPageCounts = Math.ceil(
    searchedDataPageCounts?.length / usersPerPage
  );

  return (
    <div
      className="row mt-7"
      style={{
        position: "absolute",
        bottom: "5px",
        right: "25px",
      }}
    >
      <div className="col-lg-12">
        <ReactPaginate
          previousLabel={"Previous"}
          nextLabel={"Next"}
          pageCount={searchValue === "" ? pageCount : searchedPageCounts}
          onPageChange={pageChangeHandler}
          containerClassName={"paginationBttns"}
          previousLinkClassName={"previousBttn"}
          nextLinkClassName={"nextBttn"}
          disabledClassName={"paginationDisabled"}
          activeClassName={"paginationActive"}
          forcePage={currentPage}
        />
      </div>
    </div>
  );
};

export default Pagination;
