import { Link } from "react-router-dom";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import React, { useState, useContext, useEffect } from "react";
import "../../assets/css/tasklist.scss";
import searchicon from "../../assets/images/ic_search.svg";
import ImgVector from "../../assets/images/img_vector.svg";
import view from "../../assets/images/view.svg";
import g20 from "../../assets/images/g20.png";
import avatar from "../../assets/images/avatar.svg";
import { Button, Carousel, Dropdown } from "react-bootstrap";
import { AppContext } from "../../context/AppContext";
import optionIcon from "../../assets/images/bx_edit.svg";
import ic_search_dark from "../../assets/images/ic_search_dark.svg";
import ReactPaginate from "react-paginate";
import {
  multipartPostCallWithErrorResponse,
  PostCallWithErrorResponse,
  simpleGetCallWithErrorResponse,
} from "../../api/ApiServices";
import ApiConfig from "../../api/ApiConfig";
import Loader from "../Loader";
import { ToastMsg } from "../TostMsg";
import BootstrapSpinner from "../../sharedComponent/BootstrapSpinner";
import ButtonLoader from "../../sharedComponent/ButtonLoader";
import NoData from "../../sharedComponent/NoData";
import Axe from "../../assets/images/sidebar/icons/axeMark.svg";
import Pagination from "../../sharedComponent/Pagination";
import Select from "react-select";


const AllAnnouncement = () => {
  const [show, setShow] = useState(false);
  const { sidebar, setSidebar, Dark, setDark, userData, permissions } =
    useContext(AppContext);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [allannList, setAllannList] = useState([]);
  const [viewCountList, setViewCountList] = useState([]);
  const [validated, setValidated] = useState(false);
  const [announcment, setAnnouncment] = useState([]);
  const [show4, setShow4] = useState(false);
  const handleClose4 = () => setShow4(false);
  const handleShow4 = () => setShow4(true);
  const [loading, setLoading] = useState(false);
  const [mloading, setMLoading] = useState(false);
  const [show5, setShow5] = useState(false);
  const handleClose5 = () => setShow5(false);
  const handleShow5 = () => setShow5(true);
  const [employName, setEmployName] = useState([]);
  const [priority, setPriority] = useState([]);
  const [branchName, setBranchName] = useState([]);
  const [teamName, setTeamName] = useState([]);
  const [show6, setShow6] = useState(false);

  const [addAnnouncment, setAddAnnouncment] = useState({
    Title: "",
    AnnouncementDate: "",
    AssignToBranchId:[],
    Message: "",
    PriorityId: "",
    AttachmentPath: null,
    AttachmentPreview: "",
    OrgId: Number(userData.OrgId),
    CreatedBy: Number(userData.EmpId),
    ViewCount: 0,
    AssignToTeamId: "",
    AssignToEmployeeId: "",
  });

  console.log("AssignToBranchId>>>>",addAnnouncment?.AssignToBranchId)

  const [searchValue, setSearchValue] = useState("");
  const [currentPage, setCurrentPage] = useState(0);
  const [usersPerPage, setUsersPerPage] = useState(25);
  const [selectdOpentions,setSelectedOptions] = useState([])

  const startIndex = currentPage * usersPerPage;
  const endIndex = startIndex + parseInt(usersPerPage);

  const pageChangeHandler = ({ selected }) => {
    setCurrentPage(selected);
  };

  const searchedDataPageCounts =
    allannList &&
    allannList.length &&
    allannList.filter((item) =>
      item.ProjectName?.toLowerCase().includes(searchValue)
    );

  const today = new Date().toISOString().split("T")[0];

  const handleClose6 = () => {
    setShow6(false);
  };
  const handleShow6 = () => {
    setShow6(true);
    setShow4(false);
  };

  useEffect(() => {
    getAnnounctmentApi();
    getBranchApi();
    getTeamApi();
    getEmployeeApi();
    getPriorityApi();
  }, []);

  const getAnnounctmentApi = () => {
    setLoading(true);
    PostCallWithErrorResponse(
      ApiConfig.ALL_ANNOUNCEMENTS_LIST,
      JSON.stringify({
        EmpId: Number(userData.EmpId),
        OrgId: Number(userData.OrgId),
      })
    )
      .then((res) => {
        setLoading(false);
        console.log("getAnnounctmentApi res,", res);
        setAllannList(res.json.Data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getViewCountApi = (id) => {
    setMLoading(true);
    PostCallWithErrorResponse(
      ApiConfig.VIEW_MEMBER_LIST,
      JSON.stringify({
        OrgId: Number(userData.OrgId),
        annoucementid: id,
      })
    )
      .then((res) => {
        setMLoading(false);
        setViewCountList(res.json.Data);
      })
      .catch((err) => {
        console.log(err);
        setMLoading(false);
      });
  };

  const getPreViewCountApi = (id) => {
    setMLoading(true);
    PostCallWithErrorResponse(
      ApiConfig.PREVIEW_ANNOUNCEMENTS,
      JSON.stringify({
        AnnoucementId: id,
        // EmpId: Number(userData.EmpId),
        OrgId: Number(userData.OrgId),
      })
    )
      .then((res) => {
        setMLoading(false);
        setAnnouncment(res.json.Data);
      })
      .catch((err) => {
        setMLoading(false);
        console.log(err);
      });
  };
  const getBranchApi = () => {
    PostCallWithErrorResponse(
      ApiConfig.ORGANIZATION_BRANCH,
      JSON.stringify({
        OrgId: Number(userData.OrgId),
      })
    )
      .then((res) => {
        setBranchName(res.json.Data);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const getTeamApi = () => {
    PostCallWithErrorResponse(
      ApiConfig.ORGANIZATION_TEAM,
      JSON.stringify({
        OrgId: Number(userData.OrgId),
      })
    )
      .then((res) => {
        setTeamName(res.json.Data);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const getEmployeeApi = () => {
    PostCallWithErrorResponse(
      ApiConfig.EMPLOEE_NAME_LIST,
      JSON.stringify({
        OrgId: Number(userData.OrgId),
      })
    )
      .then((res) => {
        setEmployName(res.json.Data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getPriorityApi = () => {
    simpleGetCallWithErrorResponse(ApiConfig.PRIORITY_MASTER)
      .then((res) => {
        setPriority(res.json.Data);
        // console.log("GET Priority API res.json.data---->", res.json)
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const updateViewCoutnapi = (id) => {
    PostCallWithErrorResponse(
      ApiConfig.UPDATE_VIEW_COUNT,
      JSON.stringify({
        EmpId: Number(userData.EmpId),
        OrgId: Number(userData.OrgId),
        AnnoucementId: id,
      })
    )
      .then((res) => {
        console.log(res);
        // ToastMsg("success", res.json.Message);
      })
      .catch((err) => {});
  };
  const handleChange = (selected) => {
    setSelectedOptions(selected);
  };

  useEffect(() => {
    const selectedMembers = selectdOpentions.map((member) => member.value);

    // setEmployeeId(selectedMembers);
   
      setAddAnnouncment((prev) => ({
        ...prev, // Spread the previous state
        AssignToBranchId: selectedMembers, // Update the specific key
      }));
    
    console.log("selected Add member--->", addAnnouncment.AssignToBranchId);
  }, [selectdOpentions]);
  const [btnLoading, setBtnLoading] = useState(false);
  const AddorUpdateAnnouncmentApi = async (e) => {
    e.preventDefault();
    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      e.preventDefault();
      e.stopPropagation();
    } else {
      let fromdata = new FormData();
      fromdata.append("Title", addAnnouncment.Title);
      fromdata.append("AnnouncementDate", addAnnouncment.AnnouncementDate);
      // fromdata.append("AssignToBranchId", addAnnouncment.AssignToBranchId);
      addAnnouncment.AssignToBranchId.forEach((branchId) => {
        fromdata.append("AssignToBranchId", branchId);
      });
      fromdata.append("Message", addAnnouncment.Message);
      fromdata.append("PriorityId", addAnnouncment.PriorityId);
      fromdata.append("AttachmentPreview", addAnnouncment.AttachmentPreview);
      fromdata.append("OrgId", addAnnouncment.OrgId);
      fromdata.append("ViewCount", addAnnouncment.ViewCount);
      fromdata.append("AssignToTeamId", addAnnouncment.AssignToTeamId);
      fromdata.append("AssignToEmployeeId", addAnnouncment.AssignToEmployeeId);
      fromdata.append("CreatedBy", addAnnouncment.CreatedBy);
      fromdata.append("AttachmentPath", addAnnouncment.AttachmentPath);
      setBtnLoading(true);
      multipartPostCallWithErrorResponse(ApiConfig.CRATE_ANNOUNCEMENT, fromdata)
        .then((response) => {
          if (response.json.Success) {
            ToastMsg("success", response.json.Message);
            getAnnounctmentApi();
            handleClose();
            setBtnLoading(false);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
    setValidated(true);
  };

  const options = branchName.map((name) => ({
    label: name.LocationName,
    value: name.LocationId,
  }));


  return (
    <>
      {/* <Header />
            <Sidebar /> */}
      <div
        className={sidebar ? "taskMain " : "cx-active taskMain"}
        id="cx-main"
      >
        <div className="role-content">
          <div className="sub-header-role ">
            <h6 className="mt-2">Announcemnet</h6>
            {permissions && permissions["Create Announcement"] && (
              <button
                className="create-btn-sub-header"
                variant="primary"
                onClick={() => {
                  setShow(true);
                  setAddAnnouncment({
                    Title: "",
                    AnnouncementDate: "",
                    AssignToBranchId:[],
                    Message: "",
                    PriorityId: "",
                    AttachmentPath: null,
                    AttachmentPreview: "",
                    OrgId: Number(userData.OrgId),
                    CreatedBy: Number(userData.EmpId),
                    ViewCount: 0,
                    AssignToTeamId: 0,
                    AssignToEmployeeId: 0,
                  });
                  setValidated(false);
                }}
              >
                + Add{" "}
              </button>
            )}
          </div>
          <div className="d-flex justify-content-between role-searchbar-and-btns RspSalary">
            <div className="role-table-header accoridance">
              <div className="search-input-wrapper allAcordiance">
                {permissions && permissions["View All Announcement"] && (
                  <Link to="/AllAnnouncement">
                    <button className=" active all-anc-btn-sub-header ">
                      All Announcement
                    </button>
                  </Link>
                )}
              </div>
              <div className="entries-wrapper myAccordiance">
                {permissions && permissions["View My Announcement"] && (
                  <Link to="/MyAnnouncement">
                    <button className="my-anc-btn-sub-header">
                      My Announcement
                    </button>
                  </Link>
                )}
              </div>
              <div className="role-table-header">
                <div className="entries-wrapper">
                  <p>Entries per page</p>
                  <select
                    style={{ width: "200px" }}
                    className="form-select select-drop-icon "
                    aria-label="Default select example"
                    onChange={(e) => {
                      setUsersPerPage(e.target.value);
                      setCurrentPage(0);
                    }}
                  >
                    <option value="5">5</option>
                    <option value="10">10</option>
                    <option value="15">15</option>
                    <option value="20">20</option>
                    <option value="25" selected>
                      25
                    </option>
                  </select>
                </div>
              </div>
            </div>
          </div>
          {/* <div className='emptyPage'>
                        <img src={emptypage} alt="" />
                    </div> */}
          <div
            className="table-wrapper custom-table-width"
            id="scrol-tblNtable-wrapper-af"
          >
            {loading ? (
              <Loader />
            ) : (
              <table>
                <thead>
                  <tr className="firstTabelRow">
                    <th>Sr.no</th>
                    <th>Branch, Team, Members</th>
                    <th>Title</th>
                    <th>Image</th>
                    <th>Description</th>
                    <th>Created By</th>
                    <th>Date</th>
                    <th>Priority</th>
                    {/* <th>Views</th> */}
                  </tr>
                </thead>

                <tbody>
                  {allannList && allannList.length > 0 ? (
                    allannList
                      .slice(startIndex, endIndex)
                      .map((announcement, index) => {
                        return (
                          <tr
                            className="table-row-custom"
                            key={"sdfe3" + index}
                            onClick={(e) =>
                              updateViewCoutnapi(announcement.AnnouncementId)
                            }
                            style={{ cursor: "pointer" }}
                          >
                            <td> {index + 1}</td>
                            <td> {announcement.AssignedToName}</td>
                            <td>{announcement.Title} </td>
                            <td
                              onClick={() => {
                                handleShow4();
                                getPreViewCountApi(announcement.AnnouncementId);
                              }}
                              className="pointerCurcer12"
                            >
                              <img
                                style={{ width: "30px", height: "30px" }}
                                src={
                                  !announcement.ImagePath
                                    ? ImgVector
                                    : announcement.ImagePath
                                    ? ApiConfig.BASE_URL_FOR_IMAGES +
                                      announcement.ImagePath
                                    : announcement.ImagePath &&
                                      URL.createObjectURL(
                                        announcement.ImagePath
                                      )
                                }
                                alt=""
                              />
                            </td>
                            <td
                              onClick={() => {
                                handleShow4();
                                getPreViewCountApi(announcement.AnnouncementId);
                              }}
                              className="pointerCurcer12 break-ln-spaces"
                            >
                              {" "}
                              {announcement.Description}
                            </td>
                            <td>{announcement.CreatedBy}</td>
                            <td>{announcement.Date.slice(0, 9)}</td>
                            <td
                              className={
                                announcement.Priority == "High"
                                  ? "status-red"
                                  : announcement.Priority == "Medium"
                                  ? "status-yellow"
                                  : announcement.Priority == "Low"
                                  ? "status-green"
                                  : ""
                              }
                            >
                              {announcement.Priority === "High" ? (
                                <span>High</span>
                              ) : announcement.Priority === "Medium" ? (
                                <span>Medium</span>
                              ) : announcement.Priority === "Low" ? (
                                <span>Low</span>
                              ) : (
                                ""
                              )}
                            </td>
                            {/* <td onClick={handleShow5} className="pointerCurcer12">
                            <img
                              src={view}
                              alt=""
                              onClick={(e) =>
                                getViewCountApi(announcement.AnnouncementId)
                              }
                            />
                            <span>{announcement.ViewCount}</span>
                          </td> */}
                          </tr>
                        );
                      })
                  ) : (
                    <>
                      <tr className="ErrorDataNotFound">
                        <td colSpan={6}>
                          <NoData />
                        </td>
                      </tr>
                    </>
                  )}
                </tbody>
              </table>
            )}
          </div>
        </div>
        <Pagination
          data={allannList}
          pageChangeHandler={pageChangeHandler}
          usersPerPage={usersPerPage}
          currentPage={currentPage}
          searchValue={searchValue}
          searchedDataPageCounts={searchedDataPageCounts}
        />
      </div>

      {/* Create News and Blogs Modal Start*/}

      {/* Create News and Blogs Modal Start*/}
      <Modal
        size="xl"
        className="create-news-modal"
        show={show}
        onHide={handleClose}
        centered
      >
        <Modal.Header className="create-header">
          <Modal.Title className="create-title">
            Create Announcement
          </Modal.Title>
          <div className="rightAxe">
            <img src={Axe} alt="" onClick={() => setShow(false)} />
          </div>
        </Modal.Header>
        <Form
          noValidate
          validated={validated}
          onSubmit={AddorUpdateAnnouncmentApi}
        >
          <Modal.Body className="">
            <div className="announcement-modal-body">
              <div className="row">
                <div class="col-md-6 mb-2">
                  <label class="mb-2 text-grey">Title</label>
                  <input
                    type="text"
                    placeholder=""
                    class="form-control tasKCategory1 "
                    onChange={(e) => {
                      setAddAnnouncment({
                        ...addAnnouncment,
                        Title: e.target.value,
                      });
                    }}
                    value={addAnnouncment.Title}
                    required
                    style={{
                      fontSize: "14px",
                      height: "38px",
                      border: "1px solid #ced4da",
                    }}
                  />
                  <div className="invalid-feedback">Please Enter Title</div>
                </div>
                <div class="col-md-6 mb-2">
                  <label class="mb-2 text-grey">Date</label>
                  <input
                    type="date"
                    placeholder="0"
                    class="form-control tasKCategory1"
                    onChange={(e) => {
                      setAddAnnouncment({
                        ...addAnnouncment,
                        AnnouncementDate: e.target.value,
                      });
                    }}
                    value={
                      addAnnouncment.AnnouncementDate.length &&
                      new Date(addAnnouncment.AnnouncementDate)
                        .toISOString()
                        .slice(0, 10)
                    }
                    min={today}
                    required
                    style={{
                      fontSize: "14px",
                      height: "38px",
                      border: "1px solid #ced4da",
                    }}
                  />
                  <div className="invalid-feedback">
                    Please select Announcemnet Date
                  </div>
                </div>
                <div className="col-lg-6 pe-0 mb-2">
                  <label for="" class="mb-2 text-grey">
                    Assign To Team
                  </label>
                  <select
                    // disabled={
                    //   addAnnouncment.AssignToBranchId ||
                    //   addAnnouncment.AssignToEmployeeId
                    //     ? true
                    //     : false
                    // }
                    class="form-select tasKCategory1 select-drop-icon"
                    aria-label="Default select example"
                    onChange={(e) => {
                      setAddAnnouncment({
                        ...addAnnouncment,
                        AssignToTeamId: e.target.value,
                      });
                    }}
                    value={addAnnouncment.AssignToTeamId}
                    // required
                    style={{
                      fontSize: "14px",
                      height: "38px",
                      border: "1px solid #ced4da",
                    }}
                  >
                    <option value="">Please select</option>
                    {teamName &&
                      teamName.map((team, index) => {
                        return (
                          <option key={"teadd" + index} value={team.TeamId}>
                            {team.TeamName}
                          </option>
                        );
                      })}
                  </select>
                  {/* <div className="invalid-feedback">Please select Team</div> */}
                </div>
                {/* <div class="col-md-6 row pe-0"> */}
                <div className="col-lg-6 pe-0 mb-2">
                  <label for="" class="mb-2 text-grey">
                    Assign To Branch
                  </label>
                  {/* <select
                    disabled={
                      addAnnouncment.AssignToTeamId ||
                      addAnnouncment.AssignToEmployeeId
                        ? true
                        : false
                    }
                    class="form-select tasKCategory1 select-drop-icon"
                    aria-label="Default select example"
                    onChange={(e) => {
                      setAddAnnouncment({
                        ...addAnnouncment,
                        AssignToBranchId: Number(e.target.value),
                      });
                    }}
                    value={addAnnouncment.AssignToBranchId}
                    required
                    style={{
                      fontSize: "14px",
                      height: "38px",
                      border: "1px solid #ced4da",
                    }}
                  >
                    <option value="">Please select</option>
                    {branchName &&
                      branchName.map((branch, index) => {
                        return (
                          <option
                            key={"branchName" + index}
                            value={branch.LocationId}
                          >
                            {branch.LocationName}
                          </option>
                        );
                      })}
                  </select> */}
                   <Select
                      class="form-select tasKCategory1 select-drop-icon"
                      options={options}
                      onChange={handleChange}
                      isMulti
                      onClick={(e) => {
                        setAddAnnouncment({
                          ...addAnnouncment,
                          AssignToBranchId: Number(e.target.value),
                        });
                        // setErrMsg({
                        //   ...errMsg,
                        //   EmployeeId: "",
                        // });
                      }}
                      style={{
                        control: (base) => ({
                          ...base,
                          fontSize: "15px",
                          height: "38px",
                          border: "1px solid #ced4da",
                          borderRadius: "50px", // Adjust the border-radius value here
                        }),
                      }}
                    />

                  <div className="invalid-feedback">Please select Branch</div>
                </div>
              
                <div className="col-lg-6 pe-0 mb-2">
                  <label for="" class="mb-2 text-grey">
                    Assign To Members
                  </label>
                  <select
                    // disabled={
                    //   addAnnouncment.AssignToBranchId ||
                    //   addAnnouncment.AssignToTeamId
                    //     ? true
                    //     : false
                    // }
                    class="form-select tasKCategory1 select-drop-icon"
                    aria-label="Default select example"
                    onChange={(e) => {
                      setAddAnnouncment({
                        ...addAnnouncment,
                        AssignToEmployeeId: e.target.value,
                      });
                    }}
                    value={addAnnouncment.AssignToEmployeeId}
                    // required
                    //options={addAnnouncment.AssignToEmployeeId}
                    isMulti
                    isClearable
                    style={{
                      fontSize: "14px",
                      height: "38px",
                      border: "1px solid #ced4da",
                    }}
                  >
                    <option value="">Please select</option>
                    {employName &&
                      employName.map((name, index) => {
                        return (
                          <option key={"dfgve" + index} value={name.Id}>
                            {name.FullName}
                          </option>
                        );
                      })}
                  </select>
                  {/* <div className="invalid-feedback">Please select Member</div> */}
                </div>
                <div className="col-lg-6 pe-0 mb-2">
                  <label for="" class="mb-2 text-grey">
                    Priority
                  </label>
                  <select
                    class="form-select tasKCategory1 select-drop-icon"
                    aria-label="Default select example"
                    onChange={(e) => {
                      setAddAnnouncment({
                        ...addAnnouncment,
                        PriorityId: e.target.value,
                      });
                    }}
                    required
                    value={addAnnouncment.PriorityId}
                    style={{
                      fontSize: "14px",
                      height: "38px",
                      border: "1px solid #ced4da",
                    }}
                  >
                    <option value="">Please select</option>
                    {priority &&
                      priority.map((pname, index) => (
                        <>
                          {console.log("P name --->", pname.PriorityName)}
                          <option
                            key={"priority" + index}
                            value={pname.PriorityId}
                          >
                            {pname.PriorityName}
                          </option>
                        </>
                      ))}
                    {/* <option value="1">High</option>
                      <option value="2">Medium</option>
                      <option value="3">Low</option> */}
                  </select>
                  <div className="invalid-feedback">Please select priority</div>
                </div>
                
                {/* </div> */}

                <div class="col-md-6 mb-2">
                  <label class="mb-2 text-grey">Attachment Preview</label>
                  <textarea
                    name=""
                    id=""
                    cols="25"
                    rows="8"
                    class="form-control tasKCategory1"
                    onChange={(e) => {
                      setAddAnnouncment({
                        ...addAnnouncment,
                        AttachmentPreview: e.target.value,
                      });
                    }}
                    //required
                    value={addAnnouncment.AttachmentPreview}
                  ></textarea>
                </div>
                <div class="col-md-6 pe-0 mb-2">
                  <label class="mb-2 text-grey">Attachment</label>
                  <input
                    type="file"
                    accept="image/png, image/gif, image/jpeg"
                    placeholder="Browse Files.."
                    class="form-control tasKCategory1"
                    onChange={(e) => {
                      setAddAnnouncment({
                        ...addAnnouncment,
                        AttachmentPath: e.target.files[0],
                      });
                    }}
                    //required
                    style={{
                      fontSize: "14px",
                      height: "38px",
                      border: "1px solid #ced4da",
                    }}
                  />
                  <div className="invalid-feedback">Please select file</div>
                </div>
                <div class="col-md-6 mb-2">
                  <label class="mb-2 text-grey">Message/Description</label>
                  <textarea
                    name=""
                    id=""
                    cols="30"
                    rows="8"
                    class="form-control tasKCategory1"
                    onChange={(e) => {
                      setAddAnnouncment({
                        ...addAnnouncment,
                        Message: e.target.value,
                      });
                    }}
                    value={addAnnouncment.Message}
                    required
                    style={{
                      fontSize: "14px",
                      height: "100px",
                      border: "1px solid #ced4da",
                    }}
                  ></textarea>
                  <div className="invalid-feedback">
                    Please Enter Message or Description
                  </div>
                </div>
              </div>
            </div>
          </Modal.Body>

          {/* <div className="lineSeperate"></div>
        <Modal.Footer className="create-footer">
          <div className="col-md-6 d-flex justify-content-end align-items-end">
            <div className="mainBtnsSub">
              <button className="btn-width cancelBtn mx-4" onClick={handleClose}>Cancel</button>
              <button className="btn-width saveBtn">
              Submit
              </button>
            </div>
          </div>
        </Modal.Footer> */}

          <div className="lineSeperate"></div>
          <div className="d-flex justify-content-end align-items-center mainBtnsSub">
            <button
              className="btn-width cancelBtn mx-3"
              onClick={() => {
                handleClose();
                setAddAnnouncment({
                  Title: "",
                  AnnouncementDate: "",
                  AssignToBranchId: 0,
                  Message: "",
                  PriorityId: "",
                  AttachmentPath: null,
                  AttachmentPreview: "",
                  OrgId: Number(userData.OrgId),
                  CreatedBy: Number(userData.EmpId),
                  ViewCount: 0,
                  AssignToTeamId: 0,
                  AssignToEmployeeId: 0,
                });
                setValidated(false);
              }}
              type="button"
            >
              Cancel
            </button>
            <button
              disabled={btnLoading ? true : false}
              className="btn-width saveBtn"
            >
              {btnLoading ? (
                <div
                  class="spinner-border text-light"
                  role="status"
                  style={{
                    marginTop: "5px",
                    marginLeft: "45px",
                    width: "1.3rem",
                    height: "1.3rem",
                  }}
                ></div>
              ) : (
                "Add"
              )}
            </button>
          </div>
        </Form>
      </Modal>
      {/* Create News and Blogs Modal End*/}

      {/* Create News and Blogs Modal End*/}
      <Modal
        size="l"
        className="Member-Modal"
        show={show5}
        onHide={handleClose5}
        dialogClassName="modal-90w"
        centered
      >
        <Modal.Header className="views-header pe-4 ps-4">
          {/* <Modal.Title className="PriveListPre"> */}
          <div className="birderbotton d-flex justify-content-between w-100">
            <div>Views </div>
            <div className="covercount">{viewCountList.length}+</div>
          </div>
          {/* </Modal.Title> */}
        </Modal.Header>
        <div className="lineSeperate"></div>
        <Modal.Body className="ListModel">
          {mloading ? (
            <div class="d-flex justify-content-center mt-5">
              <div class="spinner-border text-primary" role="status"></div>
            </div>
          ) : (
            <div className="totalslistwrap p-2">
              {viewCountList && viewCountList.length > 0 ? (
                viewCountList.map((count, index) => {
                  return (
                    <div className="listMain" key={"caufods" + index}>
                      <div className="img d-flex">
                        <img
                          className="img_proflie"
                          src={
                            !count.photoPath
                              ? avatar
                              : count.photoPath
                              ? ApiConfig.BASE_URL_FOR_IMAGES + count.photoPath
                              : count.photoPath &&
                                URL.createObjectURL(count.photoPath)
                          }
                          alt=""
                        />
                        <div className="name">{count.name} </div>
                      </div>
                      <div className="destion"> {count.DesignationName}</div>
                    </div>
                  );
                })
              ) : (
                <div
                  style={{
                    // marginTop: "50%",
                    textAlign: "center",
                    color: "red",
                  }}
                >
                  Data Not Found
                </div>
              )}
            </div>
          )}
        </Modal.Body>
        {/* <Modal.Footer className="delete-footer"> */}
        {/* <Button
            variant="secondary"
            className="cancel-btn"
            onClick={handleClose4}
          >
            Close
          </Button>
          <Button variant="primary" className="sbmt-btn" onClick={handleClose4}>
            Delete
          </Button> */}
        {/* </Modal.Footer> */}
      </Modal>

      <Modal
        size="xl"
        className=""
        show={show4}
        onHide={handleClose4}
        dialogClassName="modal-90w"
        centered
      >
        <Modal.Header className="announcement-header" closeButton>
          <Modal.Title className="announcement-title">Announcement</Modal.Title>
        </Modal.Header>
        <div class="lineSeperate"></div>
        <Modal.Body className="delete-body ps-4">
          {mloading ? (
            <div style={{ margin: "15%" }}>
              <BootstrapSpinner />
            </div>
          ) : announcment && announcment.length > 0 ? (
            announcment.map((anou, index) => {
              return (
                <div className="Announcement">
                  <div className="top">
                    <h3>{anou.Title}</h3>
                    <p className="text-grey">
                      Posted on {anou.Date.slice(0, 9)}{" "}
                      <b className="right-divider">{anou.CreatedBy}</b>{" "}
                      <span>{anou.ViewCount}+ Views</span>
                    </p>
                  </div>

                  <img
                    alt=""
                    //onClick={handleShow6}
                    className="AnnouncImg"
                    src={
                      !anou.ImagePath
                        ? g20
                        : anou.ImagePath
                        ? ApiConfig.BASE_URL_FOR_IMAGES + anou.ImagePath
                        : anou.ImagePath && URL.createObjectURL(anou.ImagePath)
                    }
                  ></img>

                  <div className="discribtion">
                    <p className="qution">{anou.Description}</p>
                  </div>
                </div>
              );
            })
          ) : (
            <tr className="ErrorDataNotFound">
              <td colSpan={6}>Data Not Found</td>
            </tr>
          )}
        </Modal.Body>
        <Modal.Footer className="delete-footer">
          {/* <Button
            variant="secondary"
            className="cancel-btn"
            onClick={handleClose4}
          >
            Close
          </Button>
          <Button variant="primary" className="sbmt-btn" onClick={handleClose3}>
            Delete
          </Button> */}
        </Modal.Footer>
      </Modal>

      <Modal
        size="xxl"
        className="carsousel-img-model"
        show={show6}
        onHide={handleClose6}
        dialogClassName="modal-90w"
        centered
      >
        {/* <Modal.Header>
          <div className="d-flex justify-content-between w-100">
            <div>Views @</div>
            <div>99+</div>
          </div>

        </Modal.Header> */}
        <Modal.Body className=" imagecarosla p-0">
          <div className=" p-2">
            <Carousel>
              <Carousel.Item>
                <img
                  className="d-block w-100"
                  src="holder.js/800x400?text=First slide&bg=373940"
                  alt="First slide"
                />
                <Carousel.Caption>
                  <h3>First slide label</h3>
                  <p>
                    Nulla vitae elit libero, a pharetra augue mollis interdum.
                  </p>
                </Carousel.Caption>
              </Carousel.Item>
              <Carousel.Item>
                <img
                  className="d-block w-100"
                  src="holder.js/800x400?text=Second slide&bg=282c34"
                  alt="Second slide"
                />

                <Carousel.Caption>
                  <h3>Second slide label</h3>
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                  </p>
                </Carousel.Caption>
              </Carousel.Item>
              <Carousel.Item>
                <img
                  className="d-block w-100"
                  src="holder.js/800x400?text=Third slide&bg=20232a"
                  alt="Third slide"
                />

                <Carousel.Caption>
                  <h3>Third slide label</h3>
                  <p>
                    Praesent commodo cursus magna, vel scelerisque nisl
                    consectetur.
                  </p>
                </Carousel.Caption>
              </Carousel.Item>
            </Carousel>
          </div>
        </Modal.Body>
        {/* <Modal.Footer className="delete-footer">
          <Button
            variant="secondary"
            className="cancel-btn"
            onClick={handleClose6}
          >
            Close
          </Button>
          <Button variant="primary" className="sbmt-btn" onClick={handleClose4}>
            Delete
          </Button>
        </Modal.Footer> */}
      </Modal>
    </>
  );
};

export default AllAnnouncement;
