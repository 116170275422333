import React, { useState, useContext, useEffect } from "react";
import { AppContext } from "../../context/AppContext";
import searchicon from "../../assets/images/ic_search.svg";
import ic_search_dark from "../../assets/images/ic_search_dark.svg";
import Pagination from "../../sharedComponent/Pagination";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import EditacBlc from "../../assets/images/EditacBlc.svg";
import disabled_edit from "../../assets/images/disabled_edit.svg";
import { Link, useSearchParams } from "react-router-dom";
import Loader from "../Loader";
import Axe from "../../assets/images/sidebar/icons/axeMark.svg";
import ApiConfig from "../../api/ApiConfig";
import {
  PostCallWithErrorResponse,
  simpleGetCallWithToken,
} from "../../api/ApiServices";
import { getDDateyyyymmdd, getDateddmmyyyy } from "../../common";
import NoData from "../../sharedComponent/NoData";
import moment from "moment";
import { ToastMsg } from "../TostMsg";
import { toast } from "react-toastify";
import ic_excel from "../../assets/images/ic_excel.svg";
import FileSaver from "file-saver";
import ExcelLoader from "../../sharedComponent/ExcelLoader";

const Task = () => {
  const { sidebar, setSidebar, Dark, setDark, userData, permissions } =
    useContext(AppContext);

  const [projectList, setProjectList] = useState([]);
  // const [validated, setValidated] = useState(false);
  const [adminList, setAdminList] = useState([]);
  const [standardNamelist, setStandardNamelist] = useState([]);
  const [projectDataId, setProjectDataId] = useState({});
  const [adminPostData, setAdminPostData] = useState({
    ProjectId: 0,
    StandardTaskId: 0,
    CreatedBy: 0,
    TaskName: "",
    StartDate: "",
    EndDate: "",
    budgethours: 0,
    PriorityId: 0,
    OrgId: 0,
    IsStandardTask: true,
    TaskDescription: "",
  });
  console.log("adminPostData,", adminPostData);
  const [selBudgetHours, setSelBudgetHours] = useState("");

  const [show3, setShow3] = useState(false);
  const handleClose3 = () => setShow3(false);
  const handleShow3 = () => setShow3(true);

  const [excelLoading, setExcelLoading] = useState(false);

  const changeData = (e) => {
    const { name, value } = e.target;
    setAdminPostData({
      ...adminPostData,
      [name]: value,
    });
  };

  const [loading, setLoading] = useState(false);
  const [differenceDate, setDifferenceDate] = useState(0);
  const [show, setShow] = useState(false);
  const [show2, setShow2] = useState(false);
  const [validated, setValidated] = useState(false);
  const [taskId, setTaskId] = useState("");
  const handleClose = () => {
    setShow(false);
    setAdminPostData({
      ProjectId: 0,
      StandardTaskId: 0,
      CreatedBy: 0,
      TaskName: "",
      StartDate: "",
      EndDate: "",
      budgethours: 0,
      PriorityId: 0,
      OrgId: 0,
      IsStandardTask: true,
      TaskDescription: "",
    });
  };
  const handleClose2 = () => {
    setShow2(false);
    setAdminPostData({
      ProjectId: 0,
      StandardTaskId: 0,
      CreatedBy: 0,
      TaskName: "",
      StartDate: "",
      EndDate: "",
      budgethours: 0,
      PriorityId: 0,
      OrgId: 0,
      IsStandardTask: true,
      TaskDescription: "",
    });
  };
  let handleShow = () => setShow(true);
  let handleShow2 = () => setShow2(true);
  // handleClose1()
  const empId = localStorage.getItem("EmpId");
  const orgId = localStorage.getItem("OrgId");

  const [sortOrder, setSortOrder] = useState("asc");
  const handleSort = () => {
    const sortedData = [...adminList].sort((a, b) => {
      if (a.status < b.status) return sortOrder === "asc" ? -1 : 1;
      if (a.status > b.status) return sortOrder === "asc" ? 1 : -1;
      return 0;
    });

    setAdminList(sortedData);
    setSortOrder(sortOrder === "asc" ? "desc" : "asc");
  };

  const [searchValue, setSearchValue] = useState("");
  const [currentPage, setCurrentPage] = useState(0);
  const [usersPerPage, setUsersPerPage] = useState(25);

  const startIndex = currentPage * usersPerPage;
  const endIndex = startIndex + parseInt(usersPerPage);

  const pageChangeHandler = ({ selected }) => {
    setCurrentPage(selected);
  };

  const searchedDataPageCounts =
    adminList &&
    adminList.length &&
    adminList.filter((item) =>
      item.ProjectName?.toLowerCase().includes(searchValue)
    );

  const getProjectNameApi = () => {
    let payloadData = {
      ProjAdminId: 0,
      OrgId: 1,
    };
    PostCallWithErrorResponse(
      ApiConfig.GET_TASK_PROJECTLIST,
      JSON.stringify(payloadData)
    ).then((res) => {
      let data = res.json.Data;
      setProjectList(data);
    });
  };

  const getProjectList = () => {
    setLoading(true);
    simpleGetCallWithToken(ApiConfig.GET_PROJECT_LIST_ADMIN).then((res) => {
      let data = res.Data;
      if (res.Success === true) {
        setAdminList(data);
        setLoading(false);
      }
    });
  };

  const addTaskByAdmin = (event) => {
    event.preventDefault();
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
      setValidated(true);
    } else {
      const finalHour =
        new Date(adminPostData.EndDate) - new Date(adminPostData.StartDate);
      const diffHours = finalHour / (1000 * 60 * 60);
      setAdminPostData({
        ...adminPostData,
        budgethours: Number(adminPostData.budgethours),
      });
      console.log(diffHours);
      // let payLoadData = {
      //   ProjectId: JSON.parse(adminPostData.ProjectId),
      //   StandardTaskId: JSON.parse(adminPostData.StandardTaskId),
      //   StartDate: adminPostData.StartDate,
      //   EndDate: adminPostData.EndDate,
      //   budgethours:JSON.parse(adminPostData.budgethours),
      //   PriorityId: JSON.parse(adminPostData.PriorityId),
      // };
      // console.log("DATE TEST", payLoadData);
      // return;
      PostCallWithErrorResponse(
        ApiConfig.POST_TASK_BY_ADMIN,
        JSON.stringify(adminPostData)
      ).then((res) => {
        let data = res.json.Message;
        if (res.json.Success === true) {
          setShow2(false);
          ToastMsg("success", res.json.Message);
          getProjectList();
          handleClose2();
          handleClose();
          setAdminPostData({
            ProjectId: 0,
            StandardTaskId: 0,
            CreatedBy: 0,
            TaskName: "",
            StartDate: "",
            EndDate: "",
            budgethours: 0,
            PriorityId: 0,
            OrgId: 0,
            IsStandardTask: true,
            TaskDescription: "",
          });
        } else {
          setShow2(false);
          ToastMsg("error", res.json.Message);
          getProjectList();
          handleClose2();
          handleClose();
          setAdminPostData({
            ProjectId: 0,
            StandardTaskId: 0,
            CreatedBy: 0,
            TaskName: "",
            StartDate: "",
            EndDate: "",
            budgethours: 0,
            PriorityId: 0,
            OrgId: 0,
            IsStandardTask: true,
            TaskDescription: "",
          });
        }
      });
    }
  };

  const downloadExcelFile = (e) => {
    setExcelLoading(true);
    e.preventDefault();
    PostCallWithErrorResponse(ApiConfig.GET_TASK_DOWNLOAD_EXCEL)
      .then((res) => {
        console.log("Download File Response", res);
        if (res.error) {
          console.log("Error response", res);
        } else {
          if (res.json.Success === true) {
            setExcelLoading(false);
            let data = res.json.Data;
            FileSaver.saveAs(
              "data:" + data.ContentType + ";base64," + data.FileContents,
              data.FileDownloadName
            );
          } else {
            console.log("res", res);
            setExcelLoading(false);
          }
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const updateTaskByAdmin = () => {
    const finalHour =
      new Date(adminPostData.EndDate) - new Date(adminPostData.StartDate);
    const diffHours = finalHour / (1000 * 60 * 60);
    // let payloadData = {
    //   TaskId: JSON?.parse(taskId),
    //   ProjectId: JSON?.parse(adminPostData?.ProjectId),
    //   StandardTaskId: JSON?.parse(adminPostData?.StandardTaskId),
    //   TaskName: adminPostData?.TaskName,
    //   StartDate: adminPostData?.StartDate,
    //   EndDate: adminPostData?.EndDate,
    //   budgethours: Number(JSON.parse(adminPostData?.budgethours)),
    //   PriorityId: JSON.parse(adminPostData?.PriorityId),
    //   IsStandardTask: adminPostData?.IsStandardTask,
    //   TaskDescription: adminPostData?.TaskDescription,
    // };
    let payloadData = {
      TaskId: taskId,
      ProjectId: adminPostData?.ProjectId,
      StandardTaskId: Number(adminPostData?.StandardTaskId),
      TaskName: adminPostData?.TaskName,
      StartDate: adminPostData?.StartDate,
      EndDate: adminPostData?.EndDate,
      budgethours: Number(adminPostData?.budgethours),
      PriorityId: adminPostData?.PriorityId,
      IsStandardTask: adminPostData?.IsStandardTask === "true",
      TaskDescription: adminPostData?.TaskDescription,
    };
    PostCallWithErrorResponse(
      ApiConfig.UPDATE_TASK_BY_ADMIN,
      JSON.stringify(payloadData)
    ).then((res) => {
      let data = res.json.Message;
      if (res.json.Success === true) {
        ToastMsg("success", res.json.Message);
        getProjectList();
        setShow2(false);
      } else {
        ToastMsg("error", res.json.Message);
        getProjectList();
        setShow2(false);
      }
    });
  };

  const getTaskNameCeo = (taskId) => {
    let localData = {
      TaskId: taskId,
    };
    setTaskId(taskId);
    PostCallWithErrorResponse(
      ApiConfig.EDIT_TASK_BY_ID,
      JSON.stringify(localData)
    ).then((res) => {
      let data = res.json.Data;
      let empId = localStorage.getItem("EmpId");
      setAdminPostData({
        ProjectId: data?.ProjectId ? data?.ProjectId : "",
        StandardTaskId: data?.StandardTaskId
          ? JSON.parse(data?.StandardTaskId)
          : "",
        CreatedBy: empId,
        TaskName: data?.TaskName ? data?.TaskName : "",
        StartDate: data?.TaskStartDate ? data?.TaskStartDate : "",
        EndDate: data?.TaskEndDate ? data?.TaskEndDate : "",
        budgethours: data?.BudgetedHours ? Number(data?.BudgetedHours) : null,
        PriorityId: data?.PriorityId ? data?.PriorityId : "",
        OrgId: 0,
        IsStandardTask: true,
        TaskDescription: data?.TaskDescription ? data?.TaskDescription : "",
      });
    });
  };
  const getTaskName = () => {
    simpleGetCallWithToken(ApiConfig.GET_TASK_NAME_MODAL)
      .then((res) => {
        let data = res.Data;

        setStandardNamelist(data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getProjectList();
    getProjectNameApi();
    getTaskName();
  }, []);

  const displayAdminTask = (
    <table>
      <thead>
        <tr className="firstTabelRow" id="top-tr">
          <th>Sr. No.</th>
          <th>Project Name</th>
          <th>Created By</th>
          <th>Created On</th>
          <th>Allocated To</th>
          <th>Task Name</th>
          <th>Activity</th>
          <th>Start Date</th>
          <th>End Date</th>
          <th onClick={handleSort} style={{ cursor: "pointer" }}>
            Status {sortOrder === "asc" ? "▲" : "▼"}
          </th>
          <th>Priority</th>
          {permissions &&
            permissions["Edit Task"] &&
            <th>Action</th>
          }
        </tr>
      </thead>
      <tbody>
        {adminList.slice(startIndex, endIndex).map((admin, index) => {
          return (
            <tr className="table-row-custom" key={index}>
              <td id="sr-no-padding">{startIndex + index + 1}</td>
              <td>{admin.ProjectName ? admin.ProjectName : "No Data"}</td>
              <td>{admin.CreatedbyName ? admin.CreatedbyName : "No Data"}</td>
              <td>{admin.CreatedDate ? admin.CreatedDate : "No Data"}</td>
              <td>{admin.AllocatedName ? admin.AllocatedName : "No Data"}</td>
              <td className="break-ln-spaces">
                {admin.TaskName ? admin.TaskName : ""}
              </td>
              <td className="break-ln-spaces">
                {admin.TaskDescription ? admin.TaskDescription : "No Data"}
              </td>
              <td>
                {admin?.StartDate &&
                  getDateddmmyyyy(admin?.StartDate) &&
                  getDateddmmyyyy(admin?.StartDate)}
              </td>
              <td>
                {admin?.EndDate &&
                  getDateddmmyyyy(admin?.EndDate) &&
                  getDateddmmyyyy(admin?.EndDate)}
              </td>
              {/* <td>{moment(admin.StartDate).format("L")}</td>*/}
              {/* <td>{moment(admin.EndDate).format("L")}</td>*/}
              <td>
                {admin.status === "Pending" ? (
                  <span className="colorP">{admin.status}</span>
                ) : admin.status === "Allocated" ? (
                  <span className="colorAl">{admin.status}</span>
                ) : admin.status === "Completed" ? (
                  <span className="colorC">{admin.status}</span>
                ) : admin.status === "Deallocated" ? (
                  <span className="colorDe">{admin.status}</span>
                ) : admin.status === "Abort" ? (
                  <span className="colorAb">{admin.status}</span>
                ) : admin.status === "Inprogress" ? (
                  <span className="colorIn">{admin.status}</span>
                ) : admin.status === "Rejected" ? (
                  <span className="colorR">{admin.status}</span>
                ) : admin.status === "Reallocated" ? (
                  <span className="colorRe">{admin.status}</span>
                ) : admin.status === "Hold" ? (
                  <span className="colorH">{admin.status}</span>
                ) : admin.status === "Approved" ? (
                  <span className="colorA"></span>
                ) : (
                  ""
                )}
              </td>
              <td className="textSuccess">{admin.Priority}</td>
              {permissions &&
                permissions["Edit Task"] &&
                <td
                  onClick={() => {
                    if (admin.status === "Pending") {
                      setShow2(true);
                      getTaskNameCeo(admin.TaskId);
                    }
                  }}
                >
                  {/* {item.status === "P" ? (
                <img src={EditacBlc} className="mx-2 editBtn" alt="" />
                ):(
                  <img src={disabled_edit} className="mx-2 editBtn" alt="" />
                )} */}
                  {permissions &&
                    permissions["Edit Task"] &&
                    admin.status === "Pending" ? (
                    <img src={EditacBlc} alt="" className="mx-2 pointer" />
                  ) : (
                    <img src={disabled_edit} className="mx-2 pointer" />
                  )}
                </td>
              }
            </tr>
          );
        })}
      </tbody>
    </table>
  );

  const displaySearchAdminTask = (
    <table>
      <thead>
        <tr className="firstTabelRow">
          <th>Sr. No.</th>
          <th>Project Name</th>
          <th>Created By</th>
          <th>Created On</th>
          <th>Allocated To</th>
          <th>Task Name</th>
          <th>Activity</th>
          <th>Start Date</th>
          <th>End Date</th>
          <th>Status</th>
          <th>Priority</th>
          {permissions &&
            permissions["Edit Task"] &&
            <th>Action</th>
          }
        </tr>
      </thead>
      <tbody>
        {adminList
          .filter((item) =>
            item.ProjectName.toLowerCase().includes(searchValue.toLowerCase())
          )
          .slice(startIndex, endIndex)
          .map((admin, index) => {
            return (
              <tr className="table-row-custom" key={index}>
                <td id="sr-no-padding">{startIndex + index + 1}</td>
                <td>{admin.ProjectName ? admin.ProjectName : "No Data"}</td>
                <td>{admin.CreatedbyName ? admin.CreatedbyName : "No Data"}</td>
                <td>{admin.CreatedDate ? admin.CreatedDate : "No Data"}</td>
                <td>{admin.AllocatedName ? admin.AllocatedName : "No Data"}</td>
                <td className="break-ln-spaces">
                  {admin.TaskName ? admin.TaskName : "No Data"}
                </td>
                <td className="break-ln-spaces">
                  {admin.TaskDescription ? admin.TaskDescription : "No Data"}
                </td>
                <td>
                  {admin?.StartDate &&
                    getDateddmmyyyy(admin?.StartDate) &&
                    getDateddmmyyyy(admin?.StartDate)}
                </td>
                <td>
                  {admin?.EndDate &&
                    getDateddmmyyyy(admin?.EndDate) &&
                    getDateddmmyyyy(admin?.EndDate)}
                </td>
                {/* <td>{moment(admin.StartDate).format("L")}</td>*/}
                {/* <td>{moment(admin.EndDate).format("L")}</td>*/}
                <td>
                  {admin.status === "Pending" ? (
                    <span className="colorP">{admin.status}</span>
                  ) : admin.status === "Allocated" ? (
                    <span className="colorA">{admin.status}</span>
                  ) : admin.status === "Completed" ? (
                    <span className="colorC">{admin.status}</span>
                  ) : admin.status === "Deallocated" ? (
                    <span className="colorDe">{admin.status}</span>
                  ) : admin.status === "Abort" ? (
                    <span className="colorAb">{admin.status}</span>
                  ) : admin.status === "Inprogress" ? (
                    <span className="colorIn">{admin.status}</span>
                  ) : admin.status === "Rejected" ? (
                    <span className="colorR">{admin.status}</span>
                  ) : admin.status === "Reallocated" ? (
                    <span className="colorRe">{admin.status}</span>
                  ) : admin.status === "Hold" ? (
                    <span className="colorH">{admin.status}</span>
                  ) : admin.status === "Approved" ? (
                    <span className="colorA"></span>
                  ) : (
                    ""
                  )}
                </td>
                <td className="textSuccess">{admin.Priority}</td>
                {permissions &&
                  permissions["Edit Task"] &&
                  <td
                    onClick={() => {
                      if (admin.status === "Pending") {
                        setShow2(true);
                        getTaskNameCeo(admin.TaskId);
                      }
                    }}
                  >
                    {permissions &&
                      permissions["Edit Task"] &&
                      admin.status === "Pending" ? (
                      <img src={EditacBlc} alt="" className="mx-2 pointer" />
                    ) : (
                      <img src={disabled_edit} className="mx-2 pointer" />
                    )}
                    {/* {item.status === "P" ? ( */}
                    {/* <img src={EditacBlc} className="mx-2 editBtn" alt="" /> */}
                    {/* ):(
                  <img src={disabled_edit} className="mx-2 editBtn" alt="" />
                )} */}
                  </td>
                }
              </tr>
            );
          })}
      </tbody>
      {/* {searchValue === "" ? displayTaskList : displaySearchedTaskList} */}
    </table>
  );

  return (
    <>
      <div
        className={sidebar ? "taskMain " : "cx-active taskMain"}
        id="cx-main"
      >
        <div className="role-content">
          <div className="sub-header-role ">
            <h6 className="mt-2">Task</h6>
            {permissions && permissions["Create Task"] && (
              <button
                className="create-btn-sub-header"
                variant="primary"
                onClick={() => {
                  handleShow();
                  // getTaskName();
                  //     setAddTaskPayload({
                  //         ProjectId: 0,
                  //         TaskName: "",
                  //         StartDate: "",
                  //         EndDate: "",
                  //         BudgetedHours: 0,
                  //         Priority: "",
                  //         EmpId: Number(userData.EmpId),
                  //     });

                  setValidated(false);
                }}
              >
                + Add Task{" "}
              </button>
            )}
          </div>
          <div className="d-flex justify-content-between role-searchbar-and-btns RspSalary">
            <div className="role-table-header">
              <div className="search-input-wrapper">
                <input
                  type="text"
                  placeholder="Search project"
                  onChange={(e) => {
                    setSearchValue(e.target.value);
                    setCurrentPage(0);
                  }}
                />
                {Dark === "lightMode" ? (
                  <img src={searchicon} alt="" />
                ) : (
                  <img src={ic_search_dark} alt="" />
                )}
              </div>
              <div className="entries-wrapper">
                <p>Entries per page</p>
                <select
                  className="form-select select-drop-icon"
                  aria-label="Default select example"
                  onChange={(e) => {
                    setUsersPerPage(e.target.value);
                    setCurrentPage(0);
                  }}
                >
                  <option value="5">5</option>
                  <option value="10">10</option>
                  <option value="15">15</option>
                  <option value="20">20</option>
                  <option value="25" selected>
                    25
                  </option>
                </select>
              </div>
            </div>
            {permissions && permissions["Task Export"] && (
              <div className="import-export-btn-wrapper">
                <button className="booksIcon">
                  {excelLoading ? (
                    <ExcelLoader />
                  ) : (
                    <img
                      src={ic_excel}
                      onClick={(e) => downloadExcelFile(e)}
                      alt=""
                    />
                  )}
                </button>
              </div>
            )}
          </div>
          <div className="table-wrapper-main" id="table-wrapper-af">
            {loading ? (
              <div>
                <Loader />
              </div>
            ) : (
              <>
                {adminList && adminList.length > 0 ? (
                  <>
                    {searchValue === ""
                      ? displayAdminTask
                      : displaySearchAdminTask}
                  </>
                ) : (
                  <>
                    <NoData />
                  </>
                )}
              </>
            )}
          </div>
        </div>
        {/* {!loading && searchedDataPageCounts.length > usersPerPage && ( */}
        <Pagination
          data={adminList}
          pageChangeHandler={pageChangeHandler}
          usersPerPage={usersPerPage}
          currentPage={currentPage}
          searchValue={searchValue}
          searchedDataPageCounts={searchedDataPageCounts}
        />
        {/* )} */}
      </div>

      {/* Add Modal Task */}

      <Modal
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={show}
        onHide={handleClose}
      >
        <Modal.Header>
          <Modal.Title>
            <label htmlFor="" className="create-role-text">
              Add Task
            </label>
          </Modal.Title>
          <div className="rightAxe">
            <img src={Axe} alt="" onClick={() => setShow(false)} />
          </div>
        </Modal.Header>
        <div className="hr-line-modal">
          <hr style={{ margin: "0" }} />
        </div>
        <Form noValidate validated={validated} onSubmit={addTaskByAdmin}>
          <Modal.Body id="popup-form-af">
            <div className="mb-4">
              <label htmlFor="" className="enter-role-text">
                Project Name
              </label>
              <select
                onChange={(e) => {
                  const { value } = e.target;
                  setAdminPostData({
                    ...adminPostData,
                    ProjectId: Number(value),
                  });
                }}
                name="ProjectId"
                value={adminPostData.ProjectId}
                class="form-select tasKCategory1 select-drop-icon"
                aria-label="Default select example"
                required
              >
                <option value="">Select Project</option>
                {projectList.map((name, index) => {
                  return (
                    <option value={name.ProjectId} key={index}>
                      {name.ProjectNames}
                    </option>
                  );
                })}
              </select>
              <div className="invalid-feedback">Please select project name</div>
            </div>
            <div className=" mb-4">
              <label htmlFor="" className="enter-role-text mt-2 ">
                Standard Task
              </label>
              <select
                disabled={adminPostData.TaskName ? true : false}
                class="form-select tasKCategory1 select-drop-icon"
                aria-label="Default select example "
                onChange={(e) => {
                  const { value } = e.target;
                  setAdminPostData({
                    ...adminPostData,
                    StandardTaskId: Number(value),
                    IsStandardTask: true,
                  });
                }}
                name="StandardTaskId"
                value={adminPostData?.standardTaskId}
                required
              >
                <option value={""}>Select Task Name</option>
                {standardNamelist?.map((name, index) => {
                  return (
                    <option value={name.standardTaskId}>
                      {name.StandardTaskName}
                    </option>
                  );
                })}
              </select>
              <div className="invalid-feedback">Please select task name</div>
            </div>

            <div className="modalProp mb-4">
              <label
                htmlFor=""
                className="enter-role-text mt-2"
                style={{ fontSize: "12.8px" }}
              >
                Task Name
              </label>
              <input
                disabled={adminPostData.StandardTaskId ? true : false}
                onChange={(e) => {
                  changeData(e);
                  const { value } = e.target;
                  setAdminPostData({
                    ...adminPostData,
                    TaskName: value,
                    IsStandardTask: false,
                  });
                }}
                name="TaskName"
                value={adminPostData.TaskName}
                type="text"
                className="date-label-input tasKCategory1"
                style={{
                  fontSize: "14px",
                  height: "38px",
                  border: "1px solid #ced4da",
                }}
                maxlength={65}
                required
              />
              <div className="invalid-feedback">Please add new task</div>
            </div>
            <div className="modalProp mb-4">
              <label
                htmlFor=""
                className="enter-role-text mt-2"
                style={{ fontSize: "12.8px" }}
              >
                Activity
              </label>
              <textarea
                type="text"
                //disabled={adminPostData.StandardTaskId ? true : false}
                onChange={(e) => {
                  changeData(e);
                  const { value } = e.target;
                  setAdminPostData({
                    ...adminPostData,
                    TaskDescription: value,
                    IsStandardTask: false,
                  });
                }}
                name="TaskDescription"
                value={adminPostData.TaskDescription}
                className="date-label-input tasKCategory1"
                style={{
                  border: "1px solid #53b7e8",
                  height: "auto",
                }}
                rows="4"
                placeholder="Enter Activity..."
                required
              />
              <div className="invalid-feedback">Please enter Activity</div>
            </div>
            <div className="row add-task-modal-inputs">
              <div className="col-6 modalProp mb-3">
                <label
                  htmlFor=""
                  className="enter-role-text mt-0"
                  style={{ fontSize: "12.8px" }}
                >
                  Start Date
                </label>
                <input
                  onChange={(e) => {
                    changeData(e);
                  }}
                  name="StartDate"
                  value={adminPostData.StartDate}
                  type="date"
                  className="date-label-input tasKCategory1"
                  style={{
                    fontSize: "14px",
                    height: "38px",
                    border: "1px solid #ced4da",
                  }}
                  required
                />
                <div className="invalid-feedback">Please select Start date</div>
              </div>
              <div className="col-6 modalProp mb-3">
                <label
                  htmlFor=""
                  className="enter-role-text mt-0"
                  style={{ fontSize: "12.8px" }}
                >
                  End Date
                </label>
                <input
                  onChange={(e) => {
                    changeData(e);
                  }}
                  name="EndDate"
                  value={adminPostData.EndDate}
                  disabled={!adminPostData.StartDate}
                  min={adminPostData.StartDate.split("T")[0]}
                  type="date"
                  className="date-label-input tasKCategory1"
                  style={{
                    fontSize: "14px",
                    height: "38px",
                    border: "1px solid #ced4da",
                  }}
                  required
                />
                <div className="invalid-feedback">Please select end date</div>
              </div>
            </div>
            <div className="row add-task-modal-inputs">
              <div className="col-6 modalProp mb-3">
                <label
                  htmlFor=""
                  className="enter-role-text mt-0"
                  style={{ fontSize: "12.8px" }}
                >
                  Budget Hours
                </label>
                <input
                  onChange={(e) => {
                    const { value } = e.target;

                    // If the input is empty, set the state to an empty string
                    setAdminPostData({
                      ...adminPostData,
                      budgethours: value === "" ? "" : Number(value),
                    });
                  }}
                  name="budgethours"
                  value={adminPostData.budgethours}
                  type="number"
                  className="date-label-input tasKCategory1"
                  style={{
                    fontSize: "14px",
                    height: "38px",
                    border: "1px solid #ced4da",
                  }}
                  required
                />

                <div className="invalid-feedback">
                  Please select budget hours
                </div>
              </div>
              <div className="col-6 modalProp mb-3">
                <label htmlFor="onPriority" className="enter-role-text mt-0">
                  {" "}
                  On Priority
                </label>
                <select
                  class="form-select tasKCategory1 select-drop-icon"
                  aria-label="Default select example"
                  onChange={(e) => {
                    const { value } = e.target;
                    setAdminPostData({
                      ...adminPostData,
                      PriorityId: Number(value),
                    });
                  }}
                  name="PriorityId"
                  value={adminPostData.PriorityId}
                  required
                  style={{ height: "38px" }}
                >
                  <option value="">On Priority</option>
                  <option value="1">High</option>
                  <option value="2">Medium</option>
                  <option value="3">Low</option>
                </select>
                <div className="invalid-feedback">Please select priority</div>
              </div>
            </div>
          </Modal.Body>

          <div className="hr-line-modal-1">
            <hr style={{ margin: "0" }} />
          </div>
          <Modal.Footer className="model-footer-arbaz-responsive">
            <div>
              <button
                type="button"
                className="modal-cancel-btn branchList-modal-cancel-btn-arbaz-responsive"
                onClick={handleClose}
              >
                Cancel
              </button>
              <button
                type="submit"
                className="modal-create-btn branchList-modal-cancel-btn-arbaz-responsive"
              // onClick={() => {
              //   setStandardNamelist([])
              // }}
              >
                Add
              </button>
            </div>
          </Modal.Footer>
        </Form>
      </Modal>

      {/* Edit Modal Task */}
      <Modal
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={show2}
        onHide={handleClose2}
      >
        <Modal.Header>
          <Modal.Title>
            <label htmlFor="" className="create-role-text">
              Edit Task
            </label>
          </Modal.Title>
          <div className="rightAxe">
            <img src={Axe} alt="" onClick={() => setShow2(false)} />
          </div>
          {/* <img src={close} alt="" onClick={handleClose} className="cr-class" /> */}
        </Modal.Header>
        <div className="hr-line-modal">
          <hr style={{ margin: "0" }} />
        </div>
        {/* For Edit button  */}
        {/* <Form noValidate validated={validated} onSubmit={addTaskByAdmin}> updateTaskByAdmin */}
        <Form noValidate validated={validated} onSubmit={updateTaskByAdmin}>
          <Modal.Body id="popup-form-af">
            <div className=" mb-4">
              <label htmlFor="" className="enter-role-text ">
                Project Name
              </label>
              <select
                class="form-select tasKCategory1 select-drop-icon"
                aria-label="Default select example"
                value={adminPostData?.ProjectId ? adminPostData?.ProjectId : ""}
                onChange={(e) => {
                  changeData(e);
                }}
                name="ProjectId"
                required
              >
                <option required value="">
                  Select Project
                </option>
                {projectList.map((name, index) => {
                  return (
                    <option value={name.ProjectId} key={index}>
                      <p style={{ background: "red", color: "red" }}>     {name.ProjectNames}</p>

                    </option>
                  );
                })}
              </select>
              <div className="invalid-feedback">Please select project</div>
            </div>
            <div className=" mb-4">
              <label htmlFor="" className="enter-role-text mt-2">
                Standard Task
              </label>
              <select
                disabled={adminPostData.TaskName ? true : false}
                class="form-select tasKCategory1 select-drop-icon"
                aria-label="Default select example"
                value={
                  adminPostData.StandardTaskId
                    ? adminPostData.StandardTaskId
                    : ""
                }
                onChange={(e) => {
                  const { value } = e.target;
                  setAdminPostData({
                    ...adminPostData,
                    StandardTaskId: Number(value),
                    IsStandardTask: true,
                  });
                }}
                name="StandardTaskId"
                required
              >
                <option value="" selected>
                  Select Task Name{" "}
                </option>
                {standardNamelist?.map((name, index) => {
                  return (
                    <option value={name.standardTaskId} key={index}>
                      {name.StandardTaskName}
                    </option>
                  );
                })}
              </select>
              <div className="invalid-feedback">Please select task name</div>
            </div>

            <div className="modalProp mb-4">
              <label
                htmlFor=""
                className="enter-role-text mt-2"
                style={{ fontSize: "12.8px" }}
              >
                Task Name
              </label>
              <input
                disabled={adminPostData.StandardTaskId ? true : false}
                onChange={(e) => {
                  changeData(e);
                  const { value } = e.target;
                  setAdminPostData({
                    ...adminPostData,
                    TaskName: value,
                    IsStandardTask: false,
                  });
                }}
                name="TaskName"
                value={adminPostData.TaskName}
                type="text"
                className="date-label-input tasKCategory1"
                style={{
                  fontSize: "14px",
                  height: "38px",
                  border: "1px solid #ced4da",
                }}
                maxlength={65}
                required
              />
              <div className="invalid-feedback">Please add new task</div>
            </div>
            <div className="modalProp mb-4">
              <label
                htmlFor=""
                className="enter-role-text mt-2"
                style={{ fontSize: "12.8px" }}
              >
                Activity
              </label>
              <textarea
                type="text"
                //disabled={adminPostData.StandardTaskId ? true : false}
                onChange={(e) => {
                  changeData(e);
                  const { value } = e.target;
                  setAdminPostData({
                    ...adminPostData,
                    TaskDescription: value,
                    IsStandardTask: false,
                  });
                }}
                name="TaskDescription"
                value={adminPostData.TaskDescription}
                className="date-label-input tasKCategory1"
                style={{
                  border: "1px solid #53b7e8",
                  height: "auto",
                }}
                rows="4"
                placeholder="Enter Activity..."
                required
              />
              <div className="invalid-feedback">Please enter Activity</div>
            </div>
            <div className="row add-task-modal-inputs">
              <div className="col-6 modalProp mb-3">
                <label
                  htmlFor=""
                  className="enter-role-text mt-0"
                  style={{ fontSize: "12.8px" }}
                >
                  Start Date
                </label>
                <input
                  type="date"
                  className="date-label-input tasKCategory1 "
                  style={{
                    fontSize: "14px",
                    height: "38px",
                    border: "1px solid #ced4da",
                  }}
                  // value={moment(adminPostData?.StartDate).format("l") || "No Data"}
                  value={
                    getDDateyyyymmdd(adminPostData?.StartDate)
                      ? getDDateyyyymmdd(adminPostData?.StartDate)
                      : ""
                  }
                  onChange={(e) => {
                    changeData(e);
                  }}
                  name="StartDate"
                  required
                />
                <div className="invalid-feedback">Please select start date</div>
              </div>
              <div className="col-6 modalProp mb-3">
                <label
                  htmlFor=""
                  className="enter-role-text mt-0"
                  style={{ fontSize: "12.8px" }}
                >
                  End Date
                </label>
                <input
                  type="date"
                  className="date-label-input tasKCategory1"
                  style={{
                    fontSize: "14px",
                    height: "38px",
                    border: "1px solid #ced4da",
                  }}
                  value={
                    getDDateyyyymmdd(adminPostData?.EndDate)
                      ? getDDateyyyymmdd(adminPostData?.EndDate)
                      : ""
                  }
                  onChange={(e) => {
                    changeData(e);
                  }}
                  name="EndDate"
                  required
                />
                <div className="invalid-feedback">Please select end date</div>
              </div>
            </div>
            <div className="row add-task-modal-inputs">
              <div className="col-6 modalProp mb-3">
                <label
                  htmlFor=""
                  className="enter-role-text mt-0"
                  style={{ fontSize: "12.8px" }}
                >
                  Budget Hours
                </label>
                <input
                  type="text"
                  className="date-label-input tasKCategory1"
                  style={{
                    fontSize: "14px",
                    height: "38px",
                    border: "1px solid #ced4da",
                  }}
                  value={adminPostData.budgethours}
                  onChange={(e) => {
                    changeData(e);
                  }}
                  name="budgethours"
                  required
                />
                <div className="invalid-feedback">
                  Please select budget hours
                </div>
              </div>
              <div className="col-6 modalProp mb-3">
                <label htmlFor="onPriority" className="enter-role-text mt-0">
                  On Priority
                </label>
                <select
                  className="form-select tasKCategory1 select-drop-icon"
                  style={{
                    fontSize: "14px",
                    height: "38px",
                    border: "1px solid #ced4da",
                  }}
                  aria-label="Default select example"
                  onChange={(e) => {
                    const { value } = e.target;
                    setAdminPostData({
                      ...adminPostData,
                      PriorityId: Number(value),
                    });
                  }}
                  name="PriorityId"
                  value={adminPostData?.PriorityId}
                  required
                >
                  <option selected>On Priority</option>
                  <option value="1">High</option>
                  <option value="2">Medium</option>
                  <option value="3">Low</option>
                </select>
                <div className="invalid-feedback">Please select priority</div>
              </div>
            </div>
          </Modal.Body>

          <div className="hr-line-modal-1">
            <hr style={{ margin: "0" }} />
          </div>
          <Modal.Footer className="model-footer-arbaz-responsive">
            <div>
              <button
                type="button"
                className="modal-cancel-btn branchList-modal-cancel-btn-arbaz-responsive"
                onClick={handleClose2}
              >
                Cancel
              </button>
              <button
                //type="submit"
                type="button"
                className="modal-create-btn branchList-modal-cancel-btn-arbaz-responsive"
                onClick={() => {
                  updateTaskByAdmin();
                }}
              >
                Update
              </button>
            </div>
          </Modal.Footer>
        </Form>
      </Modal>
    </>
  );
};

export default Task;
