
import { useEffect, useState } from "react";
// import Pagination from "../../Components/Pagination";
import NoDataFound from "../../../pages/ChatAdmin/NodataFound";
import { useContext } from "react";

import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { faPenToSquare, faTrash, faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useNavigate } from "react-router-dom";


import { ToastMsg } from "../../TostMsg";

import CommonButton from "../../../pages/ChatAdmin/button";
import Loader from "../loader";

import { AppContext } from "../../../context/AppContext";
import ApiConfig from "../../../api/ApiConfig";
import { deleteWithAuthCall,simpleGetCallWithToken } from "../../../api/ApiServices";

export default function ConversationFlow() {

    const [chats, setChats] = useState([]);


    const [conversationalFlowList, setConversationalFlowList] = useState([])

    const [isLoading, setIsLoading] = useState(false)
    const [search, setSearch] = useState('')

    const [selectedChat, setSelectedChat] = useState(undefined);
    const { isDrawerOpen, selectedDomainId, domainName, setBackTo, sidebar, setEditKeyWordDtls } = useContext(AppContext);

    const [totalPage, setTotalPage] = useState(0)

    const getList = () => {

        simpleGetCallWithToken(ApiConfig?.GET_MODULE_LIST + '?domain_id=' + selectedDomainId).then((res) => {

            if (res?.success) {

                setConversationalFlowList(res?.data)
                setIsLoading(false)

            } else {
                setIsLoading(false)
            }

        }).catch((err) => {


            setIsLoading(false)


        })

    }

    useEffect(() => {

        setIsLoading(true)

        getList()

    }, [selectedDomainId])


    const [currentPage, setCurrentPage] = useState(1)

    const handleChatClick = (selectedChat) => {
        setSelectedChat(selectedChat);

    };



    const deleteKeyword = (id) => {


        deleteWithAuthCall(ApiConfig?.DELETE_KEYWORD + id).then((res) => {

            if (res?.success) {

                ToastMsg('success', 'Deleted Successfully')

                setIsLoading(true)
                getList()

            } else {

                ToastMsg('error', 'Something went wrong please tre again')

            }

        })

    }
    const navigate = useNavigate()


    const updateKeywords = () => {

        simpleGetCallWithToken(ApiConfig.DOWNLOAD_KEYWORD + `?domain_id=${selectedDomainId}`).then((res) => {

            if (res?.success) {
                ToastMsg('success', res?.message)
            } else {
                ToastMsg('error', res?.message)
            }

        })

    }


    return (

        <div className={sidebar ? "taskMain " : "cx-active taskMain"}
        id="cx-main">
          
            <div
               
            >
            

                <div >
                    <div className="role-content">
                        <div className="sub-header-role d-flex justify-content-between">
                            <h6 className="mt-2">Conversation Flow</h6>
                            <h6 className="mt-2 mr-2">Domain Name : {domainName}</h6>
                        </div>
                        <div className="d-flex justify-content-between mt-4">
                            <div className="input_wraper">
                                {/* <input
                                    placeholder="Search By Question"
                                    type="text"

                                    style={{ width: '350px' }}
                                    value={search}
                                //onChange={(e) => setSearch(e.target.value)}
                                />
                                <button className="AddQuestion_submit_btn mx-3"
                                //  onClick={getQuestionList}

                                >Search</button> */}
                            </div>
                            <div>
                                <CommonButton
                                    title={'Update Keyword'}
                                    isLoading={false}
                                    handlerFunction={updateKeywords}
                                />
                                <CommonButton
                                    title={'Add Keyword'}
                                    isLoading={false}
                                    handlerFunction={() => navigate('/addconverstioflow')}
                                />
                            </div>
                        </div>
                        <div className="table-wrapper table-wrapper-2" style={{ height: "600px" }}>
                            {
                                isLoading ? <Loader /> :
                                    conversationalFlowList.length > 0 ?

                                        <table style={{ marginBottom: "20px" }}>
                                            <thead className="">
                                                <tr className="firstTabelRow">
                                                    <th>Sr. no</th>
                                                    <th>Module Name</th>
                                                    <th>Related Module</th>
                                                    <th>Module Action</th>
                                                    <th>Action</th>
                                                </tr>
                                            </thead>
                                            {conversationalFlowList.map((item, index) => (
                                                <tr key={index} className="table-row-custom">
                                                    <td>{index + 1} </td>
                                                    <td>{item?.module_name} </td>
                                                    <td>{item?.module_relation}</td>

                                                    <td>{item?.module_action}</td>
                                                    <td>
                                                        <OverlayTrigger
                                                            placement="bottom"
                                                            delay={{ show: 100, hide: 300 }}
                                                            overlay={(props) => (
                                                                <Tooltip id="button-tooltip" {...props}>
                                                                    Edit Question
                                                                </Tooltip>
                                                            )}
                                                        >
                                                            <FontAwesomeIcon
                                                                onClick={() => {
                                                                    //  setBackTo('/traineeQues')
                                                                    navigate("/addconverstioflow")
                                                                    setEditKeyWordDtls(item)
                                                                }}
                                                                icon={faPenToSquare}
                                                                style={{ width: "15px", height: "15px", marginRight:"10px" }}
                                                            />
                                                        </OverlayTrigger>{" "}

                                                        <OverlayTrigger
                                                            placement="bottom"
                                                            delay={{ show: 100, hide: 300 }}
                                                            overlay={(props) => (
                                                                <Tooltip id="button-tooltip" {...props}>
                                                                    Delete Question
                                                                </Tooltip>
                                                            )}
                                                        >
                                                            <FontAwesomeIcon icon={faTrash}
                                                                onClick={() => deleteKeyword(item?.module_id)}
                                                                style={{ width: "15px", height: "15px" }} />
                                                        </OverlayTrigger>
                                                    </td>
                                                </tr>
                                            ))}
                                        </table> : <NoDataFound />
                            }
                        </div>
                        {/* <Pagination totalPage={totalPage}
                            currentPage={currentPage}
                            pageChangeHandler={(page) => setCurrentPage(page + 1)}
                        /> */}
                    </div>
                </div>
            </div>
        </div>
    )


}