import React, { useState, useContext, useEffect } from "react";
import { AppContext } from "../../context/AppContext";
import {
  DeleteCallWithErrorResponseWithToken,
  PostCallWithErrorResponse,
  deleteWithAuthCall,
  simpleGetCallWithToken,
} from "../../api/ApiServices";
import ApiConfig from "../../api/ApiConfig";
import Editac from "../../assets/images/Editac.svg";
import DelectAc from "../../assets/images/DelectAc.svg";
import Axe from "../../assets/images/sidebar/icons/axeMark.svg";
import Modal from "react-bootstrap/Modal";
import { ToastMsg } from "../TostMsg";
import Pagination from "../../sharedComponent/Pagination";
import Loader from "../Loader";
import NoData from "../../sharedComponent/NoData";
import Select from "react-select";
import BackDropLoader from "../../sharedComponent/BackDropLoader";
import { getDateDashDDMMYYYY } from "../../common";
import FileSaver from "file-saver";

const GenerateSalarySlip = () => {
  const { sidebar, setSidebar, Dark, setDark, userData, permissions } =
    useContext(AppContext);

  const [teamName, setTeamName] = useState([]);
  const [desigName, setDesigName] = useState([]);
  const [employeeattlist, setEmployeeattlist] = useState([]);
  const [employeeId, setEmployeeId] = useState(null);
  const [loading, setLoading] = useState(false);
  const [Edit, setEdit] = useState(false);
  const [selectLoader, setSelectLoader] = useState(false);

  useEffect(() => {
    fetchEmpList();
  }, []);

  const fetchEmpList = () => {
    setSelectLoader(true);
    simpleGetCallWithToken(ApiConfig.GET_DROPDOWN_EMPLOYEE_NAMES)
      .then((res) => {
        if (res.Success === true) {
          let employeDropDownList = res?.Data.map((data) => {
            return {
              value: data?.Id,
              label: data?.FirstName,
            };
          });
          setEmployeeattlist(employeDropDownList);
        }
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setSelectLoader(false);
      });
  };
  const loggedEmployeeId = localStorage?.EmpId && Number(localStorage?.EmpId);
  const [monthAndYear, setmonthAndYear] = useState({
    EmpId: loggedEmployeeId,
    Month: "",
    Year: "",
  });

  const [totalEarnValue, setTotalEarnValue] = useState(null);
  const [salaryData, setSalaryData] = useState([]);
  console.log("salaryData,", salaryData);
  const getSalaryData = () => {
    setLoading(true);
    PostCallWithErrorResponse(
      ApiConfig?.GET_SALARY_DATA,
      JSON.stringify(monthAndYear)
    )
      .then((res) => {
        if (res?.json?.Success === true) {
          setSalaryData(res?.json?.Data?.GeneratesalaryStructure);

          const totalEarnings =
            res?.json?.Data &&
            res?.json?.Data.map((item) => {
              if (item?.SalaryStructureHead === "Professional Tax") {
                return 0;
              } else {
                return item?.MonthlyAmount;
              }
            }).reduce((total, monthlyAmount) => total + monthlyAmount, 0);

          totalEarnings
            ? setTotalEarnValue(totalEarnings)
            : setTotalEarnValue(null);
        } else {
          console.log("err res,", res);
        }
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => setLoading(false));
  };

  // GENERATE SALARY SLIP API
  const generateSlip = async () => {
    try {
      setLoading(true);
      const res = await PostCallWithErrorResponse(
        ApiConfig.GENERATE_SALARY_SLIP,
        JSON.stringify(monthAndYear)
      );
      if (res?.json?.Success === true) {
        console.log("GENERATE_SALARY_SLIP,", res);
        const { ContentType, FileContents, FileDownloadName } = res?.json?.Data;
        FileSaver.saveAs(
          "data:" + ContentType + ";base64," + FileContents,
          FileDownloadName
        );
        ToastMsg("success", res?.json?.Message)
      }else{
        ToastMsg("error", res?.json.Message)
      }
    } catch (error) {
      console.log(`GENERATE_SALARY_SLIP error`, error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      {loading && <BackDropLoader />}
      <div
        className={sidebar ? "taskMain " : "cx-active taskMain"}
        id="cx-main"
      >
        <div className="role-content">
          <div className="sub-header-role ">
            <h6 className="mt-2">Generate Salary Slip</h6>
          </div>
          <div className="innerRoleSec">
            <div className="row d-flex justify-content-center">
              <div className="col-lg-8 col-md-12">
                <div className="mb-2">
                  <label htmlFor="name" className="enter-role-text">
                    Select Employee Name
                  </label>
                  <Select
                    options={employeeattlist}
                    isLoading={selectLoader}
                    // onChange={event => {
                    //   setEmployeeId(event?.value)
                    //   setmonthAndYear
                    // }}
                    onChange={(e) => {
                      setmonthAndYear({
                        ...monthAndYear,
                        EmpId: e?.value,
                      });
                    }}
                  />
                  {/* <select
                    name=""
                    id="name"
                    class="form-select tasKCategory1 select-drop-icon"
                    aria-label="Select Employee Name"
                  >
                    <option value="">Select Employee Name</option>
                    <option value="">Employee Name 1</option>
                    <option value="">Employee Name 2</option>
                    <option value="">Employee Name 3</option>
                    <option value="">Employee Name 4</option>
                  </select> */}
                </div>
              </div>
              <div className="col-lg-8 col-md-12">
                <div className="mb-2">
                  <label htmlFor="Year" className="enter-role-text">
                    Select Year
                  </label>
                  <select
                    name=""
                    id="Year"
                    class="form-select tasKCategory1 select-drop-icon"
                    aria-label="Select Employee Name"
                    onChange={(e) => {
                      setmonthAndYear({
                        ...monthAndYear,
                        Year: e.target.value,
                      });
                    }}
                  >
                    <option value="">Please select year</option>
                    <option value="2024">2024</option>
                    <option value="2023">2023</option>
                    <option value="2022">2022</option>
                  </select>
                </div>
              </div>
              <div className="col-lg-8 col-md-12">
                <div className="modalProp mb-4">
                  <label htmlFor="month" className="enter-role-text">
                    Select Month
                  </label>
                  <select
                    name=""
                    id="month"
                    class="form-select tasKCategory1 select-drop-icon"
                    aria-label="Select Employee Name"
                    onChange={(e) => {
                      setmonthAndYear({
                        ...monthAndYear,
                        Month: e.target.value,
                      });
                    }}
                  >
                    <option value="">Please select month</option>
                    <option value="January">January</option>
                    <option value="February">February</option>
                    <option value="March">March</option>
                    <option value="April">April</option>
                    <option value="May">May</option>
                    <option value="June">June</option>
                    <option value="July">July</option>
                    <option value="August">August</option>
                    <option value="September">September</option>
                    <option value="October">October</option>
                    <option value="November">November</option>
                    <option value="December">December</option>
                  </select>
                </div>
              </div>
              <div className="col-md-12 text-center">
                <button
                  className="salary_structure_btn"
                  type="button"
                  onClick={getSalaryData}
                >
                  <span style={{ fontWeight: "bold" }}>+ </span>
                  Search
                </button>
                {permissions && permissions["Generate Salary Slip Pdf"] && (
                  <button
                    className="salary_structure_btn mx-3"
                    onClick={generateSlip}
                  >
                    <span style={{ fontWeight: "bold" }}>+ </span>
                    Generate PDF
                  </button>
                )}
                {/* {permissions && permissions["Generate Salary Slip Export"] && (
                  <button className="salary_structure_btn mx-3">
                    <span style={{ fontWeight: "bold" }}>+ </span>
                    Generate Musster Roll Excel
                  </button>
                )} */}
              </div>
            </div>
          </div>
          <div className="sub-header-role">
            <h6 className="mt-2">Salary Slip</h6>
          </div>
          <div className="table-wrapper custum_table">
            <table>
              <tbody>
                <tr className="table-row-custom">
                  <td>Employee Name</td>
                  <td>{salaryData[0]?.EmployeeName}</td>
                  <td>Employee ID</td>
                  <td>{salaryData[0]?.EmployeeCode}</td>
                </tr>
                <tr className="table-row-custom">
                  <td>Designation</td>
                  <td>{salaryData[0]?.Designation} </td>
                  <td>Locations</td>
                  <td>{salaryData[0]?.Location} </td>
                </tr>
                <tr className="table-row-custom">
                  <td>Gender</td>
                  <td>{salaryData[0]?.Gender} </td>
                  <td>Available Working Days</td>
                  <td>{salaryData[0]?.AvailableWD} </td>
                </tr>
                <tr className="table-row-custom">
                  <td>Date of Joining</td>
                  <td>
                    {salaryData[0]?.AbsentDays &&
                      getDateDashDDMMYYYY(salaryData[0]?.DTJ)}
                  </td>
                  <td>Loss of paid days</td>
                  <td>{salaryData[0]?.AbsentDays}</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="table-wrapper custum_table">
            <table>
              <thead>
                <tr className="firstTabelRow">
                  <th>Earnings</th>
                  <th>Amount</th>
                  <th>Deductions</th>
                  <th>Amount</th>
                </tr>
              </thead>
              <tbody>
                <tr className="table-row-custom">
                  <td>Basic</td>
                  <td>{salaryData[0]?.MonthlyAmount}</td>
                  <td>Professional Tax</td>
                  <td>{salaryData[6]?.Deductions} </td>

                </tr>
                <tr className="table-row-custom">
                  <td>HRA</td>
                  <td>{salaryData[1]?.MonthlyAmount} </td>
                  <td>Loss of paid days</td>
                  <td>{salaryData[7]?.MonthlyAmount}</td>
                </tr>
                <tr className="table-row-custom">
                  <td>Conveyance</td>
                  <td>{salaryData[2]?.MonthlyAmount}</td>
                  <td> </td>
                  <td> </td>
                </tr>
                <tr className="table-row-custom">
                  <td>Medical Allowance</td>
                  <td>{salaryData[3]?.MonthlyAmount} </td>
                  <td> </td>
                  <td> </td>
                </tr>
                <tr className="table-row-custom">
                  <td>Education Allowance</td>
                  <td>{salaryData[4]?.MonthlyAmount} </td>
                  <td> </td>
                  <td> </td>
                </tr>
                <tr className="table-row-custom">
                  <td>Others</td>
                  <td>{salaryData[5]?.MonthlyAmount} </td>
                  <td> </td>
                  <td> </td>
                </tr>

                <tr className="table-row-custom">
                  <td>(A) Total Earnings</td>
                  <td>{salaryData[6]?.GrossAmounts && salaryData[6]?.GrossAmounts} </td>
                  <td>(B) Total Deductions</td>
                  <td>{salaryData[6]?.TotalDeductions} </td>
                </tr>
                <tr className="table-row-custom">
                  <td></td>
                  <td></td>
                  <td>Net Salary = (A) - (B)</td>
                  <td>
                    {salaryData[6]?.NetAmounts}
                    {/* {totalEarnValue &&
                      totalEarnValue - salaryData[6]?.NetAmounts}{" "} */}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        {/* <Pagination
          data={employeeattlist}
          pageChangeHandler={pageChangeHandler}
          usersPerPage={usersPerPage}
          currentPage={currentPage}
          searchValue={searchValue}
          searchedDataPageCounts={searchedDataPageCounts}
        /> */}
      </div>

      {/* Edit Modal Task */}
      <Modal aria-labelledby="contained-modal-title-vcenter" centered>
        <Modal.Header>
          <Modal.Title>
            <label htmlFor="" className="create-role-text">
              Edit Cost
            </label>
          </Modal.Title>
          <div className="rightAxe">
            <img src={Axe} alt="" onClick={() => setEdit(false)} />
          </div>
          {/* <img src={close} alt="" onClick={handleClose} className="cr-class" /> */}
        </Modal.Header>
        <div className="hr-line-modal">
          <hr style={{ margin: "0" }} />
        </div>

        <Modal.Body>
          <div className="mb-4">
            <label htmlFor="" className="enter-role-text">
              Team Name
            </label>
            <select
              class="form-select tasKCategory1 select-drop-icon"
              aria-label="Default select example"
              // value={addCost?.TeamId ? addCost?.TeamId : ""}
              // onChange={(e) => {
              //   changeData(e);
              // }}
              name="TeamId"
            >
              <option value="">Team Name</option>
              {teamName.map((name, index) => {
                return (
                  <option value={name.TeamId} key={index}>
                    {name.TeamName}
                  </option>
                );
              })}
            </select>
          </div>
          <div className="mb-4">
            <label htmlFor="" className="enter-role-text">
              Designation
            </label>
            <select
              class="form-select tasKCategory1 select-drop-icon"
              aria-label="Default select example"
              // value={addCost?.DesignationId ? addCost?.DesignationId : null}
              // onChange={(e) => {
              //   changeData(e);
              // }}
              name="DesignationId"
            >
              <option value="">Select Designation</option>
              {desigName.map((name, index) => {
                return (
                  <option value={name.DesignationId} key={index}>
                    {name.Name}
                  </option>
                );
              })}
            </select>
          </div>
          <div className="modalProp mb-4">
            <label htmlFor="" className="enter-role-text">
              Cost
            </label>
            <input
              // onChange={(e) => {
              //   changeData(e);
              // }}
              name="cost"
              // value={addCost?.cost ? addCost?.cost : null}
              type="number"
              className="date-label-input tasKCategory1"
              style={{
                fontSize: "14px",
                height: "38px",
                border: "1px solid #ced4da",
              }}
            />
          </div>
        </Modal.Body>

        <div className="hr-line-modal-1">
          <hr style={{ margin: "0" }} />
        </div>
        <Modal.Footer className="model-footer-arbaz-responsive">
          <div className="Bottom-btn-arbaz-responsive">
            <button
              className="modal-cancel-btn branchList-modal-cancel-btn-arbaz-responsive"
              onClick={() => setEdit(false)}
            >
              Cancel
            </button>
            <button
              className="modal-create-btn branchList-modal-cancel-btn-arbaz-responsive"
            // onClick={updateCost}
            >
              Update
            </button>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default GenerateSalarySlip;
