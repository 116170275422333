import React, { useState, useContext, useEffect } from "react";
import { AppContext } from "../../context/AppContext";
import { Form, Tab, Tabs } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import { PostCallWithErrorResponse, postMultipartWithAuthCallWithErrorResponse, simpleGetCallWithToken } from "../../api/ApiServices";
import ApiConfig from "../../api/ApiConfig";
import { ToastMsg } from "../TostMsg";
import Loader from "../Loader";

const UpdateUserDetails = () => {
  const { sidebar, setSidebar, Dark, setDark, userData } =
    useContext(AppContext);
  const { id } = useParams();
  const naviget = useNavigate()
  const [detailsdata, setDetailsData] = useState({})
  const [conutryList, setCountryList] = useState([])
  const [cityList, setCityList] = useState([])
  const [stateList, setStateList] = useState([])
  const [loading, setLoding] = useState(false)

  const [updateDetails, setUpdateDetails] = useState({
    firstName: '',
    lastName: '',
    email: '',
    contactNumber: '',
    gender: '0',
    type: '0',
    country: '0',
    state: '0',
    city: '0',
  });

  console.log("agemt Details UpdateId>>>", id)

  const handleChange = (e) => {
    const { name, value } = e.target;
    setUpdateDetails({
      ...updateDetails,
      [name]: value,
    });
  };

  const getAgentdetails = () => {
    setLoding(true)
    simpleGetCallWithToken(ApiConfig.UPDATE_AGENT_DETAILS + `${id}/`)
      .then((response) => {
        setDetailsData(response?.user)
        console.log("useDetails Data>>>>", response?.user)
        setUpdateDetails((prev) => ({
          ...prev,
          firstName: response?.user?.first_name,
          lastName: response?.user?.last_name,
          email: response?.user?.email,
          contactNumber: response?.user?.phone_number,
          gender: response?.user?.gender,
          type: response?.user?.type,
          country: response?.user?.country_id,
          state: response?.user?.state_id,
          city: response?.user?.city_id,
        }));
        setLoding(false)
      })
      .catch((err) => {
        console.log("adent Details Error>>>", err)
        setLoding(false)
      })
  }

  const getCountryList = () => {
    
    simpleGetCallWithToken(ApiConfig.GET_COUNTRY_LIST)
      .then((response) => {
        console.log("country List>>>>", response)
        setCountryList(response)

      })
      .catch((err) => {
        console.log("country List Error>>>", err)
        

      })
  }

  const getUpdateData = () => {
    setLoding(true)

    const body = {
      "first_name": updateDetails.firstName,
      "last_name": updateDetails.lastName,
      "email": updateDetails.email,
      "contact_number": updateDetails.contactNumber,
      "gender": updateDetails.gender,
      "type": updateDetails.type,
      "Country": updateDetails.country,
      "state": updateDetails.state,
      "city": updateDetails.city
    }

    const url = id
    PostCallWithErrorResponse(ApiConfig.UPDATE_AGENT_DETAILS + `${url}/`, JSON.stringify(body))
      .then((res) => {
        if (res.json?.message === "Successfully Updated.") {
          ToastMsg("success", res?.json?.message);
          setLoding(false)

          naviget("/AgentList")

        }
        console.log("update Data>>>", res)
        setLoding(false)

      })
      .catch((err) => {
        console.log("update Data Error >>>", err)

      })
  }

  const getStateData = (id) => {

    const body = {
      country_id: id
    }


    PostCallWithErrorResponse(ApiConfig.GET_STATE_LIST, JSON.stringify(body))
      .then((res) => {
        console.log("update Data>>>", res)
        setStateList(res?.json?.states)
      })
      .catch((err) => {
        console.log("update Data Error >>>", err)

      })
  }

  const getCityData = (id) => {

    const body = {
      state_id: id
    }


    PostCallWithErrorResponse(ApiConfig.GET_CITY_LIST, JSON.stringify(body))
      .then((res) => {
        console.log("update Data>>>", res)
        setCityList(res?.json?.cities)
      })
      .catch((err) => {
        console.log("update Data Error >>>", err)

      })
  }

  useEffect(() => {
    if (updateDetails.country) {
      getStateData(updateDetails.country)
    }
    if (updateDetails.state) {
      getCityData(updateDetails.state)
    }

  }, [updateDetails.country, updateDetails.state])

  useEffect(() => {
    getAgentdetails()
    getCountryList()
  }, [])

  return (
    <div className={sidebar ? "taskMain " : "cx-active taskMain"} id="cx-main">
      <div className="role-content">
        <div className="sub-header-role mb-4">
          <h6 className="mt-2">User Details</h6>
        </div>
        {loading ? (<>
          <Loader />
        </>) : (
          <div className="settingsCard">
            <div className="row">
              <div className="col-lg-6">
                <div className="my-3">
                  <label htmlFor="" className="enter-role-text mt-2">
                    First Name
                  </label>
                  <input
                    name="firstName"
                    type="text"
                    value={updateDetails.firstName}
                    onChange={handleChange}
                    className="date-label-input tasKCategory1 ps-2"
                    placeholder="First Name"
                    style={{
                      fontSize: '0.8rem',
                      borderRadius: '10px',
                      height: '40px',
                    }}
                  />
                </div>
              </div>
              <div className="col-lg-6">
                <div className="my-3">
                  <label htmlFor="" className="enter-role-text mt-2">
                    Last Name
                  </label>
                  <input
                    name="lastName"
                    type="text"
                    value={updateDetails.lastName}
                    onChange={handleChange}
                    className="date-label-input tasKCategory1 ps-2"
                    placeholder="Last Name"
                    style={{
                      fontSize: '0.8rem',
                      borderRadius: '10px',
                      height: '40px',
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-6">
                <div className="my-3">
                  <label htmlFor="" className="enter-role-text mt-2">
                    Email
                  </label>
                  <input
                    name="email"
                    type="email"
                    value={updateDetails.email}
                    onChange={handleChange}
                    className="date-label-input tasKCategory1 ps-2"
                    placeholder="Email"
                    style={{
                      fontSize: '0.8rem',
                      borderRadius: '10px',
                      height: '40px',
                    }}
                  />
                </div>
              </div>
              <div className="col-lg-6">
                <div className="my-3">
                  <label htmlFor="" className="enter-role-text mt-2">
                    Contact Number
                  </label>
                  <input
                    name="contactNumber"
                    type="text"
                    value={updateDetails.contactNumber}
                    onChange={handleChange}
                    className="date-label-input tasKCategory1 ps-2"
                    placeholder="Contact Number"
                    style={{
                      fontSize: '0.8rem',
                      borderRadius: '10px',
                      height: '40px',
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-6">
                <div className="my-3">
                  <label htmlFor="" className="enter-role-text mt-2">
                    Gender
                  </label>
                  <select
                    name="gender"
                    value={updateDetails.gender}
                    onChange={handleChange}
                    className="form-select tasKCategory1 select-drop-icon"
                    aria-label="Default select example"
                  >
                    <option value="0">Select Gender</option>
                    <option value="Male">Male</option>
                    <option value="Female">Female</option>
                  </select>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="my-3">
                  <label htmlFor="" className="enter-role-text mt-2">
                    Type
                  </label>
                  <select
                    name="type"
                    value={updateDetails.type}
                    onChange={handleChange}
                    className="form-select tasKCategory1 select-drop-icon"
                    aria-label="Default select example"
                  >
                    <option value="0">Select Type</option>
                    <option value="Candidate">Candidate</option>
                    <option value="Prospect">Prospect</option>
                    <option value="Spam">Spam</option>
                    <option value="Lead">Lead</option>
                    <option value="App_User">App_User</option>
                    <option value="Inquiry">Inquiry</option>
                    <option value="Agent">Agent</option>

                  </select>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-6">
                <div className="my-3">
                  <label htmlFor="" className="enter-role-text mt-2">
                    Country
                  </label>
                  <select
                    name="country"
                    value={updateDetails.country}
                    onChange={handleChange}
                    className="form-select tasKCategory1 select-drop-icon"
                    aria-label="Default select example"
                  >
                    <option value="0">Select Country</option>
                    {
                      conutryList?.map((item) => (
                        <option value={item.Countries_id}>{item?.name}</option>
                      ))
                    }
                    {/* <option value="1">United States</option>
         <option value="2">Canada</option>
         <option value="3">United Kingdom</option>
         <option value="4">Australia</option>
         <option value="5">India</option> */}
                  </select>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="my-3">
                  <label htmlFor="" className="enter-role-text mt-2">
                    State
                  </label>
                  <select
                    name="state"
                    value={updateDetails.state}
                    onChange={handleChange}
                    className="form-select tasKCategory1 select-drop-icon"
                    aria-label="Default select example"
                  >
                    <option value="0">Select State</option>
                    {
                      stateList?.map((item) => (
                        <option value={item.st_id}>{item?.name}</option>
                      ))
                    }
                    {/* Add state options here */}
                  </select>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-6">
                <div className="my-3">
                  <label htmlFor="" className="enter-role-text mt-2">
                    City
                  </label>
                  <select
                    name="city"
                    value={updateDetails.city}
                    onChange={handleChange}
                    className="form-select tasKCategory1 select-drop-icon"
                    aria-label="Default select example"
                  >
                    <option value="0">Select City</option>
                    {
                      cityList?.map((item) => (
                        <option value={item?.id}>{item?.name}</option>
                      ))
                    }
                    {/* Add city options here */}
                  </select>
                </div>
              </div>
            </div>
            <div className="col-md-12 text-end mt-2">
              <button className="impButton" onClick={getUpdateData}>
                Update
              </button>
            </div>
          </div>
        )}

      </div>
    </div>
  );
};

export default UpdateUserDetails;
