import React, { useEffect, useContext, useState } from "react";
import Editor from "./Editor";
import ChatList from "../chat/ChatList";
import CreatableSelect from "react-select/creatable";


import { OverlayTrigger, Tooltip } from "react-bootstrap";


import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import ApiConfig from "../../../api/ApiConfig";
import { deleteWithAuthCall, getRequest, postRequest, putRequest, simpleGetCallWithTokenNoNBEARER } from "../../../api/ApiServices";
import { ToastMsg } from "../../TostMsg";
import Loader from "../../Loader";
import { AppContext } from "../../../context/AppContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faTrash, faCircleInfo } from "@fortawesome/free-solid-svg-icons";

const getChatRs = [
  {
    sender_id: "user1",
    last_message: "Hello, how can I help you?",
    timestamp: "2024-07-21T12:34:56.789Z",
  },
  {
    sender_id: "user2",
    last_message: "I need assistance with my order.",
    timestamp: "2024-07-21T12:35:56.789Z",
  },
  {
    sender_id: "user3",
    last_message: "Can you provide more details on your service?",
    timestamp: "2024-07-21T12:36:56.789Z",
  },
];

const getChatDetails = {
  events: [
    {
      type_name: "user",
      timestamp: 1675970400,
      data: {
        text: "Hello, how can I help you today?",
      },
    },
    {
      type_name: "bot",
      timestamp: 1675970460,
      data: {
        text: "I need some details about your issue.",
      },
    },
    {
      type_name: "user",
      timestamp: 1675970520,
      data: {
        text: "I have a problem with my order.",
      },
    },
    {
      type_name: "bot",
      timestamp: 1675970580,
      data: {
        text: "Can you provide me with your order number?",
      },
    },
    {
      type_name: "system", // This type will be filtered out
      timestamp: 1675970640,
      data: {
        text: "System maintenance in progress.",
      },
    },
  ],
};


const validRegex = /[^a-zA-Z0-9_]/

function AddQuestions() {

  const { id } = useParams();

  const { sidebar, selectedDomainId, domainName, backTo, setBackTo, addInExisting } = useContext(AppContext);

  const [answer, setAnswer] = useState('')

  const [answerErr, setAnswerErr] = useState('')

  const [questionList, setQuestionList] = useState([
    {
      question: '',
      answer: '',
      Queserror: '',
      ansError: ''
    }
  ])

  const [isLoading, setIsLoading] = useState(true)
  const navigate = useNavigate();

  const [editorHtml, setEditorHtml] = useState("");

  const [question, setQuestions] = useState("");

  const [searchParams] = useSearchParams();

  const [intentAndKeywords, setIntentAndKeywords] = useState([{ intent: "", keyword: "", err: '' }]);

  const [assistenName, setAssistentName] = useState([]);

  const [selectedDomain, setSelectedDomain] = useState("");

  const [productModule, setProductModule] = useState('');

  const [assistantsList, setAssistantsList] = useState([]);

  const [selectedChat, setSelectedChat] = useState(undefined);

  const [intentKeywordErr, setIntentKeywordErr] = useState(false)

  const [selectedChatDetails, setSelectedChatDetails] = useState(undefined);

  const [chats, setChats] = useState([]);

  const handleChatClick = (selectedChat) => {
    setSelectedChat(selectedChat);
    // setSelectedChatDetails(undefined);

    setSelectedChatDetails(getChatDetails);
    // RasaAdminAPI.getChat(selectedChat.sender_id).then(data => {
    // });
  };



  useEffect(() => {

    console.log(searchParams);

    // Fetch chat collection
    // RasaAdminAPI.getChats().then(data => setChats(data));
    setChats(getChatRs);
    getAssistantlist();
    if (id) {


      if (searchParams.get('question')) {


        setQuestionList([{
          question: searchParams.get('question'),
          answer: ""
        }])

        setIsLoading(false)

      } else {

        getQuetionDetails();

      }

    }
    else {

      if (searchParams.get('add')) {

        console.log(addInExisting);


        setQuestions("");
        setIntentAndKeywords([{ intent: addInExisting?.intent, keyword: addInExisting?.keyword }])
        setAnswer(addInExisting?.answer)
        // setAssistentName([])
        // setSelectedDomain("");
        // setEditorHtml("");
        // setProductModule("");

        setIsLoading(false)

        return
      }
      setQuestions("");
      setIntentAndKeywords([{ intent: "", keyword: "" }])
      setAssistentName([])
      setSelectedDomain("");
      setEditorHtml("");
      setProductModule("");
      setIsLoading(false)
    }


    return () => {

      setBackTo('')

    }
  }, [id]);

  const { isDrawerOpen } = useContext(AppContext);

  const handleIntentAndKeywordChange = (value, key, index) => {




    setIntentAndKeywords(prev => {
      const data = [...prev];
      const element = { ...data[index] };
      element[key] = value;
      data[index] = element;
      return data;
    })
  }

  const handleSubmite = () => {
    /*  {
        "question":"Request a demo",
        "answer":"Great! We offer a free demo so you can explore the features and benefits of our vehicle tracking software before making a purchase. Visit the website and click on 'Free Demo' get started.",
        "intent":"request_demo,default",
        "keyword":"Reques Free Demo, Get yourself free online demo",
        "product_module":"Pricing",
        "domain_id":4,
        "assistants":[]
    }*/



    let isValid = true;

    if (searchParams.get('add') !== "add" && backTo === '/traineeQues' && questionList.length < 4 && !id) {

      let remain = 4 - questionList.length
      let msg = 'Please Enter Atleast 4 Questions ' + remain + ' Are Still Remain'

      ToastMsg('error', msg)

      return

    }

    if (answer.length === 0) {

      setAnswerErr('Please Enter Answer')
      isValid = false

    } else {
      setAnswerErr('')
    }

    let newQuest = questionList.map((i) => {

      if (!i?.question?.length) {
        isValid = false
      }




      return {
        ...i, Queserror: !i?.question?.length ? "Please Enter Question" : '',

      }

    })

    setQuestionList(newQuest)

    let intentErr = intentAndKeywords.map((i) => {

      let err = testIntentIsvalid(i.intent)

      console.log(err);


      if (!i.intent || !i?.keyword || err.length > 0) {
        isValid = false
        setIntentKeywordErr(true)

      }

      return { ...i, err: err }


    })

    setIntentAndKeywords(intentErr)

    // intentAndKeywords.forEach((i) => {
    //   if (!i.intent || !i?.keyword) {
    //     isValid = false
    //     setIntentKeywordErr(true)

    //   }
    // })

    if (!isValid) return

    setIsLoading(true)

    let data = []
    let intent = intentAndKeywords?.map(item => item.intent).toString();
    let keyword = intentAndKeywords?.map(item => item?.keyword).toString();
    let assistants = assistenName.map(item => item.value);
    questionList.forEach((ques) => {
      data.push({
        question: ques.question,
        answer: answer,
        product_module: productModule,
        intent,
        keyword,
        assistants: assistants,
        domain_id: selectedDomainId,
      })
    })




    if (id && !searchParams.get('question')) {
      putRequest(ApiConfig.PUT_UPDATE_QUESTION_BY_ID + id, JSON.stringify(data[0]))
        .then((res) => {
          if (res?.success) {
            setIsLoading(false)
            navigate(backTo)
            ToastMsg('success', res?.message)
          } else {
            setIsLoading(false)
            ToastMsg('error', res?.message)
          }

          console.log(res);
        }).catch((err) => {
          console.log(err);
        })
      return
    }


    let promisses = data.map((i) => {
      return postRequest(ApiConfig.ADD_QUESTIONS + selectedDomainId, JSON.stringify(i))
    })

    console.log(promisses);

    Promise.all(promisses).then((res) => {

      if (res.every(i => i.success)) {

        setIsLoading(false)
        ToastMsg("success", res[0]?.message)

        if (searchParams.get('question')) {

          deleteWithAuthCall(ApiConfig.DELETE_UN_ANSWERED_QUES + id).then((res) => {

            console.log(res);


          })

        }


        navigate(backTo)

      } else {

        setIsLoading(false)
        ToastMsg("error", res[0]?.message)
        console.log('error');
      }

    }).catch((err) => {

      setIsLoading(false)
      console.log(err);

    })


    //   postRequest(ApiConfig.ADD_QUESTIONS+selectedDomainId, JSON.stringify(data))
    //     .then((res) => {
    //       if (res?.success) {
    //         navigate("/questionListing")
    //       }
    //     })
    //     .catch((err) => {
    //       console.log(err)
    //     })
  }



  function getQuetionDetails() {
    getRequest(ApiConfig.GET_QUESTION_BY_ID + id)
      .then((res) => {
        if (res.success) {

          setQuestionList([{
            question: res?.data?.question,
            answer: res?.data?.answer
          }])

          setAnswer(res?.data?.answer)



          setQuestions(res?.data?.question);
          setProductModule(res?.data?.product_module);
          setSelectedDomain(res?.data?.domain_id)
          let intent = res?.data?.intent;
          let keyword = res?.data?.keyword;
          intent = intent?.split(",");
          keyword = keyword?.split(",")


          // merge intent and keywords
          const intentKeyword = [];
          let idxIntent = 0;
          let idxKeyword = 0;
          while (idxIntent < intent?.length && idxKeyword < keyword?.length) {
            intentKeyword.push({ intent: intent[idxIntent], keyword: keyword[idxKeyword] })
            idxIntent++;
            idxKeyword++;
          }
          while (idxIntent < intent?.length) {
            intentKeyword.push({ intent: intent[idxIntent], keyword: "" })
            idxIntent++;
          }
          while (idxKeyword < keyword?.length) {
            intentKeyword.push({ intent: "", keyword: keyword[idxKeyword] })
            idxKeyword++;
          }
          setIntentAndKeywords(intentKeyword);


          // assistant merge
          let assistants = res?.data?.assistants;
          assistants = assistants.map(item => ({ value: item?.id, label: item?.name }))
          setAssistentName(assistants)


          // set answer 
          setEditorHtml(res?.data?.answer);

          setIsLoading(false)
        }
      })
      .catch((err) => {
        console.log(err);

      })


  }

  function getAssistantlist() {
    simpleGetCallWithTokenNoNBEARER(ApiConfig.GET_ASSISTANTS)
      .then((res) => {
        console.log(res)
        if (res.success) {
          setAssistantsList(res?.chatbot_assistants);
        }
      }).catch((err) => {
        console.log(err)
      })
  }


  const removeQuestion = (index) => {

    let ques = questionList.filter((_, indx) => {

      return index != indx

    })

    setQuestionList(ques)

  }


  const testIntentIsvalid = (intent) => {

    if (!intent.length) return "Please Enter Intent"


    if (intent[0] == "_" || intent[intent.length - 1] == "_") {

      return 'Please Do Not Enter _ Start Or End of Intent'

    }


    if (validRegex.test(intent)) {

      return 'Please Do Not Enter Special Charecters Or Space In Intent'

    }

    let splt = intent.split('_')
    if (splt.includes('')) {
      return "two continue underscore dont allowed"
    }


    if (intent.split('_').length > 4) {

      return 'Only Three Underscore are allowed'

    }

    if (splt.length != 4) {
      return 'there should be 4 word and 3 underscore allowed'
    }


    return ""

  }


  return (
    <div className={sidebar ? "taskMain " : "cx-active taskMain"}
      id="cx-main">

      <div

      >
        {" "}

        <div >
          <div className="role-content">
            <div className="sub-header-role d-flex justify-content-between">
              <h6 className="mt-2">Add Question</h6>
              <h6 className="mt-2 mr-2">Domain Name : {domainName}</h6>
            </div>
            {/* <div className="AddQuestion_input_wraper">
              <label htmlFor="">Question</label>
              <input
                type="text"
                value={question}
                onChange={(e) => setQuestions(e.target.value)}
                placeholder="Write Question"
                className="w-100"
              />
            </div>
            <div className="Answer_input_wraper">
              <label htmlFor="">Answer</label>
              <div className="AddQuestion_Editor_wrapper">
                <Editor
                  editorHtml={editorHtml}
                  setEditorHtml={setEditorHtml}
                  placeholder={"Write your answer"}
                />
              </div>
            </div> */}
            {
              isLoading ? <div className="mt-5"><Loader /></div> :

                <>
                  {
                    questionList.map((ques, index) => {
                      return <>
                        <div className="AddQuestion_input_wraper">
                          <label htmlFor="">Question</label>
                          <input
                            type="text"
                            value={ques.question}
                            onChange={(e) => {
                              const newQuestionList = questionList.map((item, idx) =>
                                idx === index ? { ...item, question: e.target.value } : item
                              );
                              setQuestionList(newQuestionList);
                            }}
                            placeholder="Write Question"
                            className="w-100"
                          />
                          <span span className="text-danger">{ques?.Queserror}</span>
                        </div>
                        {
                          index != 0 && <div className="d-flex justify-content-end mb-2">
                            <button className="AddQuestion_submit_btn"
                              onClick={() => removeQuestion(index)}
                            >Remove Question</button>
                          </div>
                        }
                      </>
                    })
                  }
                  {
                    !id && <div className="w-100 d-flex align-items-end justify-content-end mt-2">
                      <button className="AddQuestion_submit_btn" onClick={() => {
                        setQuestionList([...questionList, {
                          question: '',
                          answer: '',
                          Queserror: "",
                          ansError: ""
                        }])
                      }} >Add Question</button>
                    </div>
                  }


                  <div className="Answer_input_wraper">
                    <label htmlFor="">Answer</label>
                    <div className="AddQuestion_Editor_wrapper">
                      <Editor
                        editorHtml={answer}
                        setEditorHtml={(html) => {
                          setAnswer(html)

                          //   const updatedQuestionList = questionList.map((item, idx) =>
                          //   //  idx === index ? { ...item, answer: html } : item
                          //   );
                          // //  setQuestionList(updatedQuestionList)
                        }}
                        placeholder={"Write your answer"}
                      />
                    </div>
                    <span className="text-danger">{answerErr}</span>
                  </div>

                  {intentAndKeywords.map((ele, index) => {
                    return (
                      <div className="custmRow">

                        <div className="col-5 input_wraper colWdth">

                          <label htmlFor="">Intent <span className="ml-4 mt-1"> <OverlayTrigger
                            placement="bottom"
                            delay={{ show: 100, hide: 300 }}

                            overlay={(props) => (
                              <Tooltip id="button-tooltip" {...props}  >
                                <div style={{ backgroundColor: 'black', margin: '-10px', padding: '5px' }}>
                                  Please Ensure that the Intent is properly mapped <br />according to the naming convention: Module_Submodule_Topic_Action
                                </div>
                              </Tooltip>
                            )}
                          ><FontAwesomeIcon icon={faCircleInfo} /></OverlayTrigger></span></label>

                          <input
                            placeholder="Write intent"
                            className="w-100"
                            type="text"
                            value={ele.intent}
                            onChange={(e) => handleIntentAndKeywordChange(e.target.value, "intent", index)}
                          />
                          <span className="text-danger">{ele.err}</span>
                        </div>
                        <div className="col-5 input_wraper colWdth">
                          <label htmlFor="">Keywords</label>
                          <input
                            placeholder="Write Keywords"
                            className="w-100"
                            type="text"
                            value={ele.keyword}
                            onChange={(e) => handleIntentAndKeywordChange(e.target.value, "keyword", index)}
                          />
                          <span className="text-danger">{!ele.keyword && intentKeywordErr && 'Please Enter Keyword'}</span>
                        </div>
                        <div className="col-2 d-flex align-items-end input_wraper colWdthIcon">
                          <div className="addDltIconWrap">
                            {index === 0 ? (
                              <>
                                <FontAwesomeIcon
                                  icon={faPlus}
                                  width={20}
                                  onClick={() =>
                                    setIntentAndKeywords((prev) => [...prev, { intent: "", keyword: "" }])
                                  }
                                  className="icon"
                                />
                              </>
                            ) : (
                              <>
                                <FontAwesomeIcon
                                  icon={faTrash}
                                  width={20}
                                  onClick={() =>
                                    setIntentAndKeywords((prev) =>
                                      prev.filter((el, i) => index !== i)
                                    )
                                  }
                                  className="icon"
                                />
                              </>
                            )}
                          </div>
                        </div>
                      </div>
                    );
                  })}

                  <div className="mt-3">
                    <div className="sub-header-role pt-1 pb-1">
                      <h6 className="mt-2">Assistant |<span style={{ fontSize: '15px' }}> Optional</span></h6>
                    </div>

                    <div className="row mt-1 mb-1">
                      <div className="col-6 input_wraper">
                        <label htmlFor="">Name</label>
                        {/* <input placeholder='Write assistant name' className='w-100' type="text" /> */}
                        <CreatableSelect
                          isMulti
                          isClearable
                          options={assistantsList?.map(item => ({ value: item?.id, label: item?.first_name + " " + item?.last_name }))}
                          value={assistenName}
                          onChange={(e) => {
                            setAssistentName(e);
                          }}
                          classNamePrefix="custom-select"
                        />
                      </div>
                      <div className="col-6 input_wraper">
                        <label htmlFor="">Product Module</label>
                        <input
                          placeholder="Write name of product module"
                          className="w-100"
                          type="text"
                          value={productModule}
                          onChange={(e) => setProductModule(e.target.value)}
                        />
                      </div>
                    </div>

                    {/* <div className="row">
                {/* <div className="col-7 d-flex">
                  <div className="row col-12 ">
                    <div className=" input_wraper ">
                      <label htmlFor="">Select Domain</label>
                      <select
                        value={selectedDomain}
                        onChange={(e) => setSelectedDomain(e.target.value)}
                        className="selection"
                      >
                        <option value={""}>Select Domain</option>
                        {
                          domains.map((item) => (
                            <option value={item?.id}>{item?.domain} </option>
                          ))}
                      </select>
                    </div>
                  </div>
                </div> 

                <div className="col-5 input_wraper">
                  <label htmlFor="">Product Module</label>
                  <input
                    placeholder="Write name of product module"
                    className="w-100"
                    type="text"
                    value={productModule}
                    onChange={(e) => setProductModule(e.target.value)}
                  />
                </div>
              </div> */}
                    <div className="w-100 d-flex align-items-end justify-content-end">
                      <button className="AddQuestion_submit_btn" onClick={handleSubmite}>Submit</button>
                    </div>
                  </div>
                </>
            }
          </div>
        </div>
      </div>
    </div>
  );
}

export default AddQuestions;
