import { useEffect, useState } from "react";


import { faImage } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { SketchPicker } from "react-color";
import { ToastMsg } from "../../TostMsg";
import { useNavigate, useParams } from "react-router-dom";
import { AppContext } from "../../../context/AppContext";
import ApiConfig from "../../../api/ApiConfig";
import { getRequest, PostCallWithErrorResponse, postMultipartRequestAdmin, postMultipartWithAuthCallWithErrorResponse, postRequest, putMultipartWithAuthCall, putMultipartWithAuthCallWithErrorResponse, putMultipartWithAuthCallWithErrorResponseAdmin, putMultipartWithAuthCallWithErrorResponseAdminNew, simpleGetCall } from "../../../api/ApiServices";
import { useContext } from "react";

const getChatRs = [
  {
    sender_id: "user test",
    last_message: "Hello, how can I help you?",
    timestamp: "2024-07-21T12:34:56.789Z",
  },
  {
    sender_id: "user2",
    last_message: "I need assistance with my order.",
    timestamp: "2024-07-21T12:35:56.789Z",
  },
  {
    sender_id: "user3",
    last_message: "Can you provide more details on your service?",
    timestamp: "2024-07-21T12:36:56.789Z",
  },
];

const getChatDetails = {
  events: [
    {
      type_name: "user",
      timestamp: 1675970400,
      data: {
        text: "Hello, how can I help you today?",
      },
    },
    {
      type_name: "bot",
      timestamp: 1675970460,
      data: {
        text: "I need some details about your issue.",
      },
    },
    {
      type_name: "user",
      timestamp: 1675970520,
      data: {
        text: "I have a problem with my order.",
      },
    },
    {
      type_name: "bot",
      timestamp: 1675970580,
      data: {
        text: "Can you provide me with your order number?",
      },
    },
    {
      type_name: "system", // This type will be filtered out
      timestamp: 1675970640,
      data: {
        text: "System maintenance in progress.",
      },
    },
  ],
};

const theme = localStorage.getItem("theme")
  ? JSON.stringify(localStorage.getItem("theme"))
  : null;
const colorData = [
  {
    srNo: "1",
    type: "Primary text color",
    key: "primary_text_color",
    value: "#0000",
  },
  {
    srNo: "2",
    type: "Primary background color",
    key: "primary_background_color",
    value: "#0000",
  },
  {
    srNo: "3",
    type: "User message background color",
    key: "user_message_bg_color",
    value: "#0000",
  },
  {
    srNo: "4",
    type: "User message text color",
    key: "user_message_text_color",
    value: "#0000",
  },
  {
    srNo: "5",
    type: "Bot message text color",
    key: "bot_message_text_color",
    value: "#0000",
  },
  {
    srNo: "6",
    type: "Bot message background color",
    key: "bot_message_bg_color",
    value: "#0000",
  },
  {
    srNo: "7",
    type: "Secondary text color",
    key: "secondary_text_color",
    value: "#0000",
  },
  {
    srNo: "8",
    type: "Secondary background color",
    key: "secondary_background_color",
    value: "#0000",
  },

];



function AddSettings() {
  const navigate = useNavigate();
  const { id } = useParams();
  const [theme, setTheme] = useState(
    {
      primary_text_color: "#0000",
      primary_background_color: "#0000",
      user_message_bg_color: "#0000",
      user_message_text_color: "#0000",
      bot_message_text_color: "#0000",
      bot_message_bg_color: "#0000",
      secondary_text_color: "#0000",
      secondary_background_color: "#0000",
      textColor: "#0000",
      backgroundColor: "#0000",
    }
  )
  const [selectedChat, setSelectedChat] = useState(undefined);
  const [selectedChatDetails, setSelectedChatDetails] = useState(undefined);
  const [chats, setChats] = useState([]);

  const [colors, setColors] = useState(colorData);

  const handleChatClick = (selectedChat) => {
    setSelectedChat(selectedChat);
    // setSelectedChatDetails(undefined);

    setSelectedChatDetails(getChatDetails);
    // RasaAdminAPI.getChat(selectedChat.sender_id).then(data => {
    // });
  };

  useEffect(() => {
    // Fetch chat collection
    // RasaAdminAPI.getChats().then(data => setChats(data));
    setChats(getChatRs);


  }, []);


  useEffect(() => {
    if (id) {
      getSettingDetials()
    }
  }, [id])
  const { isDrawerOpen, sidebar, selectedDomainId } = useContext(AppContext);

  const [logo, setLogo] = useState(null);
  const [botLogo, setBotLogo] = useState(null);
  const [popLogo, setPopLogo] = useState(null);
  const [messageLimit, setMessageLimit] = useState('')

  const [selectedDomain, setSelectedDomain] = useState("");
  const [sessionTime, setSessionTime] = useState("");
  const [apiKey, setApiKey] = useState('')
  const [botName, setBotName] = useState("");
  const [welcomeMessage, setWelcomeMessage] = useState("")

  const [errorHandle, setErrorHandle] = useState({
    sessionTimeErr: '',
    botNameErr: '',
    wlcmMsgErr: "",
    messageLimitErr: ""
  })

  const handleColorChange = (color, key, index) => {
    console.log(color);
    // setShowColorPicker(prev => !prev)
    setTheme({ ...theme, [key]: color.hex });
    console.log(theme);
    setColors((prev) => {
      const array = [...prev];
      const data = { ...array[index] };
      data.value = color.hex;
      array[index] = {
        ...data,
      };
      return array;
    });
  };

  const [selectedIndex, setSelectedIndex] = useState(-1);







  function handleSubmite() {



    setErrorHandle(prev => ({
      ...prev,
      sessionTimeErr: (sessionTime && sessionTime) ? "" : 'Please Enter Session Time',
      botNameErr: (botName && botName.length) ? "" : "Please Enter Bot Name",
      wlcmMsgErr: (welcomeMessage && welcomeMessage.length) ? "" : 'Please Enter Welcome Message',
      messageLimitErr: (messageLimit && messageLimit.length) ? "" : "Please Enter Message Limit"
    }))


    if (!sessionTime || !messageLimit || !welcomeMessage.length || !botName.length) {
      console.log(sessionTime, botName, welcomeMessage);
      return
    }

    console.log(theme);

    const requestData = {
      bot_logo: botLogo,
      session_time: sessionTime,
      primary_text_color: theme?.primary_text_color ? theme.primary_text_color : null,
      primary_background_color: theme?.primary_background_color ? theme.primary_background_color : null,
      user_message_bg_color: theme?.user_message_bg_color ? theme?.user_message_bg_color : null,
      user_message_text_color: theme?.user_message_text_color ? theme?.user_message_text_color : null,
      bot_message_text_color: theme?.bot_message_text_color ? theme?.bot_message_text_color : null,
      bot_message_bg_color: theme?.bot_message_bg_color ? theme?.bot_message_bg_color : null,
      domain_id: !id ? selectedDomainId : selectedDomain,
      secondary_text_color: theme?.secondary_text_color ? theme?.secondary_text_color : null,
      secondary_background_color: theme?.secondary_background_color ? theme?.secondary_background_color : null,
      website_logo: logo,
      chat_bot_pop_logo: popLogo,
      bot_name: botName,
      welcome_message: welcomeMessage,
      questions_limit: messageLimit
    }

    const formData = new FormData();
    for (const [key, value] of Object.entries(requestData)) {
      formData.append(key, value);
    }

    if (id) {
      putMultipartWithAuthCallWithErrorResponse(ApiConfig.POST_ADD_COLOR_SETTING + id, formData)
        .then((res) => {
          console.log(res);
          if (res?.json?.success) {
            ToastMsg('success', res?.json?.message)
            navigate('/setting')
          } else {
            ToastMsg('error', res?.json?.message)
          }
        })
        .catch(err => console.log(err))
      return
    }

    postMultipartWithAuthCallWithErrorResponse(ApiConfig.POST_ADD_COLOR_SETTING, formData)
      .then((res) => {
        console.log(res);
        if (res.success) {
          ToastMsg('success', res?.message)
          navigate('/setting')
        } else {
          ToastMsg('error', res?.json?.message)
          ToastMsg('error', res?.message)
        }
      })
      .catch(err => console.log(err))
  }

  function getSettingDetials() {
    getRequest(ApiConfig.GET_COLOR_SETTING_BY_ID + id)
      .then((res) => {
        console.log(res);
        const data = res?.data;
        setTheme(prev => ({
          ...prev,
          primary_text_color: data?.primary_text_color || '#0000',
          primary_background_color: data?.primary_background_color || '#0000',
          user_message_bg_color: data?.user_message_bg_color || '#0000',
          user_message_text_color: data?.user_message_text_color || '#0000',
          bot_message_text_color: data?.bot_message_text_color || '#0000',
          bot_message_bg_color: data?.bot_message_bg_color || '#0000',
          secondary_text_color: data?.secondary_text_color || '#0000',
          secondary_background_color: data?.secondary_background_color || '#0000',
          textColor: "#0000",
          backgroundColor: "#0000",
        }))
        setLogo(data?.website_logo)
        setBotLogo(data.bot_logo);
        setSessionTime(data.session_time);
        setApiKey(data?.api_key)
        setSelectedDomain(data?.domain_id)
        setColors(prev => prev?.map(item => {
          console.log(data[item?.key]);
          if (data[item?.key]) {
            item.value = data[item?.key];
            return item;
          }
          else {
            item.value = "";
            return item;
          }
        }))


        const color = {
          primaryTextColor: data.primary_text_color,
          primaryBackgroundColor: data.primary_background_color,
          userMessageBackgroundColor: data.user_message_bg_color,
          userMessageTextColor: data.user_message_text_color,
          botMessageTextColor: data.bot_message_text_color,
          botMessageBackgroundColor: data.bot_message_bg_color,
          secondaryTextColor: data.secondary_text_color,
          secondaryBackgroundColor: data.secondary_background_color,
          textColor: data.text_color,
          backgroundColor: data.background_color,
        };

        // setColors([])


        setSelectedDomain(data.domain_id);
        // setLogo(data.website_logo_logo);
        setPopLogo(data.chat_bot_pop_logo);
        setBotName(data.bot_name);
        setWelcomeMessage(data.welcome_message);
        setMessageLimit(data?.questions_limit)

      }).catch((err) => {
        console.log(err);
      })
  }


  const regenarateApiKey = () => {

    putMultipartWithAuthCallWithErrorResponseAdmin(ApiConfig.GENARATE_API_KEY + id).then((res) => {

      if (res?.success) {
        ToastMsg('success', res?.message)
      } else {
        ToastMsg('error', res?.message)
      }

    })

  }





  return (
    <div className={sidebar ? "taskMain " : "cx-active taskMain"}
      id="cx-main">

      <div

      >
        {" "}

        <div >
          <div className="role-content">
            <div className="sub-header-role ">
              <h6 className="mt-2">Chatbot Settings</h6>
            </div>
            <div className="d-flex flex-column align-items-center">
              <div className="d-flex col-md-12 uploadWrap">
                <div className="col-md-2 d-flex flex-column align-items-center">
                  <div className="Settign_logo_uplaod">
                    {logo ? (
                      <>
                        <img
                          src={typeof (logo) == "string" ? logo : URL.createObjectURL(logo)}
                          className="w-100 h-100"
                          alt=""
                        />
                      </>
                    ) : (
                      <div className="p-3">
                        <FontAwesomeIcon
                          className="h-100 w-100"
                          icon={faImage}
                        />
                      </div>
                    )}
                  </div>
                  <input
                    hidden
                    onChange={(e) => {
                      const file = e.target.files[0]
                      if (file.size >= 2 * 1024 * 1024) {
                        console.log("file is over size");
                        // console.log(file);
                      }
                      setLogo(e.target.files[0])
                    }}
                    multiple={false}
                    type="file"
                    accept=".svg"
                    id="logo"
                  />
                  <label htmlFor="logo" className="uploadBtn">
                    Upload logo
                  </label>
                </div>
                <div className="col-md-10">
                  <div className="setting-section custmPdgSetng">
                    <div className="sub-header-role ps-0 pt-1 pb-1">
                      <h6 className="mt-2">Time Settings</h6>
                    </div>
                  </div>
                  <div className="row">
                    <div className="input_wraper col-6">
                      <label htmlFor="">Session Time</label>
                      <input
                        value={sessionTime}
                        onChange={(e) => setSessionTime(e.target.value)}
                        type="number"
                        placeholder="Enter session time in minutes"
                      />

                      <span className="text-danger">{errorHandle?.sessionTimeErr}</span>
                    </div>
                    {
                      id &&
                      <div className="col-6 row">
                        <div className="input_wraper col-7">
                          <label htmlFor="">Generate Key</label>
                          <input
                            value={apiKey}
                            disabled
                            type="text"
                            placeholder="Generate Key"
                          />
                        </div>
                        <div className="col-5 ">
                          <button className="AddQuestion_submit_btn" style={{ marginTop: '33px' }}
                            onClick={regenarateApiKey}
                          >Regenarate Key</button>
                        </div>
                      </div>
                    }
                    {/* <div className="col-6 d-flex ">
                      <div className="row col-12">
                        <div className=" input_wraper ">
                          <label htmlFor="">Select Domain</label>
                          <select
                            value={selectedDomain}
                            onChange={(e) => setSelectedDomain(e.target.value)}
                            className="selection"
                          >
                            <option value={""}>Select Domain</option>
                            {
                              domains.map((item) => (
                                <option value={item?.id}>{item?.domain} </option>
                              ))}
                          </select>
                        </div>
                      </div>
                    </div> */}
                  </div>
                </div>
              </div>

              <div className="d-flex col-md-12 uploadWrap">
                {/* logo one */}
                <div className="col-md-2 d-flex flex-column align-items-center">
                  <div className="Settign_logo_uplaod">

                    {botLogo ? (
                      <>
                        <img
                          src={typeof (botLogo) === "string" ? botLogo : URL.createObjectURL(botLogo)}

                          className="w-100 h-100"
                          alt=""
                        />
                      </>
                    ) : (
                      <div className="p-3">
                        <FontAwesomeIcon
                          className="h-100 w-100"
                          icon={faImage}
                        />
                      </div>
                    )}
                  </div>
                  <input
                    hidden
                    onChange={(e) => {
                      const file = e.target.files[0]
                      if (file.size >= 2 * 1024 * 1024) {
                        console.log("file is over size");
                        // console.log(file);
                      }
                      setBotLogo(e.target.files[0])
                    }}
                    multiple={false}
                    type="file"
                    accept=".svg"
                    id="bot-logo"
                  />
                  <label htmlFor="bot-logo" className="uploadBtn">
                    Upload bot logo
                  </label>
                </div>

                {/* logo two */}
                <div className="col-md-2 d-flex flex-column align-items-center">
                  <div className="Settign_logo_uplaod">
                    {popLogo ? (
                      <>
                        <img
                          src={typeof (popLogo) === "string" ? botLogo : URL.createObjectURL(popLogo)}

                          className="w-100 h-100"
                          alt=""
                        />
                      </>
                    ) : (
                      <div className="p-3">
                        <FontAwesomeIcon
                          className="h-100 w-100"
                          icon={faImage}
                        />
                      </div>
                    )}
                  </div>
                  <input
                    hidden
                    onChange={(e) => {
                      const file = e.target.files[0]
                      if (file.size >= 2 * 1024 * 1024) {
                        console.log("file is over size");
                        // console.log(file);
                      }
                      setPopLogo(e.target.files[0])
                    }}
                    multiple={false}
                    type="file"
                    accept=".svg"
                    id="pop-logo"
                  />
                  <label htmlFor="pop-logo" className="uploadBtn">
                    Upload pop logo
                  </label>
                </div>


                <div className="col-md-8">
                  <div className="setting-section custmPdgSetng ">
                    <div className="sub-header-role ps-0 pt-1 pb-1">
                      <h6 className="mt-2">Name Settings</h6>
                    </div>
                  </div>
                  <div className="row px-4">
                    <div className="input_wraper col-4">
                      <label htmlFor="">Bot Name</label>
                      <input
                        value={botName}
                        onChange={(e) => setBotName(e.target.value)}
                        type="text"
                        placeholder="Enter bot name"
                      />
                      <span className="text-danger">{errorHandle?.botNameErr}</span>
                    </div>
                    <div className="input_wraper col-4">
                      <label htmlFor="">Welcome Message</label>
                      <input
                        value={welcomeMessage}
                        onChange={(e) => setWelcomeMessage(e.target.value)}
                        type="text"
                        placeholder="Enter Welcome message"
                      />

                      <span className="text-danger">{errorHandle?.wlcmMsgErr}</span>
                    </div>
                    <div className="input_wraper col-4">
                      <label htmlFor="">Message Limit Before Login</label>
                      <input
                        value={messageLimit}
                        onChange={(e) => setMessageLimit(e.target.value)}
                        type="number"
                        placeholder="Enter Welcome message"
                      />

                      <span className="text-danger">{errorHandle?.messageLimitErr}</span>
                    </div>
                  </div>
                </div>
              </div>

              <div className="setting-section">
                <div className="sub-header-role pt-1 pb-1">
                  <h6 className="mt-2">Chatbot Color Settings</h6>
                </div>

                <div
                  className="table-wrapper table-wrapper-2"
                  style={{ height: "auto" }}
                >
                  <table>
                    <thead>
                      <tr className="firstTabelRow">
                        <th style={{ width: "10%" }}>Sr. no</th>
                        <th style={{ width: "40%" }}>Color</th>
                        <th style={{ width: "20%" }}>Value</th>
                        <th style={{ width: "30%" }}>Picker</th>
                        {/* {selectedIndex >= 0 && <th></th>} */}
                      </tr>
                    </thead>

                    <tbody>
                      {colors?.map((item, index) => (
                        <tr className="table-row-custom">
                          <td>{item.srNo} </td>
                          <td>{item.type}</td>
                          <td>{item.value}</td>
                          <td>
                            {selectedIndex === index ? (
                              <div className="color-picker-container d-flex ">
                                <SketchPicker
                                  color={item?.value}
                                  onChange={(color) =>
                                    handleColorChange(color, item?.key, index)
                                  }
                                />
                                <button
                                  className="ml-2 colorPickerCloseIcon"
                                  onClick={() => setSelectedIndex(-1)}
                                >
                                  {" "}
                                  Close
                                </button>
                              </div>
                            ) : (
                              <button
                                className="color-picker-button"
                                // style={{ color: item.value, background: getComplementaryColor(item.value) }}
                                onClick={() => setSelectedIndex(index)}
                              >
                                Pick color
                              </button>
                            )}
                          </td>
                          {/* <td>
                            <button
                              className="color-picker-button"
                              // style={{ color: item.value, background: getComplementaryColor(item.value) }}
                              onClick={() => setSelectedIndex(index)}
                            >
                              Pick color
                            </button>
                          </td>

                          {selectedIndex === index ? (
                            <td>
                              <div className="color-picker-container d-flex ">
                                <SketchPicker
                                  color={item?.value}
                                  onChange={(color) =>
                                    handleColorChange(color, item?.key, index)
                                  }
                                />
                                <button
                                  className="ml-2 colorPickerCloseIcon"
                                  onClick={() => setSelectedIndex(-1)}
                                >
                                  {" "}
                                  Close
                                </button>
                              </div>
                            </td>
                          ) : (
                            selectedIndex >= 0 && <td></td>
                          )} */}
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>

              <div className="w-100 d-flex align-items-end justify-content-end mt-2">
                <button onClick={handleSubmite} className="AddQuestion_submit_btn">Submit</button>
              </div>
            </div>
          </div>
        </div>
        {/* <ColorPickers theme={theme} setTheme={setTheme} /> */}
        {/* <div className="right-panel"></div> */}
      </div>
    </div>
  );
}

export default AddSettings;
