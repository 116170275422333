import React, { useEffect,useContext, useState } from "react";

import ChatList from "../chat/ChatList";
import { faPenToSquare,faTrash} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { useNavigate } from "react-router-dom";


import NoDataFound from "../../../pages/ChatAdmin/NodataFound";

import Pagination from "../Pagination";
import { AppContext } from "../../../context/AppContext";
import { deleteWithAuthCall, simpleGetCallWithToken } from "../../../api/ApiServices";
import ApiConfig from "../../../api/ApiConfig";
import { ToastMsg } from "../../TostMsg";
import Loader from "../loader";



const getChatRs = [
  {
    sender_id: "user1",
    last_message: "Hello, how can I help you?",
    timestamp: "2024-07-21T12:34:56.789Z",
  },
  {
    sender_id: "user2",
    last_message: "I need assistance with my order.",
    timestamp: "2024-07-21T12:35:56.789Z",
  },
  {
    sender_id: "user3",
    last_message: "Can you provide more details on your service?",
    timestamp: "2024-07-21T12:36:56.789Z",
  },
];

const getChatDetails = {
  events: [
    {
      type_name: "user",
      timestamp: 1675970400,
      data: {
        text: "Hello, how can I help you today?",
      },
    },
    {
      type_name: "bot",
      timestamp: 1675970460,
      data: {
        text: "I need some details about your issue.",
      },
    },
    {
      type_name: "user",
      timestamp: 1675970520,
      data: {
        text: "I have a problem with my order.",
      },
    },
    {
      type_name: "bot",
      timestamp: 1675970580,
      data: {
        text: "Can you provide me with your order number?",
      },
    },
    {
      type_name: "system", // This type will be filtered out
      timestamp: 1675970640,
      data: {
        text: "System maintenance in progress.",
      },
    },
  ],
};

// Assuming you have a CSS file for styling


const UnAnsweredListing = () => {
  const [selectedChat, setSelectedChat] = useState(undefined);
  const [selectedChatDetails, setSelectedChatDetails] = useState(undefined);
  const [chats, setChats] = useState([]);
  const [questionList, setQuestionList] = useState([]);

  const [totalPage,setTotalPage] = useState(0)
  const [currentPage,setCurrentPage]= useState(1)

  const [search,setSearch] = useState('')

  const { sidebar,selectedDomainId,domainName,setBackTo} = useContext(AppContext);

  const [isLoading,setIsLoading] = useState(true)


  const navigate = useNavigate();

  const [startIndex,setStartIndex] = useState(0)

  const handleChatClick = (selectedChat) => {
    setSelectedChat(selectedChat);
    // setSelectedChatDetails(undefined);

    setSelectedChatDetails(getChatDetails);
    // RasaAdminAPI.getChat(selectedChat.sender_id).then(data => {
    // });
  };

  const handleSendMessage = (text) => {
    const data = {
      type_name: "bot", // This type will be filtered out
      timestamp: 1675970640,
      data: {
        text: "System maintenance in progress.",
      },
    };
    const data2 = {
      type_name: "user", // This type will be filtered out
      timestamp: Math.floor(Math.random() * 100000000),
      data: {
        text: text,
      },
    };

    setSelectedChatDetails((prev) => ({
      ...prev,
      events: [...prev.events, data2, data],
    }));
    // console.log(`send message: ${text} to ${selectedChat.sender_id}`);
    // RasaAdminAPI.sendMessage(selectedChat.sender_id, text);
  };

  useEffect(() => {
    // Fetch chat collection
    // RasaAdminAPI.getChats().then(data => setChats(data));
    setChats(getChatRs);
    getUnAnswerdQuestionList();
  }, [selectedDomainId,currentPage]);


  function getUnAnswerdQuestionList() {
    setIsLoading(true)
    simpleGetCallWithToken(ApiConfig.GET_UN_ANSWERED_QUES + "?page=" + currentPage + "&page_limit=10"+'&domain_id='+selectedDomainId+'&search='+search)
      .then((res) => {
        console.log(res);
        if (res.success) {
            console.log(res?.data);
            
          setTotalPage(res?.total_pages)
          setQuestionList(res.data)
          setStartIndex((currentPage-1)*10)
        }else{
          setQuestionList([])
        }
        setIsLoading(false)
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false)
      })
  }

 
  const deleteQuestion=(id)=>{

    deleteWithAuthCall(ApiConfig.DELETE_UN_ANSWERED_QUES+id).then((res)=>{


      if(res?.success){

        ToastMsg('success',res?.message)

        getUnAnswerdQuestionList()

      }else{
        ToastMsg('error',res?.message)
      }


    }).catch((err)=>{

      ToastMsg('error','Something Went Wrong Try Again')

    })

  }


  return (
    <div className="main_container">
  
      <div
        className={sidebar ? "taskMain " : "cx-active taskMain"}
        id="cx-main"
      >
  

        <div >
          <div className="role-content">
            <div className="sub-header-role d-flex justify-content-between">
              <h6 className="mt-2">Un-Answered Questions List</h6>

              <h6 className="mt-2 mr-2">Domain Name : {domainName}</h6>
            </div>
            <div className="input_wraper d-flex mt-4">
            <input
            placeholder="Search By Question"
            type="text"
            className=""   
            style={{width:'250px', height:"38px"}}  
            value={search}  
            onChange={(e)=>setSearch(e.target.value)}
            />
            <button className="AddQuestion_submit_btn mx-3"
            onClick={getUnAnswerdQuestionList}
            >Search</button>
            </div> 
            <div className="table-wrapper table-wrapper-2" style={{height:"600px"}}>
              {
                isLoading?<Loader/>:
                questionList.length>0?
             
              <table style={{marginBottom:"20px"}}>
                <thead className="">
                  <tr className="firstTabelRow">
                    <th>Sr. no</th>
                    <th>User Name</th>
                    <th>Question</th>
                    <th>Answer</th>
                    <th>Action</th>
                  </tr>
                </thead>
                {questionList.map((item, index) => (
                  <tr key={index} className="table-row-custom">
                    <td>{startIndex + index+1} </td>
                    <td>{item?.sender_id}</td>
                    <td>{item?.question_text} </td>
                    <td>-</td>
                    <td>
                      <OverlayTrigger
                        placement="bottom"
                        delay={{ show: 100, hide: 300 }}
                        overlay={(props) => (
                          <Tooltip id="button-tooltip" {...props}>
                            Edit Question
                          </Tooltip>
                        )}
                      >
                        <FontAwesomeIcon
                          onClick={() =>{
                            setBackTo('/unAnsweredListing')
                            navigate("/editQuestion/" + item?.id+'?question='+item?.question_text)}}
                          icon={faPenToSquare}
                          style={{ width: "15px", height: "15px",marginRight:"10px" }}
                        />
                      </OverlayTrigger>{" "}
                      
                      <OverlayTrigger
                        placement="bottom"
                        delay={{ show: 100, hide: 300 }}
                        overlay={(props) => (
                          <Tooltip id="button-tooltip" {...props}>
                            Delete Question
                          </Tooltip>
                        )}
                      >
                        <FontAwesomeIcon icon={faTrash}
                        onClick={()=>deleteQuestion(item?.id)}
                        style={{ width: "15px", height: "15px" }}/>
                      </OverlayTrigger>
                    </td>
                  </tr>
                ))}
              </table>:<NoDataFound/>
               }
            </div>
            <Pagination totalPage={totalPage}
            currentPage={currentPage}
            pageChangeHandler={(page)=>setCurrentPage(page+1)}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default UnAnsweredListing
