import { useState, useCallback, useEffect } from "react";
import ReactQuill, { Quill } from "react-quill";
import "react-quill/dist/quill.snow.css";
import ImageResize from "quill-image-resize-module-react";
// import './styles.css';

Quill.register("modules/imageResize", ImageResize);

const Editor = ({ placeholder, editorHtml, setEditorHtml }) => {
  const handleChange = useCallback((html) => {
   // console.log(html);
    setEditorHtml(html);
    // console.log(html);
  }, []);

  useEffect(() => {
    // Any additional setup or cleanup can go here if needed
  }, []);

  const modules = {
    toolbar: [
      [{ header: "1" }, { header: "2" }, { font: [] }],
      [{ size: [] }],
      ["bold", "italic", "underline", "strike", "blockquote"],
      [
        { list: "ordered" },
        { list: "bullet" },
        { indent: "-1" },
        { indent: "+1" },
      ],
      [
        "link",
        // 'image',
        // 'video'
      ],
      ["clean"],
    ],
    clipboard: {
      matchVisual: false,
    },
    imageResize: {
      parchment: Quill.import("parchment"),
      modules: ["Resize", "DisplaySize"],
    },
  };

  const formats = [
    "header",
    "font",
    "size",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "list",
    "bullet",
    "indent",
    "link",
    "image",
    "video",
  ];

  return (
    <ReactQuill
      theme="snow"
      className="editorHW"
      onChange={(html)=>setEditorHtml(html)}
      value={editorHtml}
      modules={modules}
      formats={formats}
      bounds={"#root"}
      placeholder={placeholder}
    />
  );
};

export default Editor;
