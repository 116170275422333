import React, { useEffect, useRef, useState } from "react";
import ChatList from "../chat/ChatList";
import { faPenToSquare, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import Pagination from "../../../pages/ChatAdmin/Pagination";


import NoDataFound from "../../../pages/ChatAdmin/NodataFound";
import { ToastMsg } from "../../TostMsg";
import Loader from "../../Loader";
import { AppContext } from "../../../context/AppContext";
import { downloadFile, getPlainText } from "../../../pages/ChatAdmin/commaon";
import { simpleGetCall,deleteWithAuthCall, simpleGetCallWithTokenNoNBEARER, getRequest } from "../../../api/ApiServices";
import ApiConfig from "../../../api/ApiConfig";
import { useContext } from "react";
const getChatRs = [
  {
    sender_id: "user1",
    last_message: "Hello, how can I help you?",
    timestamp: "2024-07-21T12:34:56.789Z",
  },
  {
    sender_id: "user2",
    last_message: "I need assistance with my order.",
    timestamp: "2024-07-21T12:35:56.789Z",
  },
  {
    sender_id: "user3",
    last_message: "Can you provide more details on your service?",
    timestamp: "2024-07-21T12:36:56.789Z",
  },
];

const getChatDetails = {
  events: [
    {
      type_name: "user",
      timestamp: 1675970400,
      data: {
        text: "Hello, how can I help you today?",
      },
    },
    {
      type_name: "bot",
      timestamp: 1675970460,
      data: {
        text: "I need some details about your issue.test",
      },
    },
    {
      type_name: "user",
      timestamp: 1675970520,
      data: {
        text: "I have a problem with my order.",
      },
    },
    {
      type_name: "bot",
      timestamp: 1675970580,
      data: {
        text: "Can you provide me with your order number?",
      },
    },
    {
      type_name: "system", // This type will be filtered out
      timestamp: 1675970640,
      data: {
        text: "System maintenance in progress.",
      },
    },
  ],
};

// Assuming you have a CSS file for styling

const QuestionListing = () => {
  const [selectedChat, setSelectedChat] = useState(undefined);
  const [selectedChatDetails, setSelectedChatDetails] = useState(undefined);
  const [chats, setChats] = useState([]);
  const [questionList, setQuestionList] = useState([]);

  const [totalPage, setTotalPage] = useState(0)
  const [currentPage, setCurrentPage] = useState(1)

  const [search, setSearch] = useState('')

  const [showDuplicateQuesList, setDuplcateQuesList] = useState(false)

  const { sidebar, selectedDomainId, domainName, setBackTo } = useContext(AppContext);

  const [isLoading, setIsLoading] = useState(true)

  const [tolalRecords, setTotalRecords] = useState(0)

  const [isDownload, setIsDownload] = useState(false)


  const navigate = useNavigate();

  const [startIndex, setStartIndex] = useState(0)

  const handleChatClick = (selectedChat) => {
    setSelectedChat(selectedChat);
    // setSelectedChatDetails(undefined);

    setSelectedChatDetails(getChatDetails);
    // RasaAdminAPI.getChat(selectedChat.sender_id).then(data => {
    // });
  };


  useEffect(() => {
    // Fetch chat collection
    // RasaAdminAPI.getChats().then(data => setChats(data));
    setChats(getChatRs);
    getQuestionList();
  }, [selectedDomainId, currentPage, showDuplicateQuesList]);


  function getQuestionList() {
    setIsLoading(true)

    let duplicate = showDuplicateQuesList ? '&start_range_duplicate=2' : ''

    simpleGetCallWithTokenNoNBEARER(ApiConfig.GET_QUESTION_LIST + "?page=" + currentPage + "&page_limit=10" + '&domain_id=' + selectedDomainId + '&search=' + search + duplicate)
      .then((res) => {
        console.log(res);
        if (res.success) {
          setTotalRecords(res?.total)
          setTotalPage(res?.total_pages)
          setQuestionList(res.data)
          setStartIndex((currentPage - 1) * 10)
        } else {
          setQuestionList([])
        }
        setIsLoading(false)
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false)
      })
  }


  const deleteQuestion = (id) => {

    deleteWithAuthCall(ApiConfig.DELETE_QUESTION + id).then((res) => {


      if (res?.success) {

        ToastMsg('success', res?.message)

        getQuestionList()

      } else {
        ToastMsg('error', res?.message)
      }


    }).catch((err) => {

      ToastMsg('error', 'Something Went Wrong Try Again')

    })

  }


  const downLoadQuesList = () => {


    setIsDownload(true)

    let duplicate = showDuplicateQuesList ? '&start_range_duplicate=2' : ''

    getRequest(ApiConfig?.GET_QUESTION_LIST + '?domain_id=' + Number(selectedDomainId) + "&&page_limit=" + tolalRecords + duplicate).then((res) => {

      if (res?.success) {

        downloadFile({
          data: JSON.stringify(res?.data),
          fileName: 'question.json',
          fileType: 'text/json',
        })


      } else {

        ToastMsg('error', res?.Message)

      }

      setIsDownload(false)

    }).catch((err) => {

      console.log(err);


    })


  }


  return (
    <div    className={sidebar ? "taskMain " : "cx-active taskMain"}
    id="cx-main">

      <div
       
      >
   

      
        <div>
          <div className="role-content">
            <div className="sub-header-role d-flex justify-content-between">
              <h6 className="mt-2">{showDuplicateQuesList && 'Duplicate '}Questions List</h6>
              <h6 className="mt-2 mr-2">Domain Name : {domainName}</h6>
            </div>
            <div className="d-flex justify-content-between mt-4">
              <div className="input_wraper d-flex ">
                <input
                  placeholder="Search By Question"
                  type="text"

                  style={{ width: '350px', height:"38px" }}
                  value={search}
                  onChange={(e) => setSearch(e.target.value)}
                />
                <button className="AddQuestion_submit_btn mx-3"
                  onClick={getQuestionList}

                >Search</button>
              </div>
              <div>
                <button className="AddQuestion_submit_btn mx-3 " type="button" onClick={() => setDuplcateQuesList(!showDuplicateQuesList)}  >{showDuplicateQuesList ? "List" : "Duplicate"}</button>
                <button className="AddQuestion_submit_btn mx-3 " type="button" disabled={isDownload} onClick={downLoadQuesList}>{showDuplicateQuesList ? 'Un-' : ""}Publish</button>
              </div>
            </div>
            <div className="table-wrapper table-wrapper-2" style={{ height: "600px" }}>
              {
                isLoading ? <Loader /> :
                  questionList.length > 0 ?

                    <table style={{ marginBottom: "20px" }}>
                      <thead className="">
                        <tr className="firstTabelRow">
                          <th>Sr. no</th>
                          <th>Question</th>
                          <th>Answer</th>
                          <th>Domain</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      {questionList.map((item, index) => (
                        <tr key={index} className="table-row-custom">
                          <td>{startIndex + index + 1} </td>
                          <td>{item.question} </td>
                          <td>{<div dangerouslySetInnerHTML={{ __html: item?.answer }} />} </td>
                          <td>{item?.domain}</td>
                          <td>
                            <OverlayTrigger
                              placement="bottom"
                              delay={{ show: 100, hide: 300 }}
                              overlay={(props) => (
                                <Tooltip id="button-tooltip" {...props}>
                                  Edit Question
                                </Tooltip>
                              )}
                            >
                              <FontAwesomeIcon
                                onClick={() => {
                                  setBackTo('/questionListing')
                                  navigate("/editQuestion/" + item?.id)
                                }}
                                icon={faPenToSquare}
                                style={{ width: "15px", height: "15px", marginRight:"10px" }}
                              />
                            </OverlayTrigger>{" "}

                            <OverlayTrigger
                              placement="bottom"
                              delay={{ show: 100, hide: 300 }}
                              overlay={(props) => (
                                <Tooltip id="button-tooltip" {...props}>
                                  Delete Question
                                </Tooltip>
                              )}
                            >
                              <FontAwesomeIcon icon={faTrash}
                                onClick={() => deleteQuestion(item?.id)}
                                style={{ width: "15px", height: "15px" }} />
                            </OverlayTrigger>
                          </td>
                        </tr>
                      ))}
                    </table> : <NoDataFound />
              }
            </div>
            <Pagination totalPage={totalPage}
              currentPage={currentPage}
              pageChangeHandler={(page) => setCurrentPage(page + 1)}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default QuestionListing;
