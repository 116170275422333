import React, { useRef, useState } from "react";
import { useContext } from "react";
import { AppContext } from "../../context/AppContext";
import { toast } from "react-toastify";
import { ToastMsg } from "../TostMsg";
import {
  postMultipartWithAuthCall,
  postWithAuthCallWithErrorResponse,
} from "../../api/ApiServices";
import ApiConfig from "../../api/ApiConfig";

const BulkUploadOfficeCaller = () => {
  const [filesave, setFileSave] = useState(null);
  const [fileSelect, setfileSelect] = useState(false);
  const { sidebar, setSidebar, Dark, setDark, userData } =
    useContext(AppContext);
  const ref = useRef();
  let token = localStorage.getItem("Token");

  const handleUpload = (e) => {
    const file = filesave;
    if (!file) {
      console.log("No file selected");
      setfileSelect(true);
      return;
    }
    setfileSelect(false);

    const formData = new FormData();
    formData.append("users", file);
    postWithAuthCallWithErrorResponse(ApiConfig.BULK_UPLOAD, formData)
      .then((res) => {
        if (res?.json?.success) {
          console.log(res);
          setFileSave(null);
          ToastMsg("success", res?.json?.message);
        } else {
          ToastMsg("error", res?.json?.message);
        }
      })
      .catch((err) => {
        console.log(err);
        setFileSave(null);
      });
  };
  const handleFileChange = (e) => {
    setFileSave(e.target.files[0]);
  };

  return (
    <div className={sidebar ? "taskMain " : "cx-active taskMain"} id="cx-main">
      <div className="role-content">
        <div className="sub-header-role mb-4">
          <h6 className="mt-2">Bulk Upload</h6>
        </div>
        <div className="settingsCard">
          <div className="row">
            <div className="col-6 ">
              <span>Upload File</span>
              <div className="d-flex align-items-center">
                <input
                  type="file"
                  accept=".xlsx"
                  onChange={handleFileChange}
                  className="form-control tasKCategory1 mt-3"
                />
                <button
                  className="AddQuestion_submit_btn mx-3 mt-auto"
                  onClick={handleUpload}
                >
                  Upload
                </button>
              </div>
            </div>
            {fileSelect && <p className="text-danger">upload the file</p>}
          </div>
        </div>
      </div>
    </div>
  );
};
export default BulkUploadOfficeCaller;
