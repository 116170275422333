import React, { useContext, useEffect, useState } from "react";
import { AppContext } from "../../context/AppContext";
import { Form } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { PostCallWithErrorResponse, simpleGetCallWithErrorResponse } from "../../api/ApiServices";
import ApiConfig from "../../api/ApiConfig";
import { notifyError, notifySuccess } from "../../toaster/notify";

export default function CreateIVR() {
  const { sidebar, setSidebar, Dark, setDark, userData } =
    useContext(AppContext);
  const [iverList, setiverList] = useState([])
  const [userId, seUserId] = useState('')
  const [AddIver, setAddIver] = useState({
    c_api_key: "",
    c_user_name: "",
    user_id: "",

  })
  const [btnLoader, setbtnLoader] = useState(false);
  const [validated, setValidated] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    IVRUser()
  }, [])




  const IVRUser = () => {
    simpleGetCallWithErrorResponse(userData?.CRMUrlNode +ApiConfig.GETIVRLIST)
      .then((res) => {
        console.log("paymentDrop--->", res.json.data)
        setiverList(res.json.data)
      })
      .catch((err) => {
        console.log(err)
      })
  }



  const createIVER = () => {



    setbtnLoader(true);  // Start button loader
    PostCallWithErrorResponse(
      userData?.CRMUrlNode + ApiConfig.ADDTIVR,
      JSON.stringify(AddIver)
    )
      .then((res) => {
        // Close modal after success
        console.log(res);



        if (res.json.success) {
          notifySuccess(res.json.message);
          navigate("/ivrsetting");

        } else {

          notifyError(res.json.message);

        }
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setbtnLoader(false);  // Stop button loader
      });

  };

  return (
    <>
      <div
        className={sidebar ? "taskMain " : "cx-active taskMain"}
        id="cx-main"
      >
        <div className="sub-header-role ">
          <h6 className="mt-2">IVR Add</h6>
        </div>
        <Form noValidate>
          <div className="WeeklyMain">
            <div className="row justify-content-center">
              <div className="col-lg-7">
                <div className="my-3">
                  <Form.Group>
                    <Form.Label className="mb-2 text-grey">
                      User List :
                    </Form.Label>
                    <Form.Select

                      className="form-control tasKCategory1"
                      required
                      value={AddIver.userId}
                      onChange={(e) => setAddIver({
                        user_id: e.target.value
                      })}

                    >
                      <option value={0}>Select</option>
                      {
                        iverList.map((item) => (
                          <option key={item.id} value={item.id}>{item.name}</option>
                        ))
                      }

                    </Form.Select>
                  </Form.Group>
                </div>
              </div>

              <div className="col-lg-7">
                <div className="my-3">
                  <Form.Group>
                    <Form.Label className="mb-2 text-grey">API :</Form.Label>
                    <Form.Control
                      className="form-control tasKCategory1"
                      type="text"
                      required

                      value={AddIver.c_api_key}
                      onChange={(e) => setAddIver({ ...AddIver, c_api_key: e.target.value })}
                    />
                  </Form.Group>
                </div>
              </div>

              <div className="col-lg-7">
                <div className="my-3">
                  <Form.Group>
                    <Form.Label className="mb-2 text-grey">
                      User Name :
                    </Form.Label>
                    <Form.Control
                      required

                      className="form-control tasKCategory1"
                      type="text"
                      value={AddIver.c_user_name}
                      onChange={(e) => setAddIver({ ...AddIver, c_user_name: e.target.value })}
                    />
                  </Form.Group>
                </div>
              </div>
            </div>

            <div className="align-items-center justify-content-center d-flex mt-5 mb-3">
              <button className="allocateionBtn">
                <Link className="text-white" to=""
                  onClick={createIVER}
                >
                  Add IVR
                </Link>
              </button>
            </div>
          </div>
        </Form>

      </div>
    </>
  );
}
